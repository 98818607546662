import React, { FC, useEffect, useState } from 'react'
import { Button, Col, message } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import AddFileModal from '../modals/AddFile'

interface IDownload {
	title: string
}

export const UploadExcel: FC<IDownload> = ({ title }) => {
	const [isOpen, setIsOpen] = useState(false)
	const [seller, setSeller] = useState<string | null>(null)

	const onClick = () => {
		setIsOpen(true)
	}

	return (
		<>
			<Button
				onClick={onClick}
				// className='table-header-button'
				type='primary'
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					width: '100%',
				}}
			>
				{title}
			</Button>
			<AddFileModal
				title={title}
				isOpen={isOpen}
				onClose={() => {
					setIsOpen(false)
				}}
			/>
		</>
	)
}
