import React, { useEffect, useState } from 'react'
import {
	Button,
	Checkbox,
	Col,
	DatePicker,
	Dropdown,
	Input,
	message,
	Modal,
	Pagination,
	Row,
	Select,
	Spin,
	Table,
} from 'antd'
import { useLocation, useNavigate } from 'react-router'
import Icon, {
	DownloadOutlined,
	EditOutlined,
	InfoCircleOutlined,
	LoadingOutlined,
} from '@ant-design/icons'
import { PickSeller } from './components/PickSeller'
import { PickActionType } from './components/PickActionType'
import CopyToClipboard from 'react-copy-to-clipboard'
import { ReactComponent as SelectIconComponent } from 'assets/images/select-icon.svg'
import { PickIsAvailable } from './components/PickIsAvailable'
import ActionsTableCol from './components/ActionsTableCol'
import { PickIsParticipating } from './components/PickIsParticipating'
import ActionItemDetails from './components/ActionDetailsItem'
import { PickIsNew } from './components/PickIsNew'
import { PickChangeType } from './components/PickChangeType'
import { ReactComponent as rangePickerArrow } from '../../assets/images/rangepicker-arrow.svg'
import HistoryHover from './components/HistoryHover'
import dayjs from 'dayjs'
import { formatDate } from 'utils/formatDate'
import { PickPageSize } from './components/PickPageSize'
import usePricesData from './hooks/prices/usePricesData'
import AddFileModal from './modals/AddFileModal'
import { setPrices } from 'api/prices'
import BeforeAfter from './BeforeAfter'
import { current } from '@reduxjs/toolkit'
import TitleWithTooltip from 'features/actions/components/TitleWithTooltip'
import NoImage from 'assets/icon/no-image'
import { ConfirmationModal } from 'features/settingsMirror/modals/ConfirmationModal'
import { PickLessThenMin } from './components/LessThenMin'
const { RangePicker } = DatePicker

const priceCheck = ({
	newPrice,
	newFullPrice,
	newDiscount,
}: {
	newPrice: number
	newFullPrice: number
	newDiscount: number
}) => {
	if (newFullPrice - (newFullPrice * newDiscount) / 100 !== newPrice) {
		return newFullPrice - (newFullPrice * newDiscount) / 100
	} else {
		return newPrice
	}
}

const validatePrice = (price: number): boolean => {
	return price > 0
}

const validateDiscount = (discount: number): boolean => {
	return discount >= 0 && discount <= 100
}

const validateMinPrice = (price: number, minPrice: number): boolean => {
	return price >= minPrice
}

export const PricesUpload = () => {
	const [addFileModal, setAddFileModal] = useState(false)

	const [data, setData] = useState<null | any>(null)
	const [filteredData, setFilteredData] = useState<null | any>(data)
	const [selectedPriceType, setSelectedPriceType] = useState<boolean | null>(
		null
	)
	const [confirmUploadOpen, setConfirmUploadOpen] = useState(false)
	const [validationErrors, setValidationErrors] = useState({
		minPrice: false,
		negativeValues: false,
		discountRange: false,
	})

	const validateRowData = (row: any) => {
		const errors = {
			minPrice: !validateMinPrice(row.newPrice, row.minPrice),
			negativeValues:
				!validatePrice(row.newPrice) || !validatePrice(row.newFullPrice),
			discountRange: !validateDiscount(row.newDiscount),
		}
		setValidationErrors(errors)
		return !errors.negativeValues && !errors.discountRange
	}

	const handlePriceChange = (
		rowId: number,
		updatedValue: any,
		rowKey: 'newFullPrice' | 'newDiscount'
	) => {
		setData((prevData: any) => {
			const newData = prevData.map((row: any) => {
				if (row.id === rowId) {
					const updatedRow = { ...row, [rowKey]: updatedValue }

					if (rowKey === 'newFullPrice' || rowKey === 'newDiscount') {
						updatedRow.newPrice = priceCheck({
							newPrice: updatedRow.newPrice,
							newFullPrice: updatedRow.newFullPrice,
							newDiscount: updatedRow.newDiscount,
						})
					}

					const fieldErrors: any = {}

					if (rowKey === 'newFullPrice' && !validatePrice(updatedValue)) {
						fieldErrors[rowKey] = 'Некорректная цена!'
					}

					if (rowKey === 'newDiscount' && !validateDiscount(updatedValue)) {
						fieldErrors[rowKey] = 'Некорректная скидка!'
					}

					if (!validateMinPrice(updatedRow.newPrice, row.minPrice)) {
						console.log(updatedRow, row.monPrice)
						fieldErrors['minPrice'] = 'Цена ниже минимальной!'
					}

					Object.entries(fieldErrors).forEach(([key, errorMessage]) => {
						if (key === rowKey || key === 'minPrice') {
							message.warning(`На строке ${rowId + 1}: ${errorMessage}`)
						}
					})

					return updatedRow
				}
				return row
			})

			setValidationErrors((prevErrors: any) => ({
				...prevErrors,
				[rowId]: {
					...prevErrors[rowId],
					[rowKey]: null,
				},
			}))

			return newData
		})
	}

	const setAndValidateData = (uploadedData: any) => {
		const validData = uploadedData.filter((row: any) => validateRowData(row))
		setValidationErrors({
			minPrice: uploadedData.some(
				(row: any) => !validateMinPrice(row.newPrice, row.minPrice)
			),
			negativeValues: uploadedData.some(
				(row: any) =>
					!validatePrice(row.newPrice) || !validatePrice(row.newFullPrice)
			),
			discountRange: uploadedData.some(
				(row: any) => !validateDiscount(row.newDiscount)
			),
		})
		setData(uploadedData)
		setFilteredData(validData)
	}

	const {
		pricesData,
		promotions,
		setPromotions,

		getSellersFilterItems,
		getProductsFilterItems,
		searchText,
		setSearchText,
		selectedAvailableType,
		setSelectedAvailableType,
		selectedParticipatingType,
		setSelectedParticipatingType,

		onChangeDate,
		dateSortStart,
		dateSortEnd,
		selectedChangeType,
		setSelectedChangeType,
		selectedSeenType,
		setSelectedSeenType,
		selectedActionType,
		setSelectedActionType,
		selectedSeller,
		selectedProducts,
		isError,
		isLoading,
		setDataType,
		exportPrices,
	} = usePricesData()

	useEffect(() => {
		if (validationErrors.minPrice) {
			message.warning(
				'В загруженном файле найдены цены, установленные ниже минимума.'
			)
		}
		if (validationErrors.negativeValues) {
			message.error('В загруженном файле найдены отрицательные цены.')
		}
		if (validationErrors.discountRange) {
			message.error(
				'В загруженном файле найдены скидки вне допустимого диапазона.'
			)
		}
	}, [data?.length])

	useEffect(() => {
		if (data) {
			const newErrors = {
				minPrice: data.some(
					(row: any) => !validateMinPrice(row.newPrice, row.minPrice)
				),
				negativeValues: data.some(
					(row: any) =>
						!validatePrice(row.newPrice) || !validatePrice(row.newFullPrice)
				),
				discountRange: data.some(
					(row: any) => !validateDiscount(row.newDiscount)
				),
			}
			setValidationErrors(newErrors)
		}
	}, [data])

	const columns = [
		{
			title: '',
			dataIndex: 'photoUrl',
			key: 'photoUrl',
			width: '5%',
			align: 'center' as const,
			render: (photo: string, record: any) => {
				const { wbArticle, linkOnSite } = record || {}
				return (
					<div
						className='analytics-col-title__wrap'
						style={{ padding: '5px 6px 5px 6px' }}
					>
						<CopyToClipboard
							text={`${wbArticle || ''}`}
							onCopy={() => message.success('Текст скопирован')}
						>
							<div
								className='analytics-col-title'
								style={{ cursor: 'pointer' }}
							>
								{wbArticle || 'Нет данных'}
							</div>
						</CopyToClipboard>
						<a href={linkOnSite} target='_blank' rel='noopener noreferrer'>
							{photo ? (
								<img
									src={photo}
									style={{
										width: '90px',
										minHeight: 120,
										maxHeight: 120,
									}}
									alt='Товар'
									onError={e => {
										e.currentTarget.onerror = null
										e.currentTarget.src = ''
										e.currentTarget.style.display = 'none'
										const noImage = e.currentTarget
											.nextElementSibling as HTMLElement
										if (noImage) noImage.style.display = 'block'
									}}
								/>
							) : null}
							<NoImage
								style={{
									width: '90px',
									minHeight: 120,
									maxHeight: 120,
									display: photo ? 'none' : 'block',
								}}
							/>
						</a>
						{/* {category && <div className='analytics-col-title'>{category}</div>} */}
					</div>
				)
			},
		},
		{
			title: <TitleWithTooltip title='Продавец' />,
			dataIndex: 'seller',
			key: 'seller',
			width: '7%',
			height: 120,
			align: 'center' as const,
			render: (text: string) => <div style={{ padding: '0 8px' }}>{text}</div>,
		},
		{
			title: <TitleWithTooltip title='Категория' />,
			dataIndex: 'category',
			key: 'category',
			width: '4%',
			align: 'center' as const,
			render: (text: any) => <div style={{ padding: '0 8px' }}>{text}</div>,
		},
		{
			title: <TitleWithTooltip title='Текущая полная цена' />,
			dataIndex: 'currentPrice',
			key: 'currentPrice',
			width: 50,
			align: 'center' as const,
			render: (text: any, price: any) => (
				<div style={{ padding: '0 8px' }}>
					<BeforeAfter before={price.currentFullPrice} />
				</div>
			),
		},

		{
			title: <TitleWithTooltip title='Текущая скидка' />,
			dataIndex: 'currentDiscount',
			key: 'currentDiscount',
			width: '7%',
			align: 'center' as const,
			render: (text: any, price: any) => (
				<div style={{ padding: '0 8px' }}>
					<BeforeAfter before={price.currentDiscount} />
				</div>
			),
		},
		{
			title: <TitleWithTooltip title='Текущая цена до СПП' />,
			dataIndex: 'currentPrice',
			key: 'currentPrice',
			width: 50,
			align: 'center' as const,
			render: (text: any, price: any) => (
				<div style={{ padding: '0 8px' }}>
					<BeforeAfter before={price.currentPrice} />
				</div>
			),
		},
		{
			title: <TitleWithTooltip title='Новая полная цена' />,
			dataIndex: 'newPrice',
			key: 'newPrice',
			width: 50,
			align: 'center' as const,
			render: (text: any, price: any) => {
				const newPrice = priceCheck({
					newPrice: price.newPrice,
					newFullPrice: price.newFullPrice,
					newDiscount: price.newDiscount,
				})
				return {
					props: {
						style: {
							background:
								price?.minPrice > newPrice
									? 'rgba(255, 50, 50, 0.6)'
									: 'rgba(0, 203, 68, 0.6)',
						},
					},
					children: (
						<div style={{ padding: '0 8px' }}>
							<BeforeAfter
								after={price.newFullPrice}
								onAfterChange={newAfter => {
									handlePriceChange(price.id, newAfter, 'newFullPrice')
								}}
							/>
						</div>
					),
				}
			},
		},
		{
			title: <TitleWithTooltip title='Новая скидка' />,
			dataIndex: 'newDiscount',
			key: 'newDiscount',
			width: '7%',
			align: 'center' as const,
			render: (text: any, price: any) => {
				const newPrice = priceCheck({
					newPrice: price.newPrice,
					newFullPrice: price.newFullPrice,
					newDiscount: price.newDiscount,
				})
				return {
					props: {
						style: {
							background:
								price?.minPrice > newPrice
									? 'rgba(255, 50, 50, 0.6)'
									: 'rgba(0, 203, 68, 0.6)',
						},
					},
					children: (
						<div style={{ padding: '0 8px' }}>
							<BeforeAfter
								after={price.newDiscount}
								onAfterChange={newAfter => {
									handlePriceChange(price.id, newAfter, 'newDiscount')
								}}
							/>
						</div>
					),
				}
			},
		},
		{
			title: <TitleWithTooltip title='Новая цена до СПП' />,
			dataIndex: 'newPrice',
			key: 'newPrice',
			width: 50,
			align: 'center' as const,
			render: (text: any, price: any) => {
				const newPrice = priceCheck({
					newPrice: price.newPrice,
					newFullPrice: price.newFullPrice,
					newDiscount: price.newDiscount,
				})
				return {
					props: {
						style: {
							background:
								price?.minPrice > newPrice
									? 'rgba(255, 50, 50, 0.6)'
									: 'rgba(0, 203, 68, 0.6)',
						},
					},
					children: (
						<div style={{ padding: '0 8px' }}>
							<BeforeAfter after={newPrice} />
						</div>
					),
				}
			},
		},
		{
			title: <TitleWithTooltip title='Нормальная цена' />,
			dataIndex: 'normalPrice',
			key: 'normalPrice',
			width: 50,
			align: 'center' as const,
			render: (text: any) => <div style={{ padding: '0 8px' }}>{text}</div>,
		},
		{
			title: <TitleWithTooltip title='Минимальная цена' />,
			dataIndex: 'minPrice',
			key: 'minPrice',
			width: 50,
			align: 'center' as const,
			render: (text: any) => <div style={{ padding: '0 8px' }}>{text}</div>,
		},
		{
			title: <TitleWithTooltip title='Желаемая цена' />,
			dataIndex: 'desiredPrice',
			key: 'desiredPrice',
			width: 50,
			align: 'center' as const,
			render: (text: any) => <div style={{ padding: '0 8px' }}>{text}</div>,
		},
	]

	useEffect(() => {
		const applyFilters = () => {
			let filtered = data

			if (searchText.trim() !== '') {
				filtered = filtered.filter((item: any) =>
					String(item.wbArticle)
						.toLowerCase()
						.includes(searchText.toLowerCase())
				)
			}

			if (selectedSeller.length > 0) {
				const selectedSellerIds = selectedSeller.map((seller: any) => seller.id)
				filtered = filtered.filter((item: any) =>
					selectedSellerIds.includes(item.sellerId)
				)
			}

			if (selectedProducts.length > 0) {
				const selectedProductIds = selectedProducts.map(
					(product: any) => product.id
				)
				filtered = filtered.filter((item: any) =>
					selectedProductIds.includes(item.categoryId)
				)
			}

			if (selectedPriceType !== null) {
				filtered = filtered.filter((item: any) =>
					selectedPriceType
						? item.newPrice >= item.minPrice
						: item.newPrice < item.minPrice
				)
			}

			setFilteredData(filtered)
		}

		applyFilters()
	}, [searchText, selectedSeller, selectedProducts, selectedPriceType, data])

	const handleSearchChange = (e: any) => {
		setSearchText(e.target.value)
	}
	const confirmUpload = () => exportPrices({ prices: data })
	const openUploadConfirm = () => {
		validationErrors.minPrice &&
			message.error('У вас имеются цены установленные ниже минимума')
		setConfirmUploadOpen(true)
	}
	const cleanTable = () => {
		setData(null)
		setFilteredData(null)
	}

	return (
		<>
			<Row
				gutter={6}
				align={'middle'}
				justify={'end'}
				style={{ gap: 5, background: 'transparent', marginTop: 10 }}
			>
				{data && (
					<>
						<Col style={{ flexGrow: '1', minWidth: 100, maxWidth: '100%' }}>
							<Input
								className='table-header-input'
								placeholder={'Поиск'}
								value={searchText}
								onChange={handleSearchChange}
								type={'number'}
							/>
						</Col>
						<Col>
							<PickSeller
								getSellersFilterItems={getSellersFilterItems}
								selectedSeller={selectedSeller}
							/>
						</Col>
						<Col style={{ width: '98px' }}>
							<Dropdown
								trigger={['click']}
								placement={'bottomRight'}
								menu={{ items: getProductsFilterItems() }}
								overlayClassName={'dropdown-border'}
							>
								<div
									className={'analytics-header-dropdown '}
									style={{
										border:
											selectedSeller !== null && selectedSeller.length > 0
												? '1px solid #4c78ee'
												: '',
									}}
								>
									<div>Предмет</div>
									<Icon
										component={SelectIconComponent}
										style={{ marginTop: '2px', fontSize: '10px' }}
									/>
								</div>
							</Dropdown>
						</Col>
						<Col>
							<PickLessThenMin
								selectedPriceType={selectedPriceType}
								setSelectedPriceType={setSelectedPriceType}
							/>
						</Col>
					</>
				)}
				<Col>
					{data && data.length && (
						<p>
							Всего: <b>{data.length} </b>
							{filteredData.length && (
								<span>
									| На странице: <b>{filteredData.length}</b>
								</span>
							)}
						</p>
					)}
				</Col>
				<Col>
					<Button
						href='https://it-korobka.ru/api/storage/prices/new_price_sample.xlsx'
						type={'primary'}
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<DownloadOutlined />
						Скачать шаблон
					</Button>
				</Col>

				<Col>
					<Button
						onClick={() => setAddFileModal(true)}
						type={'primary'}
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						Загрузить новые цены
					</Button>
				</Col>
			</Row>

			<Row className='container' style={{ height: '100%', marginTop: 10 }}>
				{isLoading ? (
					<div
						style={{ margin: '0 auto', padding: '150px', textAlign: 'center' }}
					>
						<Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} />} />
					</div>
				) : isError ? (
					<div style={{ textAlign: 'center', padding: '20px', color: 'red' }}>
						Ошибка загрузки данных. Пожалуйста, попробуйте еще раз.
					</div>
				) : (
					<Col span={24}>
						<Table
							columns={columns}
							locale={{
								emptyText: 'Нет данных',
								filterTitle: 'Фильтр',
								filterReset: 'Очистить',
								filterEmptyText: 'Нет фильтров',
							}}
							dataSource={filteredData ? filteredData : []}
							pagination={false}
							rowKey={(record: any) => record.id}
							tableLayout='fixed'
							scroll={{
								x: 'max-content',
								y: '65vh',
								// y: '76dvh',
							}}
							size='small'
							style={{ width: '100%' }}
						/>

						{data && (
							<Col
								span={24}
								style={{
									width: '100%',
									padding: '12px 15px 0 15px',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'flex-end',
									gap: 8,
								}}
							>
								<Button type='primary' onClick={cleanTable}>
									Сбросить
								</Button>
								<Button type='primary' onClick={openUploadConfirm}>
									Применить
								</Button>
							</Col>
						)}
					</Col>
				)}
			</Row>

			<AddFileModal
				// selectedSeller={selectedSeller}
				onClose={() => setAddFileModal(false)}
				isOpen={addFileModal}
				setData={setAndValidateData}
				type={'import-prices'}
				title='Загрузить цены'
			/>
			<ConfirmationModal
				isOpen={confirmUploadOpen}
				setIsOpen={setConfirmUploadOpen}
				confirm={confirmUpload}
				title={'Вы уверены, что хотите обновить цены?'}
			/>
		</>
	)
}
