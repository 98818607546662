import ModalHeader from "features/shared/ModalHeader";
import React, {
    JSXElementConstructor,
    ReactElement,
    ReactFragment,
    ReactPortal,
    useEffect,
    useRef,
    useState
} from "react";
import {AutoComplete, Button, Checkbox, Col, Dropdown, Form, Input, MenuProps, message, Row, Spin} from "antd";
import useProductsDataById from "../../../product/hooks/useProductDataById";
import {ReactComponent as SelectIconComponent} from "../../../../assets/images/select-icon.svg";
import Icon from "@ant-design/icons";
import {ReactComponent as searchIcon} from "../../../../assets/images/search-icon.svg";
import useBiderDataById from "../../hooks/bider/useBiderDataById";
import {useBiderPutById} from "../../hooks/bider/useBiderPutById";

export default function EditProductBider({onClose, productId}: any) {

    const [form] = Form.useForm();
    const [selectedKeyWords, setSelectedKeyWords] = useState<Array<string>>([]);
    const [searchValue, setSearchValue] = useState<string>('');

    const {
        isLoading,
        product,
        setId,
    } = useProductsDataById()

    console.log(selectedKeyWords)

    const {
        biderData,
    } = useBiderDataById(productId)

    const {
        handleUpdate,
        isLoading: isLoadingUpdate,
        isError: isErrorUpdate,
        isSuccess: isSuccessUpdate,
        errorMessage
    } = useBiderPutById()


    const onFinish = (values: any) =>{

        const data: any = {};

        if (selectedKeyWords.length === 0){
            message.error('Вы не выбрали ключевые слова')
            return
        } else{
            data.keywordIds = selectedKeyWords?.map((item: any) => item?.id)
        }
        if (values.maxBudget){
            data.maxBudget = values.maxBudget
        }
        if (values.maxBet){
            data.maxBet = values.maxBet
        }
        if (values.budgetStep){
            data.budgetStep = values.budgetStep
        }
        if (values.maxFallsCount){
            data.maxFallsCount = values.maxFallsCount
        }

        console.log(data)

        handleUpdate(data, productId)
    }

    useEffect(() => {
        setId(productId)
    }, [productId])

    useEffect(() =>{
        if (isErrorUpdate){
            message.error(errorMessage)
        }
        if (isSuccessUpdate){
            message.success('Вы успешно обновили бидер')
        }
    },[isSuccessUpdate, isErrorUpdate])

    useEffect(() =>{
        setSelectedKeyWords(biderData?.keywords || [])
        form.setFieldsValue({
            maxBudget: biderData?.maxBudget,
            maxBet: biderData?.maxBet,
            maxFallsCount: biderData?.maxFallsCount,
            budgetStep: biderData?.budgetStep,
        })
    },[biderData])

    const getSellersFilterItems = () => {
        if (product?.keywordsProducts?.length === 0 || !product?.keywordsProducts) {
            return [];
        }

        let allItems: any[] = [];
        let selectedItems: any[] = [];

        product.keywordsProducts?.filter((item: any) => searchValue !== '' ? item?.title?.toLowerCase().includes(searchValue?.toLowerCase()) : item)?.forEach((item: any) => {
            const isDuplicate = selectedKeyWords?.some((sellerItem: any) => sellerItem?.id?.toString() === item?.id?.toString());

            if (!isDuplicate) {
                allItems.push({
                    label: (
                        <div
                            style={{display: "flex", gap: "10px"}}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <Checkbox
                                style={{width: '100%'}}
                                value={item.title}
                                checked={selectedKeyWords?.some((sellerItem: any) => sellerItem?.id?.toString() === item?.id?.toString())}
                                onChange={(e) => {

                                    if (selectedKeyWords?.length >= 5) {
                                        message.warning('Вы не можете выбрать больше 5 ключевых слов')
                                        return
                                    }

                                    let newSelectedKeyWord = [...selectedKeyWords];
                                    if (e.target.checked) {
                                        newSelectedKeyWord.push(item);
                                    } else {
                                        newSelectedKeyWord = newSelectedKeyWord.filter((el: any) => el?.id?.toString() !== item?.id?.toString());
                                    }
                                    setSelectedKeyWords(newSelectedKeyWord);
                                }}
                            >
                                {item.title}
                            </Checkbox>
                        </div>
                    ),
                    key: `key-word-${item.id}`,
                });
            }
        });

        product.keywordsProducts?.filter((item: any) => searchValue !== '' ? item?.title?.toLowerCase().includes(searchValue?.toLowerCase()) : item)?.forEach((item: any) => {
            const isDuplicate = selectedKeyWords?.some((sellerItem: any) => sellerItem?.id?.toString() === item?.id?.toString());

            if (isDuplicate) {
                selectedItems.push({
                    label: (
                        <div
                            style={{display: "flex", gap: "10px"}}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <Checkbox
                                style={{width: '100%'}}
                                value={item.title}
                                checked={selectedKeyWords?.some((sellerItem: any) => sellerItem?.id?.toString() === item?.id?.toString())}
                                onChange={(e) => {
                                    let newSelectedKeyWord = [...selectedKeyWords];
                                    if (e.target.checked) {
                                        newSelectedKeyWord.push(item);
                                    } else {
                                        newSelectedKeyWord = newSelectedKeyWord.filter((el: any) => el?.id?.toString() !== item?.id?.toString());
                                    }
                                    setSelectedKeyWords(newSelectedKeyWord);
                                }}
                            >
                                {item.title}
                            </Checkbox>
                        </div>
                    ),
                    key: `key-word-${item.id}`,
                });
            }
        });

        const items: MenuProps["items"] = [
            {
                label: (
                    <Input
                        value={searchValue}
                        className={"analytic-dropdown-input"}
                        onClick={(e) => e?.stopPropagation()}
                        onChange={(e) => setSearchValue(e?.target?.value)}
                        prefix={<Icon component={searchIcon}/>}
                        placeholder={"Поиск..."}
                    />
                ),
                key: "key-word-search",
            },
            ...selectedItems,
            ...allItems
        ];

        return items;
    };

    return (
        <div className={"modal-wrapper"} style={{padding: "30px", marginBottom: 200}}>
            <ModalHeader
                title={"Редактирование бидера"}
                onClose={onClose}
            />

            <Form
                onFinish={onFinish}
                form={form}
                layout={"vertical"}
            >
                <Form.Item
                    label={"Главное ключевое слово"}
                    name="keyWorld"
                >
                    <Dropdown
                        trigger={["click"]}
                        placement={"bottomRight"}
                        menu={{items: getSellersFilterItems()}}
                        overlayClassName={"dropdown-border"}
                    >
                        <div
                            className={"analytics-header-dropdown "}
                            style={{
                                border: '1px solid #babfc7',
                                boxSizing: 'border-box',
                                borderRadius: 4,
                                height: 20
                            }}>
                            <div>Главные ключевые слова</div>
                            <Icon
                                component={SelectIconComponent}
                                style={{marginTop: "2px", fontSize: "10px"}}
                            />
                        </div>
                    </Dropdown>
                </Form.Item>

                <Form.Item
                    label={"Максимальная допустимая ставка"}
                    name="maxBet"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input type={'number'}/>
                </Form.Item>

                <Form.Item
                    label={"Максимальный бюджет на день"}
                    name="maxBudget"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input type={'number'}/>
                </Form.Item>

                <Form.Item
                    label={"Шаг ставки"}
                    name="budgetStep"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input type={'number'}/>
                </Form.Item>

                <Form.Item
                    label={"Количество попыток для отключения при отрицательном росте"}
                    name="maxFallsCount"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input type={'number'}/>
                </Form.Item>

                <Row gutter={16}>
                    <Col span={12}>
                        <Button
                            className={"button"}
                            type={"primary"}
                            style={{width: "100%"}}
                            htmlType={"submit"}
                            loading={isLoadingUpdate}
                        >
                            Сохранить
                        </Button>
                    </Col>
                    <Col span={12}>
                        {" "}
                        <Button
                            className={"button"}
                            type={"primary"}
                            ghost
                            style={{width: "100%"}}
                            onClick={onClose}
                        >
                            Отмена
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}
