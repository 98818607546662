import React, { FC } from 'react'
import { ReactComponent as uploadButtonIcon } from '../../../assets/images/upload-button-icon.svg'
import { Button } from 'antd'
import Icon from '@ant-design/icons'

interface IDownload {
	handleDownload: () => void
	isLoading: boolean
	title: string
}

export const DownloadExcel: FC<IDownload> = ({
	handleDownload,
	isLoading,
	title,
}) => {
	return (
		<Button
			onClick={handleDownload}
			className='table-header-button'
			type='primary'
			loading={isLoading} // Показываем лоадер
			disabled={isLoading} // Блокируем кнопку
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			{title}
			{!isLoading && (
				<Icon
					component={uploadButtonIcon}
					style={{ fontSize: '18px', display: 'block' }}
				/>
			)}
		</Button>
	)
}
