import React, { useEffect, useState } from 'react'
import {
	Button,
	Checkbox,
	Col,
	DatePicker,
	Dropdown,
	Input,
	message,
	Pagination,
	Row,
	Select,
	Spin,
	Table,
	Tooltip,
} from 'antd'
import { useLocation, useNavigate } from 'react-router'
import Icon, {
	EditOutlined,
	InfoCircleOutlined,
	LoadingOutlined,
} from '@ant-design/icons'
import useActionsData from './hooks/actions/useActionsData'
import { PickSeller } from './components/PickSeller'
import { PickActionType } from './components/PickActionType'
import CopyToClipboard from 'react-copy-to-clipboard'
import { ReactComponent as SelectIconComponent } from 'assets/images/select-icon.svg'
import selectIcon from 'assets/images/select-icon.svg'
import { PickIsAvailable } from './components/PickIsAvailable'
import ActionsTableCol from './components/ActionsTableCol'
import { PickIsParticipating } from './components/PickIsParticipating'
import { ReactComponent as rangePickerArrow } from '../../assets/images/rangepicker-arrow.svg'
import dayjs from 'dayjs'
import { PickChangeType } from './components/PickChangeType'
import useProductsDataById from 'features/product/hooks/useProductDataById'
import HistoryHover from './components/HistoryHover'
import { PickIsNew } from './components/PickIsNew'
import { PickPageSize } from './components/PickPageSize'
import { UpdateDate } from './components/UpdateDate'
import { ConfirmationModal } from './modals/ConfirmationModal'
import { setAllChangeSeen } from 'api/actions'
import NoImage from 'assets/icon/no-image'
const { RangePicker } = DatePicker

const changeTypeColors = [
	{
		id: 1,
		color: 'rgba(0, 203, 68, 0.6)',
	},
	{
		id: 2,
		color: 'rgba(255, 203, 10, 0.6)',
	},
	{
		id: 3,
		color: 'rgba(255, 50, 50, 0.6)',
	},
	{
		id: 4,
		color: 'rgba(25, 73, 230, 0.4)',
	},
	{
		id: 5,
		color: 'rgba(255, 123, 10, 0.6)',
	},
	{
		id: 6,
		color: 'rgba(255, 52, 210, 0.6)',
	},
]

export const ActionsChangesTable = () => {
	const [isEditModalVisible, setIsEditModalVisible] = useState(false)

	const [data, setData] = useState<null | any>(null)
	const location = useLocation()
	const navigate = useNavigate()

	const {
		setDataType,
		nomenclatureChanges,
		nomenclatureChangeTypesData,
		actionsData,
		promotions,
		setPromotions,
		currentPage,
		setCurrentPage,
		currentPageSize,
		setCurrentPageSize,

		nomenclaturesLoading,
		nomenclaturesError,
		getSellersFilterItems,
		getProductsFilterItems,
		searchText,
		setSearchText,
		selectedAvailableType,
		setSelectedAvailableType,
		selectedParticipatingType,
		setSelectedParticipatingType,
		setChangeSeen,
		setLoadingCheckboxId,
		loadingCheckboxId,
		onChangeDate,
		dateSortStart,
		dateSortEnd,
		selectedChangeType,
		setSelectedChangeType,
		selectedSeenType,
		setSelectedSeenType,
		selectedSeller,
		setAllChangeSeen,
	} = useActionsData()

	const handleCancel = () => {
		setIsEditModalVisible(false)
	}

	useEffect(() => {
		setDataType('action_details')
		if (nomenclatureChanges) setData(nomenclatureChanges)
		console.log(data)
	}, [nomenclatureChanges])

	useEffect(() => {
		if (!selectedChangeType || selectedChangeType.length < 1) {
			setSelectedChangeType(['1', '2', '3', '5', '6'])
		}
	}, [selectedChangeType])

	const handleGoToNomenclature = (id: number | string) => {
		navigate(`/actions/nomenclature/${id || 0}`)
	}
	const handleGoToAction = (id: number | string) => {
		navigate(`/actions/actions-calendar/${id || 0}`)
	}

	const ProductCell = ({ product }: { product: any }) => {
		if (!product) {
			return <LoadingOutlined />
		}

		return (
			<div
				className='analytics-col-title__wrap'
				style={{ padding: '5px 6px 5px 6px', width: 90 }}
			>
				<CopyToClipboard
					text={`${product?.wbArticle || ''}`}
					onCopy={() => message.success('Текст скопирован')}
				>
					<div className='analytics-col-title' style={{ cursor: 'pointer' }}>
						{product?.wbArticle || 'Нет данных'}
					</div>
				</CopyToClipboard>
				<a href={product?.linkOnSite} target='_blank' rel='noopener noreferrer'>
					{product?.photoUrl ? (
						<img
							src={product.photoUrl}
							style={{
								width: '90px',
								minHeight: 120,
								maxHeight: 120,
							}}
							alt='Товар'
							onError={e => {
								e.currentTarget.onerror = null
								e.currentTarget.src = ''
								e.currentTarget.style.display = 'none'
								const noImage = e.currentTarget
									.nextElementSibling as HTMLElement
								if (noImage) noImage.style.display = 'block'
							}}
						/>
					) : null}
					<NoImage
						style={{
							width: '90px',
							minHeight: 120,
							maxHeight: 120,
							display: product?.photoUrl ? 'none' : 'block',
						}}
					/>
				</a>
			</div>
		)
	}

	const [confirmUnsetAllSeenVisible, setConfirmUnsetAllSeenVisible] =
		useState(false)
	const [confirmSetAllSeenVisible, setConfirmSetAllSeenVisible] =
		useState(false)

	const handleSetAllNotSeen = () => {
		// if (selectedSeller.length < 1) {
		// 	message.error('Выберите хотя бы одного продавца')
		// } else {
		setConfirmUnsetAllSeenVisible(true)
		// }
	}
	const handleSetAllSeen = () => {
		// if (selectedSeller.length < 1) {
		// 	message.error('Выберите хотя бы одного продавца')
		// } else {
		setConfirmSetAllSeenVisible(true)
		// }
	}

	const confirmSetAllSeen = () => {
		setAllChangeSeen({ seen: true })
	}

	const confirmUnsetAllSeen = () => {
		setAllChangeSeen({ seen: false })
	}

	const columns = [
		{
			title: '',
			dataIndex: 'productId',
			key: 'productId',
			width: '1.2%',
			height: 120,
			align: 'center' as const,
			render: (productId: string, product: any) => (
				<ProductCell product={product} />
			),
		},
		{
			title: 'Название акции',
			dataIndex: 'promotionName',
			key: 'promotionName',
			width: '10%',
			height: 120,
			align: 'center' as const,
			render: (text: string, action: any) => (
				<div
					className='item-title'
					style={{
						padding: '0 8px',
						fontSize: '0.88rem',
						fontWeight: 400,
						cursor: 'pointer',
					}}
					onClick={() => handleGoToAction(action.promotionId)}
				>
					{text}
				</div>
			),
		},
		{
			title: 'Продавец',
			dataIndex: 'companyName',
			key: 'companyName',
			width: '7%',
			height: 120,
			align: 'center' as const,
			render: (text: string) => <div style={{ padding: '0 8px' }}>{text}</div>,
		},
		{
			title: 'Предмет',
			dataIndex: 'categoryTitle',
			key: 'categoryTitle',
			width: '7%',
			height: 120,
			align: 'center' as const,
			render: (text: string) => <div style={{ padding: '0 8px' }}>{text}</div>,
		},
		{
			title: 'Отслеж.',
			dataIndex: 'isAvailable',
			key: 'isAvailable',
			width: '4.5%',
			align: 'center' as const,
			render: (isAvailable: any) => (
				<div
					style={{
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
					}}
				>
					{isAvailable ? 'Да' : 'Нет'}
				</div>
			),
		},
		{
			title: 'Участвует',
			dataIndex: 'inAction',
			key: 'inAction',
			width: '5%',
			align: 'center' as const,
			render: (participation: boolean) => (
				<div
					style={{
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
					}}
				>
					{participation ? 'Да' : 'Нет'}
				</div>
			),
		},
		{
			title: 'Тип изменения',
			dataIndex: 'changeType',
			key: 'changeType',
			width: '6%',
			align: 'center' as const,
			render: (changeType: any, action: any) => {
				const colorData = changeTypeColors.find(item => item.id === changeType)
				const color = colorData ? colorData.color : 'rgba(255, 255, 255, 0)'
				const titleData = nomenclatureChangeTypesData.find(
					(item: any) => item.id === changeType
				)
				const title = titleData ? titleData.title : 'Неизвестный тип'
				return (
					<Tooltip title={title}>
						<div
							style={{
								display: 'flex',
								width: '100%',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<div
								style={{
									whiteSpace: 'nowrap',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									background: color,
									borderRadius: 5,
									padding: '0 5px',
									maxWidth: 165,
									minWidth: 165,
								}}
							>
								{title}
							</div>
						</div>
					</Tooltip>
				)
			},
		},
		{
			title: 'Загружаемая скидка для уч. в акции',
			dataIndex: 'planDiscount',
			key: 'planDiscount',
			width: '10%',
			align: 'center' as const,
			render: (discount: any, action: any) => (
				<div style={{ padding: '0 8px' }}>{`${discount}%`}</div>
			),
		},
		{
			title: 'Дата обновления записи в системе по МСК',
			dataIndex: 'updatedAt',
			key: 'updatedAt',
			width: '10%',
			align: 'center' as const,
			render: (date: any, action: any) => {
				if (!date) return <div style={{ padding: '0 8px' }}>Нет данных</div>
				return (
					<div style={{ padding: '0 8px' }}>
						{dayjs(date).format('DD.MM.YYYY HH:mm')}
					</div>
				)
			},
		},
		{
			title: 'История',
			dataIndex: 'history',
			key: 'history',
			width: '5%',
			align: 'center' as const,
			render: (history: any, action: any) => {
				if (!history || history.length === 0) {
					return <div>Нет данных</div>
				}

				const [latest, previous] = history

				return (
					<div style={{ padding: '0 8px' }}>
						<HistoryHover
							title=' '
							width='100%'
							icon={<InfoCircleOutlined size={40} />}
							isShowHover={true}
							hoverData={history}
							type='nomenclatures'
						/>
						{history.length > 1 && (
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									gap: 7,
									width: '100%',
								}}
							>
								<UpdateDate updatedAt={latest?.updatedAt} />
								{previous?.planDiscount !== latest?.planDiscount && (
									<span>
										<span style={{ fontWeight: 600 }}>Скидка:</span>{' '}
										{previous?.planDiscount}% → {latest?.planDiscount}%
									</span>
								)}
								{previous?.inAction !== latest?.inAction && (
									<div>
										<span>{previous?.inAction ? 'У' : 'Не у'}частвует</span>→
										<span>
											{latest?.inAction ? '' : 'Не'} участвует в акции,
										</span>
									</div>
								)}
								{/* {previous?.isNew !== latest?.isNew && (
									<div>
										<span>{previous?.isNew ? 'П' : 'Не п'}росмотрен,</span>
										<span> → {latest?.isNew ? '' : 'Не '}просмотрен</span>
									</div>
								)} */}
							</div>
						)}
					</div>
				)
			},
		},
		{
			title: 'Просмотрено',
			dataIndex: 'isNew',
			key: 'isNew',
			width: '5%',
			align: 'center' as const,
			render: (isNew: boolean, action: any) => (
				<Checkbox
					checked={!isNew}
					disabled={loadingCheckboxId === action.id}
					onChange={() => {
						console.log(action.id)
						setLoadingCheckboxId(action.id)
						setChangeSeen({
							id: action.id,
							seen: isNew,
						})
					}}
				>
					{loadingCheckboxId === action.id && <Spin size='small' />}
				</Checkbox>
			),
		},
	]

	return (
		<>
			<div style={{ backgroundColor: '#f8f8f8' }}>
				<Row gutter={6} align={'middle'} justify={'end'} style={{ gap: 5 }}>
					<Col style={{ flexGrow: '1' }}>
						<Input
							className='table-header-input'
							placeholder={'Поиск'}
							value={searchText}
							onChange={e => setSearchText(e.target.value)}
							type={'number'}
						/>
					</Col>
					<Col
						style={{
							width: '275px',
							paddingTop: 0,
							paddingBottom: 0,
						}}
					>
						<Button
							type='primary'
							onClick={handleSetAllSeen}
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							Установить все просмотренными
						</Button>
					</Col>
					<Col style={{ width: '300px', paddingTop: 0, paddingBottom: 0 }}>
						<Button
							type='primary'
							onClick={handleSetAllNotSeen}
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							Установить все не просмотренными
						</Button>
					</Col>
					<Col style={{ width: '120px' }}>
						<PickSeller
							getSellersFilterItems={getSellersFilterItems}
							selectedSeller={selectedSeller}
						/>
					</Col>
					<Col style={{ width: '180px' }}>
						<PickIsAvailable
							selectedAvailableType={selectedAvailableType}
							setSelectedAvailableType={setSelectedAvailableType}
						/>
					</Col>
					<Col style={{ width: '150px' }}>
						<PickIsParticipating
							selectedParticipatingType={selectedParticipatingType}
							setSelectedParticipatingType={setSelectedParticipatingType}
						/>
					</Col>
					<Col style={{ width: '160px' }}>
						<PickIsNew
							selectedSeenType={selectedSeenType}
							setSelectedSeenType={setSelectedSeenType}
						/>
					</Col>
					<Col style={{ width: '155px' }}>
						<PickChangeType
							selectedType={selectedChangeType}
							setSelectedType={setSelectedChangeType}
							data={nomenclatureChangeTypesData}
							title={'Тип изменений'}
						/>
					</Col>
					<Col style={{ width: '170px' }}>
						<PickChangeType
							selectedType={promotions}
							setSelectedType={setPromotions}
							data={actionsData}
							title={'Выберите акцию'}
						/>
					</Col>
					<Col style={{ width: '98px' }}>
						<Dropdown
							trigger={['click']}
							placement={'bottomRight'}
							menu={{ items: getProductsFilterItems() }}
							overlayClassName={'dropdown-border'}
						>
							<div className={'analytics-header-dropdown '}>
								<div>Предмет</div>
								<Icon
									component={SelectIconComponent}
									style={{ marginTop: '2px', fontSize: '10px' }}
								/>
							</div>
						</Dropdown>
					</Col>
					{/* <Col>
						<RangePicker
							onChange={onChangeDate}
							placeholder={['Начало', 'Конец']}
							separator={<Icon component={rangePickerArrow} />}
							format={'DD.MM.YYYY'}
							value={[dateSortStart, dateSortEnd]}
						/>
					</Col> */}
				</Row>

				<Row className='container' style={{ marginTop: '10px' }}>
					{nomenclaturesLoading ? (
						<div
							style={{
								margin: '0 auto',
								padding: '150px',
								textAlign: 'center',
							}}
						>
							<Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} />} />
						</div>
					) : nomenclaturesError ? (
						<div style={{ textAlign: 'center', padding: '20px', color: 'red' }}>
							Ошибка загрузки данных. Пожалуйста, попробуйте еще раз.
						</div>
					) : (
						<Col span={24}>
							<Table
								columns={columns}
								locale={{
									emptyText: 'Нет данных',
									filterTitle: 'Фильтр',
									filterReset: 'Очистить',
									filterEmptyText: 'Нет фильтров',
								}}
								dataSource={data ? data.data : []}
								pagination={false}
								rowKey={(record: any) => record.id}
								tableLayout='fixed'
								scroll={{
									x: 'max-content',
									y: '65vh',
									// y: '76dvh',
								}}
								size='small'
								style={{ width: '100%' }}
							/>
							<Col span={24}>
								<Row style={{ alignItems: 'center', justifyContent: 'center' }}>
									<Pagination
										onChange={page => setCurrentPage(page)}
										current={currentPage}
										defaultCurrent={1}
										pageSize={parseInt(currentPageSize)}
										total={data?.total}
										style={{
											textAlign: 'center',
											padding: '16px 0',
										}}
										showSizeChanger={false}
									/>
									<Col style={{ width: '190px' }}>
										<PickPageSize
											currentPageSize={currentPageSize}
											setCurrentPage={setCurrentPage}
											setCurrentPageSize={setCurrentPageSize}
										/>
									</Col>
								</Row>
							</Col>
						</Col>
					)}
				</Row>
			</div>
			<ConfirmationModal
				isOpen={confirmSetAllSeenVisible}
				setIsOpen={setConfirmSetAllSeenVisible}
				confirm={confirmSetAllSeen}
				title={'Вы уверены, что хотите просмотреть все товары?'}
			/>
			<ConfirmationModal
				isOpen={confirmUnsetAllSeenVisible}
				setIsOpen={setConfirmUnsetAllSeenVisible}
				confirm={confirmUnsetAllSeen}
				title={'Вы уверены, что хотите отменить просмотр всех товаров?'}
			/>
		</>
	)
}
