import React, { FC, useEffect, useState } from 'react'
import { ConfirmationModal } from '../modals/ConfirmationModal'
import { Button, Col, message, Row, Dropdown, Menu } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { UploadExcel } from './UploadExcel'
import TitleWithTooltip from './TitleWithTooltip'
import useActionsData from '../hooks/actions/useActionsData'

interface IActionWithActions {
	selectedSeller: any
	promotionId: string
	selectedNomenclature: any
	setAllParticipating: any
	setAllNotParticipating: any
	setAddByControlPrice: any
	allGoodsNotParticipating: boolean
}

const ActionWithActions: FC<IActionWithActions> = ({
	selectedSeller,
	promotionId,
	selectedNomenclature,
	setAllParticipating,
	setAllNotParticipating,
	setAddByControlPrice,
	allGoodsNotParticipating,
}) => {
	const [
		confirmUnsetAllParticipatingVisible,
		setConfirmUnsetAllParticipatingVisible,
	] = useState(false)
	const [
		confirmSetAllParticipatingVisible,
		setConfirmSetAllParticipatingVisible,
	] = useState(false)
	const [openByControlPrice, setOpenByControlPrice] = useState<boolean>(false)

	const [goodsParticipating, setGoodsParticipating] = useState<boolean>(
		!allGoodsNotParticipating
	)

	useEffect(() => {
		setGoodsParticipating(!allGoodsNotParticipating)
		console.log(goodsParticipating, allGoodsNotParticipating)
	}, [selectedSeller, allGoodsNotParticipating])

	const confirmSetAllParticipating = () => {
		setAllParticipating({
			promotion: promotionId,
			nm_ids: selectedNomenclature,
		})
	}

	const confirmUnsetAllParticipating = () => {
		setAllNotParticipating({
			promotion: promotionId,
			nm_ids: selectedNomenclature,
		})
		setGoodsParticipating(false)
	}

	const handleSetAllParticipating = () => {
		if (selectedSeller.length !== 1) {
			message.error('Выберите одного продавца')
		} else {
			setConfirmSetAllParticipatingVisible(true)
		}
	}

	const handleSetAllNotParticipating = () => {
		if (selectedSeller.length !== 1) {
			message.error('Выберите одного продавца')
		} else {
			setConfirmUnsetAllParticipatingVisible(true)
		}
	}

	const handleAddByControlPrice = () => {
		console.log(
			`goods participating ${goodsParticipating} all not participating ${allGoodsNotParticipating}`
		)
		if (selectedSeller.length !== 1) {
			message.error('Выберите одного продавца')
		} else if (goodsParticipating) {
			message.error(
				'Перед активацией товара выведите весь товар из акции кнопкой "Убрать весь товар из акции"'
			)
		} else {
			setAddByControlPrice({
				promotion: promotionId,
				nm_ids: selectedNomenclature,
			})
		}
	}

	const menu = (
		<Menu>
			<Menu.Item key='setParticipating'>
				<Col style={{ minWidth: 280, maxWidth: 280 }}>
					<Button
						type='primary'
						onClick={handleSetAllParticipating}
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							width: '100%',
						}}
					>
						<TitleWithTooltip
							title={`Поставить ${
								selectedNomenclature.length > 0 ? 'выбранные' : 'все'
							} 
									товары ${selectedSeller.length > 0 ? 'продавца' : ' '} в акцию`}
							rows={1}
							color={'#fff'}
						/>
					</Button>
				</Col>
			</Menu.Item>
			<Menu.Item key='unsetParticipating'>
				<Col style={{ minWidth: 280, maxWidth: 280 }}>
					<Button
						type='primary'
						onClick={handleSetAllNotParticipating}
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							width: '100%',
						}}
					>
						<TitleWithTooltip
							title={`Убрать ${
								selectedNomenclature.length > 0 ? 'выбранные' : 'все'
							} ${' '}
									товары ${selectedSeller.length > 0 ? 'продавца' : ' '} из акции`}
							rows={1}
							color={'#fff'}
						/>
					</Button>
				</Col>
			</Menu.Item>
			<Menu.Item key='addByControlPrice'>
				<Col style={{ minWidth: 280, maxWidth: 280 }}>
					<Button
						type='primary'
						onClick={() => setOpenByControlPrice(true)}
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							width: '100%',
						}}
					>
						<TitleWithTooltip
							title={'Активировать по контрольной цене'}
							rows={1}
							color={'#fff'}
						/>
					</Button>
				</Col>
			</Menu.Item>
			<Menu.Item key='uploadEnable'>
				<Col style={{ minWidth: 280, maxWidth: 280 }}>
					<UploadExcel
						title='Ввод в акцию'
						type='enable'
						sellers={selectedSeller}
						promotion_id={promotionId}
					/>
				</Col>
			</Menu.Item>
			<Menu.Item key='uploadDisable'>
				<Col style={{ minWidth: 280, maxWidth: 280 }}>
					<UploadExcel
						title='Вывод из акции'
						type='disable'
						sellers={selectedSeller}
						promotion_id={promotionId}
					/>
				</Col>
			</Menu.Item>
		</Menu>
	)

	return (
		<>
			<Row gutter={6} align='middle' justify='start' style={{ gap: 5 }}>
				<Col>
					<Dropdown
						overlay={menu}
						trigger={['click']}
						overlayClassName='dropdown-border'
						placement='bottomRight'
					>
						<Button
							className='analytics-header-dropdown'
							style={{
								border: 0,
							}}
						>
							Действия с акциями <DownOutlined />
						</Button>
					</Dropdown>
				</Col>
			</Row>

			<ConfirmationModal
				isOpen={confirmSetAllParticipatingVisible}
				setIsOpen={setConfirmSetAllParticipatingVisible}
				confirm={confirmSetAllParticipating}
				title={`Вы уверены, что хотите поставить ${
					selectedNomenclature.length ? 'выбранные' : 'все'
				} товары ${selectedSeller.length > 0 ? 'продавца' : ' '} в акцию?`}
			/>
			<ConfirmationModal
				isOpen={confirmUnsetAllParticipatingVisible}
				setIsOpen={setConfirmUnsetAllParticipatingVisible}
				confirm={confirmUnsetAllParticipating}
				title={`Вы уверены, что хотите убрать ${
					selectedNomenclature.length ? 'выбранные' : 'все'
				} товары ${selectedSeller.length > 0 ? 'продавца' : ' '} из акции?`}
			/>
			<ConfirmationModal
				isOpen={openByControlPrice}
				setIsOpen={setOpenByControlPrice}
				confirm={handleAddByControlPrice}
				title={'Не забудьте загрузить свежую контрольную цену'}
			/>
		</>
	)
}

export default ActionWithActions
