import React, { SVGProps } from 'react'

const NoImage = ({ ...props }: SVGProps<SVGSVGElement>) => {
	return (
		<>
			<svg
				xmlns='http://www.w3.org/2000/svg'
				xmlnsXlink='http://www.w3.org/1999/xlink'
				version='1.1'
				id='svg2'
				viewBox='0 0 329.77792 406.00738'
				height='406.00739'
				width='329.77792'
				{...props}
			>
				<defs id='defs4'>
					<linearGradient id='linearGradient967'>
						<stop
							id='stop963'
							offset='0'
							style={{ stopColor: '#c2c2c2', stopOpacity: 1 }}
						/>
						<stop
							id='stop965'
							offset='1'
							style={{ stopColor: '#9f9f9f', stopOpacity: 1 }}
						/>
					</linearGradient>
					<linearGradient
						gradientTransform='translate(-45.254833,0.35355338)'
						gradientUnits='userSpaceOnUse'
						y2='108.77648'
						x2='658.45801'
						y1='6.5995569'
						x1='660.06653'
						id='linearGradient969'
						xlinkHref='#linearGradient967'
					/>
				</defs>
				<metadata id='metadata7'></metadata>
				<g transform='translate(-399.13437,-122.79051)' id='layer1'>
					<rect
						ry='7.0136137'
						y='122.79051'
						x='399.13437'
						height='406.00739'
						width='329.77792'
						id='rect1017'
						style={{
							fill: '#eeeeee',
							fillOpacity: 1,
							fillRule: 'nonzero',
							stroke: 'none',
							strokeWidth: 1.59331,
							strokeLinecap: 'round',
							strokeLinejoin: 'round',
							strokeMiterlimit: 4,
							strokeDasharray: 'none',
							strokeOpacity: 1,
						}}
					/>
					<g transform='translate(43.778173,191.04163)' id='g1015'>
						<rect
							style={{
								fill: '#9f9f9f',
								fillOpacity: 1,
								fillRule: 'nonzero',
								stroke: 'none',
								strokeWidth: 2.2995,
								strokeLinecap: 'round',
								strokeLinejoin: 'round',
								strokeMiterlimit: 4,
								strokeDasharray: 'none',
								strokeOpacity: 1,
							}}
							id='rect1002'
							width='82.489967'
							height='90.470001'
							x='398.75827'
							y='178.74706'
							ry='8.3970251'
							transform='rotate(-16.342822)'
						/>
						<g id='g1000' transform='rotate(16.320529,538.13563,-184.89727)'>
							<rect
								ry='4.5961938'
								y='1.6498091'
								x='547.18585'
								height='115.96551'
								width='107.83378'
								id='rect961'
								style={{
									fill: 'url(#linearGradient969)',
									fillOpacity: 1,
									fillRule: 'nonzero',
									stroke: '#ffffff',
									strokeWidth: 5.398,
									strokeLinecap: 'round',
									strokeLinejoin: 'round',
									strokeMiterlimit: 4,
									strokeDasharray: 'none',
								}}
							/>
							<g
								style={{
									stroke: '#ffffff',
									strokeWidth: 13.0708,
									strokeMiterlimit: 4,
									strokeDasharray: 'none',
									strokeOpacity: 1,
								}}
								transform='matrix(0.17265471,0,0,0.17265471,512.49324,-6.3296456)'
								id='g875'
							>
								<rect
									style={{
										color: '#000000',
										clipRule: 'nonzero',
										display: 'inline',
										overflow: 'visible',
										visibility: 'visible',
										opacity: 1,
										isolation: 'auto',
										mixBlendMode: 'normal',
										colorInterpolation: 'sRGB',
										fill: '#cccccc',
										fillOpacity: 1,
										fillRule: 'evenodd',
										stroke: '#ffffff',
										strokeWidth: 13.0708,
										strokeLinecap: 'round',
										strokeLinejoin: 'round',
										strokeMiterlimit: 4,
										strokeDasharray: 'none',
										strokeDashoffset: 0,
										strokeOpacity: 1,
										marker: 'none',
										colorRendering: 'auto',
										imageRendering: 'auto',
										shapeRendering: 'auto',
										textRendering: 'auto',
									}}
									id='rect3338'
									width='491.10556'
									height='449.99814'
									x='270'
									y='107.36227'
								/>
								<rect
									style={{
										color: '#000000',
										clipRule: 'nonzero',
										display: 'inline',
										overflow: 'visible',
										visibility: 'visible',
										opacity: 1,
										isolation: 'auto',
										mixBlendMode: 'normal',
										colorInterpolation: 'sRGB',
										fill: '#ffffff',
										fillOpacity: 1,
										fillRule: 'evenodd',
										stroke: '#ffffff',
										strokeWidth: 13.0708,
										strokeLinecap: 'round',
										strokeLinejoin: 'round',
										strokeMiterlimit: 4,
										strokeDasharray: 'none',
										strokeDashoffset: 0,
										strokeOpacity: 1,
										marker: 'none',
										colorRendering: 'auto',
										imageRendering: 'auto',
										shapeRendering: 'auto',
										textRendering: 'auto',
									}}
									id='rect3342'
									width='491.10559'
									height='209.99976'
									x='270'
									y='107.36227'
								/>
								<path
									style={{
										color: '#000000',
										clipRule: 'nonzero',
										display: 'inline',
										overflow: 'visible',
										visibility: 'visible',
										opacity: 1,
										isolation: 'auto',
										mixBlendMode: 'normal',
										colorInterpolation: 'sRGB',
										fill: '#cccccc',
										fillOpacity: 1,
										fillRule: 'evenodd',
										stroke: '#ffffff',
										strokeWidth: 13.0708,
										strokeLinecap: 'round',
										strokeLinejoin: 'round',
										strokeMiterlimit: 4,
										strokeDasharray: 'none',
										strokeDashoffset: 0,
										strokeOpacity: 1,
										marker: 'none',
										paintOrder: 'stroke',
										colorRendering: 'auto',
										imageRendering: 'auto',
										shapeRendering: 'auto',
										textRendering: 'auto',
									}}
									d='m 300,317.36255 38.46147,-53.53818 60.53097,-45.16084 15.88277,18.57394 13.61285,-38.68356 8.20133,-2.98188 13.3106,-28.2093 180,179.99979'
									id='path3344'
								/>
								<path
									style={{
										color: '#000000',
										clipRule: 'nonzero',
										display: 'inline',
										overflow: 'visible',
										visibility: 'visible',
										opacity: 1,
										isolation: 'auto',
										mixBlendMode: 'normal',
										colorInterpolation: 'sRGB',

										fill: '#b3b3b3',
										fillOpacity: 1,
										fillRule: 'evenodd',
										stroke: '#ffffff',
										strokeWidth: 13.0708,
										strokeLinecap: 'round',
										strokeLinejoin: 'round',
										strokeMiterlimit: 4,
										strokeDasharray: 'none',
										strokeDashoffset: 0,
										strokeOpacity: 1,
										marker: 'none',
										paintOrder: 'stroke',
										colorRendering: 'auto',
										imageRendering: 'auto',
										shapeRendering: 'auto',
										textRendering: 'auto',
									}}
									d='m 180,60 c 4.09311,16.474688 7.71219,33.067277 10.85156,49.75 2.38256,12.66097 4.48857,25.37408 6.31641,38.12695 l -22.06445,-7.16015 -46.11133,-29.41602 5.32422,46.42578 -1.61524,24.78711 10.05274,30.37695 73.18554,-11.75585 L 300,180 252.19922,102.56641 242.5,117.5 215.375,95.375 Z'
									transform='translate(270,107.36227)'
									id='path3390-0'
								/>
								<path
									style={{
										color: '#000000',
										clipRule: 'nonzero',
										display: 'inline',
										overflow: 'visible',
										visibility: 'visible',
										opacity: 1,
										isolation: 'auto',
										mixBlendMode: 'normal',
										colorInterpolation: 'sRGB',

										fill: '#cccccc',
										fillOpacity: 1,
										fillRule: 'evenodd',
										stroke: '#ffffff',
										strokeWidth: 13.0708,
										strokeLinecap: 'round',
										strokeLinejoin: 'round',
										strokeMiterlimit: 4,
										strokeDasharray: 'none',
										strokeDashoffset: 0,
										strokeOpacity: 1,
										marker: 'none',
										paintOrder: 'stroke',
										colorRendering: 'auto',
										imageRendering: 'auto',
										shapeRendering: 'auto',
										textRendering: 'auto',
									}}
									d='m 419.99999,347.36252 81.89918,-74.42959 18.50574,-9.68009 23.6512,-44.18894 25.94388,-21.70121 179.99999,179.99979'
									id='path3358'
								/>
								<path
									style={{
										color: '#000000',
										clipRule: 'nonzero',
										display: 'inline',
										overflow: 'visible',
										visibility: 'visible',
										opacity: 1,
										isolation: 'auto',
										mixBlendMode: 'normal',
										colorInterpolation: 'sRGB',

										fill: '#b3b3b3',
										fillOpacity: 1,
										fillRule: 'evenodd',
										stroke: '#ffffff',
										strokeWidth: 13.0708,
										strokeLinecap: 'round',
										strokeLinejoin: 'round',
										strokeMiterlimit: 4,
										strokeDasharray: 'none',
										strokeDashoffset: 0,
										strokeOpacity: 1,
										marker: 'none',
										paintOrder: 'stroke',
										colorRendering: 'auto',
										imageRendering: 'auto',
										shapeRendering: 'auto',
										textRendering: 'auto',
									}}
									d='m 569.99999,197.36269 35.9388,80.91289 v 30.11038 30.11038 l 22.45864,19.46652 c 6.52453,-6.45031 14.14893,-11.78526 22.44431,-15.70477 14.8245,-7.00447 31.33823,-9.35959 47.17057,-13.6217 6.42776,-1.73037 12.90672,-3.85419 18.21343,-7.87277 1.35174,-1.02362 2.61592,-2.16281 3.77424,-3.40107 h -30 l -40.52149,-40.55006 -29.85645,-48.91972 -10.25307,8.83886 z'
									id='path3386'
								/>
								<path
									style={{
										color: '#000000',
										clipRule: 'nonzero',
										display: 'inline',
										overflow: 'visible',
										visibility: 'visible',
										opacity: 1,
										isolation: 'auto',
										mixBlendMode: 'normal',
										colorInterpolation: 'sRGB',

										fill: '#ffffff',
										fillOpacity: 1,
										fillRule: 'evenodd',
										stroke: '#ffffff',
										strokeWidth: 13.0708,
										strokeLinecap: 'round',
										strokeLinejoin: 'round',
										strokeMiterlimit: 4,
										strokeDasharray: 'none',
										strokeDashoffset: 0,
										strokeOpacity: 1,
										marker: 'none',
										paintOrder: 'stroke',
										colorRendering: 'auto',
										imageRendering: 'auto',
										shapeRendering: 'auto',
										textRendering: 'auto',
									}}
									d='m 419.99999,557.36227 c -0.41699,-9.60089 -8.81759,-17.60878 17.1252,-30.66806 31.8318,-16.02389 125.895,-35.88836 152.1537,-59.98434 19.42709,-17.82687 -70.4154,-37.66945 -55.0191,-59.07323 6.981,-9.70528 59.037,-19.96947 82.1463,-30.27386 21.90569,-9.76799 15.14129,-19.80328 31.4046,-29.97507 15.7092,-9.82558 68.3499,-19.77358 72.18929,-30.02516 -10.41359,10.52188 -68.83379,20.40327 -89.99999,30.00026 -22.3377,10.128 -21.4689,19.93018 -49.4313,29.48367 -30.1245,10.29239 -89.142,20.55268 -102.7077,30.51626 -28.4133,20.86858 46.863,42.59995 16.2024,59.99993 C 452.54309,490.92554 344.7219,510.65712 300,527.3626 c -30.9039,11.54369 -28.4079,17.74799 -30,29.99967'
									id='path3370'
								/>
							</g>
						</g>
					</g>
					<text
						id='text1021'
						y='412.12527'
						x='562.00677'
						style={{
							fontStyle: 'normal',
							fontWeight: 'normal',
							fontSize: '32px',
							lineHeight: 1.25,
							fontFamily: 'sans-serif',
							fill: '#767676',
							fillOpacity: 1,
							stroke: 'none',
						}}
						xmlSpace='preserve'
					>
						<tspan
							style={{
								fontStyle: 'normal',
								fontVariant: 'normal',
								fontWeight: 'bold',
								fontStretch: 'normal',
								fontSize: '32px',
								fontFamily: 'sans-serif',
								textAlign: 'center',
								textAnchor: 'middle',
							}}
							y='412.12527'
							x='562.00677'
							id='tspan1019'
						>
							NO IMAGE
						</tspan>
						<tspan
							style={{
								fontStyle: 'normal',
								fontVariant: 'normal',
								fontWeight: 'bold',
								fontStretch: 'normal',
								fontSize: '32px',
								fontFamily: 'sans-serif',
								textAlign: 'center',
								textAnchor: 'middle',
							}}
							id='tspan1023'
							y='452.12527'
							x='562.00677'
						>
							AVAILABLE
						</tspan>
					</text>
				</g>
			</svg>
		</>
	)
}

export default NoImage
