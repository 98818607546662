import { Tooltip } from 'antd'
import React from 'react'

const TitleWithTooltip = ({
	title,
	rows = 3,
	color = '#000000E0',
	fontSize = 'inherit',
	fontWeight = 'inerit',
}: {
	title: string
	rows?: number
	color?: string
	fontSize?: string | number
	fontWeight?: string | number
}) => {
	return (
		<Tooltip title={title}>
			<span
				style={{
					display: '-webkit-box',
					WebkitBoxOrient: 'vertical',
					WebkitLineClamp: rows,
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					whiteSpace: 'normal',
					color: color,
					fontSize: fontSize,
					fontWeight: fontWeight,
				}}
			>
				{title}
			</span>
		</Tooltip>
	)
}

export default TitleWithTooltip
