import React, { FC, useEffect, useState } from 'react'
import { Button, Col, message } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import AddFileModal from '../modals/AddFile'

interface IDownload {
	title: string
	type: 'enable' | 'disable'
	sellers: any[]
	promotion_id: string
}

export const UploadExcel: FC<IDownload> = ({
	title,
	type,
	sellers,
	promotion_id,
}) => {
	const [isOpen, setIsOpen] = useState(false)
	const [seller, setSeller] = useState<string | null>(null)

	const onClick = () => {
		if (sellers.length !== 1) {
			message.error('Выберите одного продавца')
		} else {
			setSeller(sellers[0].id)
		}
	}

	useEffect(() => {
		if (seller) {
			setIsOpen(true)
		}
	}, [seller])

	return (
		<>
			<Button
				onClick={onClick}
				// className='table-header-button'
				type='primary'
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					width: '100%',
				}}
			>
				{title}
			</Button>
			<AddFileModal
				title={title}
				isOpen={isOpen}
				onClose={() => {
					setIsOpen(false)
					setSeller(null)
				}}
				type={type}
				seller={seller ? seller : ''}
				promotion={promotion_id}
			/>
		</>
	)
}
