import React, { useEffect, useState } from 'react'
import { Dropdown, Menu, Checkbox } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { ColumnType } from 'antd/es/table'

interface Column extends Partial<ColumnType<any>> {
	key: string
	title: any
	visible: boolean
}

interface PickVisibleColumnsProps {
	columns: Column[]
	onToggle: (key: string, visible: boolean) => void
}

export const PickVisibleColumns: React.FC<PickVisibleColumnsProps> = ({
	columns,
	onToggle,
}) => {
	const [visible, setVisible] = useState(false)
	const [isEdited, setIsEdited] = useState(
		columns.some((item: any) => item.visible === false)
	)

	useEffect(() => {
		setIsEdited(columns.some((item: any) => item.visible === false))
	}, [columns])

	const menu = (
		<Menu>
			{columns.map(
				col =>
					col.title && (
						<Menu.Item key={col.key}>
							<div
								style={{ width: '100%', cursor: 'pointer' }}
								onClick={e => e.stopPropagation()}
							>
								<Checkbox
									checked={col.visible}
									onChange={e => onToggle(col.key, e.target.checked)}
								>
									{col.title || 'Колонка без названия'}
								</Checkbox>
							</div>
						</Menu.Item>
					)
			)}
		</Menu>
	)

	return (
		<Dropdown
			overlay={menu}
			trigger={['click']}
			open={visible}
			onOpenChange={setVisible}
			overlayClassName='dropdown-border'
			placement='bottomRight'
		>
			<div
				className='analytics-header-dropdown'
				onClick={() => setVisible(!visible)}
				style={{
					border: isEdited ? '1px solid #4c78ee' : '',
				}}
			>
				<div style={{ paddingRight: 5 }}>Колонки</div>
				<DownOutlined style={{ fontSize: '10px', marginTop: '2px' }} />
			</div>
		</Dropdown>
	)
}
