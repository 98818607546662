import React, {useState} from "react";
import {
    Button,
    Col, DatePicker,
    Dropdown,
    Input,
    Menu,
    Pagination,
    Radio,
    Row,
    Select,
} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import {Spin} from "antd";
import Icon from "@ant-design/icons";
import selectIcon from "assets/images/select-icon.svg";
import {ReactComponent as SelectIconComponent} from "assets/images/select-icon.svg";
import {ReactComponent as uploadButtonIcon} from "assets/images/upload-button-icon.svg";
import {ReactComponent as columnsIcon} from "assets/images/columns-icon.svg";
import {ReactComponent as filterIcon} from "assets/images/filter-icon.svg";
import AnalyticsTableRow from "./AnalyticsTableRow";
import useAnalyticsData from "../hooks/useAnalyticsData";
import {useAnalyticsTableColumnFilters} from "../hooks/useAnalyticsTableColumnFilters";
import {useAnalyticsTableFilters} from "../hooks/useAnalyticsTableFilters";
import {useNavigate} from "react-router-dom";
import {DownloadAnaliticsButton} from "../components/DownloadAnaliticsButton";
import {ReactComponent as rangePickerArrow} from "../../../assets/images/rangepicker-arrow.svg";
import useEmissionMainTableFilterBuId from "../../kiz/hooks/emission/useEmissionMainTableFilterBuId";
import usAnalyticsFiltersNew from "../hooks/usAnalyticsFiltersNew";
import { DownloadExcel } from "../components/DownloadExcel";

const {RangePicker} = DatePicker;

export default function AnalyticsTable() {
    const navigate = useNavigate();

    const {
        analytics,
        isLoading,
        currentPage,
        currentPageSize,
        showMode,
        searchText,
        setCurrentPage,
        setCurrentPageSize,
        setShowMode,
        setSearchText,
        selectedSizes,
        selectedProducts,
        selectedSeller,
        getSizeFilterItems,
        getProductsFilterItems,
        getSellersFilterItems,
        onChangeData,
        dateSortStart,
        dateSortEnd,
    } = useAnalyticsData();

    const {getColumnFilterItems, filters: columnFilters} = useAnalyticsTableColumnFilters();

    const {getSelectedFilter, getFilterMenuItems, sortField} = useAnalyticsTableFilters();
    const {filterMenuItems} = usAnalyticsFiltersNew();

    const [filterMenuOpened, setFilterMenuOpened] = useState<boolean>(false);

    const getAnalyticsToRender = () => {
        if (!analytics) {
            return [];
        }

        if (!sortField) {
            return analytics.data;
        }
        let sortedAnalytics = [...analytics.data];
        sortedAnalytics.sort((a: Analytics, b: Analytics) => {
            if (
                a[sortField as keyof Analytics] <
                b[sortField as keyof Analytics]
            ) {
                return -1;
            }
            if (
                a[sortField as keyof Analytics] >
                b[sortField as keyof Analytics]
            ) {
                return 1;
            }
            return 0;
        });

        return sortedAnalytics;
        // return analytics.data;
    };

    const renderAnalyticsTable = () => {
        if (isLoading) {
            return (
                <div
                    style={{
                        padding: "150px",
                        textAlign: "center",
                    }}
                >
                    <Spin
                        indicator={<LoadingOutlined style={{fontSize: 24}}/>}
                    />
                </div>
            );
        }

        if (analytics?.data) {
            return (
                <>
                    {getAnalyticsToRender().map((analytic: any) => (
                        <div>
                            <AnalyticsTableRow
                                analytic={analytic}
                                filters={columnFilters}
                            />
                        </div>
                    ))}
                    <Pagination
                        onChange={(page, pageSize): any => setCurrentPage(page)}
                        defaultCurrent={1}
                        current={currentPage}
                        pageSize={parseInt(currentPageSize)}
                        total={analytics.total}
                        style={{
                            textAlign: "center",
                            padding: "16px 0",
                        }}
                        showSizeChanger={false}
                    />
                </>
            );
        }

        return (
            <div
                style={{
                    padding: "150px",
                    textAlign: "center",
                }}
            >
                Нет данных
            </div>
        );
    };

    return (
        <div>
            <Row gutter={6} align={"middle"}>
                <Col style={{flexGrow: "1"}}>
                    <Input
                        className="table-header-input"
                        placeholder={"Поиск"}
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        type={"number"}
                    />
                </Col>
                <Col style={{width: "220px"}}>
                    <Radio.Group
                        defaultValue={showMode}
                        style={{width: "100%"}}
                        onChange={(e) => setShowMode(e.target.value)}
                    >
                        <Radio.Button
                            value={"articule"}
                            style={{
                                width: "50%",
                                paddingTop: "4px",
                                textAlign: "center",
                                paddingRight: "1.5px",
                            }}
                        >
                            По артикульно
                        </Radio.Button>
                        <Radio.Button
                            value={"size"}
                            style={{
                                width: "50%",
                                paddingTop: "4px",
                                textAlign: "center",
                                paddingLeft: "1.5px",
                            }}
                        >
                            По размерно
                        </Radio.Button>
                    </Radio.Group>
                </Col>
                <Col style={{width: "88px", paddingTop: 0, paddingBottom: 0}}>
                    <Dropdown
                        trigger={["click"]}
                        placement={"bottomRight"}
                        menu={{items: getSizeFilterItems()}}
                        overlayClassName={"dropdown-border"}
                    >
                        <div className={"analytics-header-dropdown "}>
                            <div>Размер</div>
                            <Icon
                                component={SelectIconComponent}
                                style={{marginTop: "2px", fontSize: "10px"}}
                            />
                        </div>
                    </Dropdown>
                </Col>
                <Col style={{width: "98px"}}>
                    <Dropdown
                        trigger={["click"]}
                        placement={"bottomRight"}
                        menu={{items: getProductsFilterItems()}}
                        overlayClassName={"dropdown-border"}
                    >
                        <div className={"analytics-header-dropdown "}>
                            <div>Предмет</div>
                            <Icon
                                component={SelectIconComponent}
                                style={{marginTop: "2px", fontSize: "10px"}}
                            />
                        </div>
                    </Dropdown>
                </Col>
                <Col style={{width: "190px"}}>
                    <Select
                        className="table-header-select table-header-select-no-offset"
                        id="analytics-select"
                        suffixIcon={<img src={selectIcon} alt={""}/>}
                        defaultValue={currentPageSize}
                        onChange={(e) => {
                            setCurrentPage(1);
                            setCurrentPageSize(e);
                        }}
                        options={[
                            {value: "10", label: "Показать по 10 шт."},
                            {value: "50", label: "Показать по 50 шт."},
                            {value: "100", label: "Показать по 100 шт."},
                        ]}
                    />
                </Col>
                <Col>
                    <DownloadExcel 
                        handleDownload={function (): void {
                            throw new Error("Function not implemented.");
                        } }
                        selectedSeller={selectedSeller}   
                        isLoading={false}
                        title='Скачать новый фин. отчет'
                        className=''                  
                        />
                </Col>
                <Col>
                    <DownloadAnaliticsButton
                        searchText={searchText}
                        showMode={showMode}
                        sizes={selectedSizes}
                        products={selectedProducts}
                        sellers={selectedSeller}
                        dateSortStart={dateSortStart}
                        dateSortEnd={dateSortEnd}
                    />
                </Col>
            </Row>

            <Row className={"container"} style={{marginTop: "10px"}}>
                <Col span={24} className={"analytic-rows-container"}>
                    <Row
                        justify={"space-between"}
                        style={{padding: "10px 40px 5px 30px"}}
                    >
                        <Col className={"analytics-discount-wrapper"}>
                            {/*<span className={"analytics-discount-title"}>*/}
                            {/*    СПП, %{" "}*/}
                            {/*</span>*/}
                            {/*<Input*/}
                            {/*    className={"analytics-spp-input"}*/}
                            {/*    type={"number"}*/}
                            {/*    value={spp}*/}
                            {/*    onChange={(e) => setSpp(e.target.value)}*/}
                            {/*    suffix={"%"}*/}
                            {/*/>*/}
                        </Col>
                        <Col className={"analytics-actions-wrapper"}>


                            <RangePicker
                                onChange={onChangeData}
                                placeholder={["Начало", "Конец"]}
                                separator={
                                    <Icon component={rangePickerArrow}/>
                                }
                                format={"DD.MM.YYYY"}
                                value={[dateSortStart, dateSortEnd]}
                            />
                            <Dropdown
                                trigger={["click"]}
                                placement={"bottomRight"}
                                menu={{
                                    items: getColumnFilterItems(),
                                }}
                                overlayStyle={{width: "218px"}}
                                overlayClassName={"dropdown-border"}
                            >
                                <div style={{cursor: "pointer"}}>
                                    <Icon component={columnsIcon}/>
                                    <span>Столбцы</span>
                                </div>
                            </Dropdown>

                            <Dropdown
                                trigger={["click"]}
                                placement={"bottomRight"}
                                menu={{items: getSellersFilterItems()}}
                                overlayClassName={"dropdown-border"}
                            >
                                <div className="analytics-header-dropdown-v2">
                                    <div>Продавец</div>
                                    <Icon
                                        component={SelectIconComponent}
                                        style={{marginTop: "2px", fontSize: "10px"}}
                                    />
                                </div>
                            </Dropdown>

                            {/*<div style={{position: "relative"}}>*/}
                            {/*    <div*/}
                            {/*        style={{cursor: "pointer"}}*/}
                            {/*        onClick={() => {*/}
                            {/*            setFilterMenuOpened(!filterMenuOpened);*/}
                            {/*        }}*/}
                            {/*    >*/}
                            {/*        <Icon component={filterIcon}/>*/}
                            {/*        <span*/}
                            {/*            style={{*/}
                            {/*                color: "#82868B",*/}
                            {/*                marginLeft: "6px",*/}
                            {/*            }}*/}
                            {/*        >*/}
                            {/*    Фильтры*/}
                            {/*</span>*/}
                            {/*    </div>*/}

                            {/*    <Menu*/}
                            {/*        className={*/}
                            {/*            filterMenuOpened*/}
                            {/*                ? "filter-menu filter-menu-opened"*/}
                            {/*                : "filter-menu filter-menu-closed"*/}
                            {/*        }*/}
                            {/*        style={{*/}
                            {/*            color: "red !important",*/}
                            {/*            width: "250px",*/}
                            {/*        }}*/}
                            {/*        items={filterMenuItems}*/}
                            {/*        mode={"inline"}*/}
                            {/*        onClick={(e) => {*/}
                            {/*            console.log(e?.key)*/}
                            {/*            // setOrderBy(e.key)*/}
                            {/*        }}*/}
                            {/*    />*/}
                            {/*</div>*/}
                            {/*old filters*/}

                            <div style={{position: "relative"}}>
                                <div
                                    style={{cursor: "pointer"}}
                                    onClick={() => {
                                        setFilterMenuOpened(!filterMenuOpened);
                                        return;
                                    }}
                                >
                                    <Icon component={filterIcon}/>
                                    <span>Фильтры</span>
                                </div>

                                <Menu
                                    className={
                                        filterMenuOpened
                                            ? "filter-menu filter-menu-opened"
                                            : "filter-menu filter-menu-closed"
                                    }
                                    style={{
                                        color: "red !important",
                                        width: "250px",
                                    }}
                                    items={getFilterMenuItems()}
                                    mode={"inline"}
                                    selectedKeys={[getSelectedFilter()]}
                                />
                            </div>
                        </Col>
                    </Row>
                    {renderAnalyticsTable()}
                </Col>
            </Row>
        </div>
    );
}
