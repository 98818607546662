import React, { useEffect, useState } from 'react'
import {
	Button,
	Checkbox,
	Col,
	DatePicker,
	Dropdown,
	Input,
	message,
	Pagination,
	Row,
	Select,
	Spin,
	Table,
} from 'antd'
import { useLocation, useNavigate } from 'react-router'
import Icon, {
	EditOutlined,
	InfoCircleOutlined,
	LoadingOutlined,
} from '@ant-design/icons'
import useActionsData from './hooks/prices/usePricesData'
import { PickSeller } from './components/PickSeller'
import { PickActionType } from './components/PickActionType'
import CopyToClipboard from 'react-copy-to-clipboard'
import { ReactComponent as SelectIconComponent } from 'assets/images/select-icon.svg'
import selectIcon from 'assets/images/select-icon.svg'
import { PickIsAvailable } from './components/PickIsAvailable'
import ActionsTableCol from './components/ActionsTableCol'
import { PickIsParticipating } from './components/PickIsParticipating'
import { ReactComponent as rangePickerArrow } from '../../assets/images/rangepicker-arrow.svg'
import dayjs from 'dayjs'
import { PickChangeType } from './components/PickChangeType'
import useProductsDataById from 'features/product/hooks/useProductDataById'
import HistoryHover from './components/HistoryHover'
import { PickIsNew } from './components/PickIsNew'
import { PickPageSize } from './components/PickPageSize'
import usePricesData from './hooks/prices/usePricesData'
import NoImage from 'assets/icon/no-image'
import TitleWithTooltip from 'features/actions/components/TitleWithTooltip'
import { DownloadExcel } from './components/DownloadExcel'
import HandleUploadPlannedPrices from './components/HandleUploadPlannedPrices'
import { UploadExcel } from './components/UploadExcel'
const { RangePicker } = DatePicker

const changeTypeColors = [
	{
		id: 1,
		color: 'rgba(0, 203, 68, 0.6)',
	},
	{
		id: 2,
		color: 'rgba(255, 203, 10, 0.6)',
	},
	{
		id: 3,
		color: 'rgba(255, 50, 50, 0.6)',
	},
	{
		id: 4,
		color: 'rgba(25, 73, 230, 0.4)',
	},
	{
		id: 5,
		color: 'rgba(255, 123, 10, 0.6)',
	},
	{
		id: 6,
		color: 'rgba(255, 52, 210, 0.6)',
	},
]

export const PlannedPricesTable = () => {
	const [isEditModalVisible, setIsEditModalVisible] = useState(false)

	const [data, setData] = useState<null | any>(null)
	const location = useLocation()
	const navigate = useNavigate()

	const {
		pricesData,
		promotions,
		setPromotions,
		currentPage,
		setCurrentPage,
		currentPageSize,
		setCurrentPageSize,
		setDataType,
		selectedSeller,

		getSellersFilterItems,
		getProductsFilterItems,
		searchText,
		setSearchText,
		selectedAvailableType,
		setSelectedAvailableType,
		selectedParticipatingType,
		setSelectedParticipatingType,

		onChangeDate,
		dateSortStart,
		dateSortEnd,

		selectedSeenType,
		setSelectedSeenType,
		isLoading,
		isError,
		handleAddPlannedPrices,
		handleDeletePlannedPrices,
	} = usePricesData()

	const handleCancel = () => {
		setIsEditModalVisible(false)
	}

	useEffect(() => {
		setDataType('planned-prices')
		if (pricesData) {
			setData(pricesData)
		}
		console.log(pricesData)
	}, [pricesData])

	const handleGoToNomenclature = (id: number | string) => {
		navigate(`/actions/nomenclature/${id || 0}`)
	}
	const handleGoToAction = (id: number | string) => {
		navigate(`/actions/actions-calendar/${id || 0}`)
	}

	const ProductCell = ({ product }: { product: any }) => {
		if (!product) {
			return <LoadingOutlined />
		}

		return (
			<div
				className='analytics-col-title__wrap'
				style={{ padding: '5px 6px 5px 6px', width: 90 }}
			>
				<CopyToClipboard
					text={`${product?.wbArticle || ''}`}
					onCopy={() => message.success('Текст скопирован')}
				>
					<div className='analytics-col-title' style={{ cursor: 'pointer' }}>
						{product?.wbArticle || 'Нет данных'}
					</div>
				</CopyToClipboard>

				<a href={product?.linkOnSite} target='_blank' rel='noopener noreferrer'>
					{product?.photoUrl ? (
						<img
							src={product?.photoUrl}
							style={{
								width: '90px',
								minHeight: 120,
								maxHeight: 120,
							}}
							alt='Товар'
							onError={e => {
								e.currentTarget.onerror = null
								e.currentTarget.src = ''
								e.currentTarget.style.display = 'none'
								const noImage = e.currentTarget
									.nextElementSibling as HTMLElement
								if (noImage) noImage.style.display = 'block'
							}}
						/>
					) : null}
					<NoImage
						style={{
							width: '90px',
							minHeight: 120,
							maxHeight: 120,
							display: product?.photoUrl ? 'none' : 'block',
						}}
					/>
				</a>
			</div>
		)
	}

	const columns = [
		{
			title: '',
			dataIndex: 'productId',
			key: 'productId',
			width: '5%',
			height: 120,
			align: 'center' as const,
			render: (productId: string, product: any) => (
				<ProductCell product={product} />
			),
		},
		{
			title: <TitleWithTooltip title='Продавец' />,
			dataIndex: 'companyName',
			key: 'companyName',
			width: '7%',
			height: 120,
			align: 'center' as const,
			render: (text: string) => <div style={{ paddingLeft: 16 }}>{text}</div>,
		},
		{
			title: <TitleWithTooltip title='Цена' />,
			dataIndex: 'currentFullPrice',
			key: 'currentFullPrice',
			width: '10%',
			align: 'center' as const,
			render: (price: any, action: any) => (
				<div style={{ paddingLeft: '16px' }}>{`${
					price ? price : 'Нет данных'
				}`}</div>
			),
		},
		{
			title: <TitleWithTooltip title='Цена до СПП' />,
			dataIndex: 'currentPriceBeforeSpp',
			key: 'currentPriceBeforeSpp',
			width: '10%',
			align: 'center' as const,
			render: (price: any, action: any) => (
				<div style={{ paddingLeft: '16px' }}>{`${
					price ? price : 'Нет данных'
				}`}</div>
			),
		},
		{
			title: <TitleWithTooltip title='Скидка' />,
			dataIndex: 'currentDiscount',
			key: 'currentDiscount',
			width: '10%',
			align: 'center' as const,
			render: (discount: any, action: any) => (
				<div style={{ paddingLeft: '16px' }}>{`${
					discount ? discount : 'Нет данных'
				}`}</div>
			),
		},
		{
			title: <TitleWithTooltip title='Плановая цена' />,
			dataIndex: 'plannedPrice',
			key: 'plannedPrice',
			width: '10%',
			align: 'center' as const,
			render: (price: any, action: any) => (
				<div style={{ paddingLeft: '16px' }}>{`${
					price ? price : 'Нет данных'
				}`}</div>
			),
		},
		{
			title: <TitleWithTooltip title='Плановая цена до СПП' />,
			dataIndex: 'plannedPriceBeforeSpp',
			key: 'plannedPriceBeforeSpp',
			width: '10%',
			align: 'center' as const,
			render: (price: any, action: any) => (
				<div style={{ paddingLeft: '16px' }}>{`${
					price ? price : 'Нет данных'
				}`}</div>
			),
		},
		{
			title: <TitleWithTooltip title='Плановая скидка' />,
			dataIndex: 'plannedDiscount',
			key: 'plannedDiscount',
			width: '10%',
			align: 'center' as const,
			render: (discount: any, action: any) => (
				<div style={{ paddingLeft: '16px' }}>{`${
					discount ? discount : 'Нет данных'
				}`}</div>
			),
		},

		{
			title: <TitleWithTooltip title='Время старта по МСК' />,
			dataIndex: 'startDate',
			key: 'startDate',
			width: '10%',
			align: 'center' as const,
			render: (date: any, action: any) => {
				if (!date) return <div style={{ paddingLeft: '16px' }}>Нет данных</div>
				return (
					<div style={{ paddingLeft: '16px' }}>
						{dayjs(date).format('DD.MM.YYYY HH:mm')}
					</div>
				)
			},
		},
		{
			title: <TitleWithTooltip title='Отработал' />,
			dataIndex: 'isOperated',
			key: 'isOperated',
			width: '5%',
			align: 'center' as const,
			render: (participation: boolean) => (
				<div
					style={{
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
					}}
				>
					{participation ? 'Да' : 'Нет'}
				</div>
			),
		},
		{
			title: <TitleWithTooltip title='Действие' />,
			dataIndex: 'inAction',
			key: 'inAction',
			width: '5%',
			align: 'center' as const,
			render: (participation: boolean, record: any) => (
				<Button
					disabled={false}
					style={{
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
					}}
					type='primary'
					onClick={() => handleDeletePlannedPrices({ id: record.id })}
				>
					Удалить
				</Button>
			),
		},
	]

	return (
		<div style={{ backgroundColor: '#f8f8f8' }}>
			<Row gutter={6} align={'middle'} justify={'end'} style={{ gap: 5 }}>
				<Col style={{ flexGrow: '1' }}>
					<Input
						className='table-header-input'
						placeholder={'Поиск'}
						value={searchText}
						onChange={e => setSearchText(e.target.value)}
						type={'number'}
					/>
				</Col>
				<Col style={{ width: '120px' }}>
					<PickSeller
						getSellersFilterItems={getSellersFilterItems}
						selectedSeller={selectedSeller}
					/>
				</Col>
				<Col style={{ width: '180px' }}>
					<PickIsAvailable
						selectedAvailableType={selectedAvailableType}
						setSelectedAvailableType={setSelectedAvailableType}
					/>
				</Col>
				{/* <Col style={{ width: '150px' }}>
					<PickIsParticipating
						selectedParticipatingType={selectedParticipatingType}
						setSelectedParticipatingType={setSelectedParticipatingType}
					/>
				</Col> */}
				{/* <Col style={{ width: '160px' }}>
					<PickIsNew
						selectedSeenType={selectedSeenType}
						setSelectedSeenType={setSelectedSeenType}
					/>
				</Col> */}

				<Col style={{ width: '98px' }}>
					<Dropdown
						trigger={['click']}
						placement={'bottomRight'}
						menu={{ items: getProductsFilterItems() }}
						overlayClassName={'dropdown-border'}
					>
						<div className={'analytics-header-dropdown '}>
							<div>Предмет</div>
							<Icon
								component={SelectIconComponent}
								style={{ marginTop: '2px', fontSize: '10px' }}
							/>
						</div>
					</Dropdown>
				</Col>
				{/* <Col>
					<RangePicker
						onChange={onChangeDate}
						placeholder={['Начало', 'Конец']}
						separator={<Icon component={rangePickerArrow} />}
						format={'DD.MM.YYYY'}
						value={[dateSortStart, dateSortEnd]}
					/>
				</Col> */}
				<Col style={{ width: '190px' }}>
					<PickPageSize
						currentPageSize={currentPageSize}
						setCurrentPage={setCurrentPage}
						setCurrentPageSize={setCurrentPageSize}
					/>
				</Col>
				<Col>
					<HandleUploadPlannedPrices
						title={'Добавить цену'}
						setData={handleAddPlannedPrices}
					/>
				</Col>
				<Col>
					<UploadExcel
						title={'Загрузить планируемые цены'}
						type={'import-planned-prices'}
					/>
				</Col>
			</Row>

			<Row className='container' style={{ marginTop: '10px' }}>
				{isLoading ? (
					<div
						style={{ margin: '0 auto', padding: '150px', textAlign: 'center' }}
					>
						<Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} />} />
					</div>
				) : isError ? (
					<div style={{ textAlign: 'center', padding: '20px', color: 'red' }}>
						Ошибка загрузки данных. Пожалуйста, попробуйте еще раз.
					</div>
				) : (
					<Col span={24}>
						<Table
							columns={columns}
							locale={{
								emptyText: 'Нет данных',
								filterTitle: 'Фильтр',
								filterReset: 'Очистить',
								filterEmptyText: 'Нет фильтров',
							}}
							dataSource={data ? data.data : []}
							pagination={false}
							rowKey={(record: any) => record.id}
							tableLayout='fixed'
							scroll={{
								x: 'max-content',
								y: '65vh',
								// y: '76dvh',
							}}
							size='small'
							style={{ width: '100%' }}
						/>
						<Col span={24}>
							<Pagination
								onChange={page => setCurrentPage(page)}
								current={currentPage}
								defaultCurrent={1}
								pageSize={parseInt(currentPageSize)}
								total={data?.total}
								style={{
									textAlign: 'center',
									padding: '16px 0',
								}}
								showSizeChanger={false}
							/>
						</Col>
					</Col>
				)}
			</Row>
		</div>
	)
}
