import { formatDate } from 'utils/formatDate'
import { api } from '../index'
import { message } from 'antd'

export async function getActionsData(
	sellers?: any[],
	startDate?: string | null,
	endDate?: string | null,
	types?: string[] | null,
	showHidden?: boolean | null
) {
	try {
		const params = new URLSearchParams()

		if (sellers && sellers.length > 0) {
			params.append('sellers', sellers?.map((item: any) => item?.id)?.join(','))
		}

		if (startDate && endDate) {
			params.append('start_date', startDate)
			params.append('end_date', endDate)
		}

		if (types && types.length > 0 && types[0]) {
			console.log(types)
			params.append('type', types?.map((item: any) => item)?.join(','))
		}

		if (showHidden !== null) {
			params.append('hidden', `${showHidden}`)
		}

		const response = await api.get<any>(
			`wb-actions/calendar?${params.toString()}`
		)
		return response.data.data
	} catch (error: any) {
		console.error(`Error fetching calendar data:`, error)
		message.error(`Failed to fetch calendar data. Please try again.`)
	}
}

export async function getNomenclaturesData(
	page?: string | number,
	promotions?: string | any[] | null,
	currentPageSize?: string,
	sellers?: any[],
	search?: string,
	products?: any[],
	available?: boolean | null,
	participating?: boolean | null,
	setInAction?: boolean | null,
	wbStocks?: boolean | null
) {
	try {
		const params = new URLSearchParams()

		if (sellers && sellers.length > 0) {
			params.append('sellers', sellers?.map((item: any) => item?.id)?.join(','))
		}

		if (promotions) {
			const promotionsString = Array.isArray(promotions)
				? promotions.join(',')
				: promotions
			console.log(promotionsString)
			params.append('promotions', promotionsString)
		}

		if (page) {
			params.append('page', `${page}`)
		}

		if (currentPageSize) {
			params.append('limit', currentPageSize)
		}

		if (search) {
			params.append('search', search)
		}

		if (products && products.length > 0) {
			params.append(
				'categories',
				products?.map((item: any) => item?.id)?.join(',')
			)
		}

		if (available !== null) {
			params.append('tracking', `${available}`)
		}

		if (participating !== null) {
			params.append('in_action', `${participating}`)
		}

		if (setInAction !== null) {
			params.append('set_in_action', `${setInAction}`)
		}

		if (wbStocks !== null) {
			params.append('wb_stocks', `${wbStocks}`)
		}

		const queryString = params.toString().replace(/\+/g, '%20')
		const response = await api.get<any>(
			`wb-actions/nomenclatures?${queryString}`
		)
		return response.data
	} catch (error: any) {
		console.error(`Error fetching nomenclatures data:`, error)
		message.error(`Failed to fetch nomenclatures data. Please try again.`)
	}
}

export async function setInAction(id: string | number, setInAction: boolean) {
	const response = await api.patch(
		`/wb-actions/set-in-action?id=${id}&set_in_action=${setInAction}`
	)
	return response.data
}

export async function getActionTypes() {
	const response = await api.get(`/wb-actions/promotion-types`)
	return response.data.data
}

export async function getNomenclatureChangeTypes() {
	const response = await api.get(`/wb-actions/nomenclature-change-types`)
	return response.data.data
}

export async function getPromotionChangeTypes() {
	const response = await api.get(`/wb-actions/promotion-change-types`)
	return response.data.data
}

export async function getNomenclatureChanges(
	page?: string | number,
	promotions?: string | any[] | null,
	currentPageSize?: string,
	sellers?: any[],
	search?: string,
	products?: any[],
	startDate?: string | null,
	endDate?: string | null,
	changeType?: string[] | null,
	available?: boolean | null,
	participating?: boolean | null,
	seen?: boolean | null
) {
	try {
		const params = new URLSearchParams()

		if (sellers && sellers.length > 0) {
			params.append('sellers', sellers?.map((item: any) => item?.id)?.join(','))
		}

		if (promotions) {
			const promotionsString = Array.isArray(promotions)
				? promotions.join(',')
				: promotions
			console.log(promotionsString)
			params.append('promotions', promotionsString)
		}

		if (page) {
			params.append('page', `${page}`)
		}

		if (currentPageSize) {
			params.append('limit', currentPageSize)
		}

		if (search) {
			params.append('search', search)
		}

		if (products && products.length > 0) {
			params.append(
				'categories',
				products?.map((item: any) => item?.id)?.join(',')
			)
		}
		if (changeType && changeType.length > 0) {
			params.append(
				'change_type',
				changeType?.map((item: any) => item)?.join(',')
			)
		}

		if (startDate && endDate) {
			params.append('start_date', startDate)
			params.append('end_date', endDate)
		}
		if (available !== null) {
			params.append('tracking', `${available}`)
		}

		if (participating !== null) {
			params.append('in_action', `${participating}`)
		}

		if (seen !== null) {
			params.append('is_new', `${seen}`)
		}

		const queryString = params.toString().replace(/\+/g, '%20')
		const response = await api.get(
			`/wb-actions/nomenclature-changes${params && `?${queryString}`}`
		)
		return response.data.data
	} catch (error: any) {
		console.error(`Error fetching nomenclatures data:`, error)
		message.error(`Failed to fetch nomenclatures data. Please try again.`)
	}
}

export async function getPromotionChanges(
	page?: string | number,
	promotions?: string | any[] | null,
	currentPageSize?: string,
	sellers?: any[],
	// search?: string,
	// products?: any[],
	startDate?: string | null,
	endDate?: string | null,
	changeType?: string[] | null,
	// available?: boolean | null,
	// participating?: boolean | null,
	seen?: boolean | null,
	types?: string[] | null
) {
	try {
		const params = new URLSearchParams()

		if (sellers && sellers.length > 0) {
			params.append('sellers', sellers?.map((item: any) => item?.id)?.join(','))
		}

		if (promotions) {
			const promotionsString = Array.isArray(promotions)
				? promotions.join(',')
				: promotions
			console.log(promotionsString)
			params.append('promotions', promotionsString)
		}

		if (page) {
			params.append('page', `${page}`)
		}

		if (currentPageSize) {
			params.append('limit', currentPageSize)
		}

		// if (search) {
		// 	params.append('search', search)
		// }

		// if (products && products.length > 0) {
		// 	params.append(
		// 		'categories',
		// 		products?.map((item: any) => item?.id)?.join(',')
		// 	)
		// }
		if (changeType && changeType.length > 0) {
			params.append(
				'change_type',
				changeType?.map((item: any) => item)?.join(',')
			)
		}

		if (startDate && endDate) {
			params.append('start_date', startDate)
			params.append('end_date', endDate)
		}
		// if (available !== null) {
		// 	params.append('tracking', `${available}`)
		// }

		// if (participating !== null) {
		// 	params.append('in_action', `${participating}`)
		// }

		if (seen !== null) {
			params.append('is_new', `${seen}`)
		}

		if (types && types.length > 0 && types[0]) {
			console.log(types)
			params.append('type', types?.map((item: any) => item)?.join(','))
		}

		const queryString = params.toString().replace(/\+/g, '%20')
		const response = await api.get(
			`/wb-actions/promotion-changes${params && `?${queryString}`}`
		)
		return response.data.data
	} catch (error: any) {
		console.error(`Error fetching nomenclatures data:`, error)
		message.error(`Failed to fetch nomenclatures data. Please try again.`)
	}
}

export async function setChangeSeen(id: string | number, seen: boolean) {
	const response = await api.patch(
		`/wb-actions/set-change-seen?id=${id}&seen=${seen}`
	)
	return response.data
}

export async function setPromotionChangeSeen(
	id: string | number,
	seen: boolean
) {
	const response = await api.patch(
		`/wb-actions/set-promotion-change-seen?id=${id}&seen=${seen}`
	)
	return response.data
}

export async function setAllInAction(
	promotions?: string | number | null,
	sellers?: any[],
	setInAction?: boolean
) {
	const params = new URLSearchParams()
	if (sellers && sellers.length > 0) {
		params.append('sellers', sellers?.map((item: any) => item?.id)?.join(','))
	}

	if (promotions) {
		const promotionsString = Array.isArray(promotions)
			? promotions.join(',')
			: promotions
		params.append('promotion_id', `${promotionsString}`)
	}
	if (setInAction !== null) {
		params.append('set_in_action', `${setInAction}`)
	}

	const queryString = params.toString().replace(/\+/g, '%20')
	const response = await api.patch(
		`/wb-actions/set-all-in-action${params && `?${queryString}`}`
	)
	return response.data
}

export async function setAllChangeSeen(sellers?: any[], seen?: boolean) {
	const params = new URLSearchParams()

	if (sellers && sellers.length > 0) {
		params.append('sellers', sellers?.map((item: any) => item?.id)?.join(','))
	}

	if (seen !== null) {
		params.append('seen', `${seen}`)
	}

	const queryString = params.toString().replace(/\+/g, '%20')
	const response = await api.patch(
		`/wb-actions/set-all-change-seen${params && `?${queryString}`}`
	)
	return response.data
}

export async function setAllPromotionChangeSeen(
	sellers?: any[],
	seen?: boolean
) {
	const params = new URLSearchParams()

	if (sellers && sellers.length > 0) {
		params.append('sellers', sellers?.map((item: any) => item?.id)?.join(','))
	}

	if (seen !== null) {
		params.append('seen', `${seen}`)
	}

	const queryString = params.toString().replace(/\+/g, '%20')
	const response = await api.patch(
		`/wb-actions/set-all-promotion-change-seen${params && `?${queryString}`}`
	)
	return response.data
}

export async function setHideAction(id: string | number, hide: boolean) {
	const response = await api.patch(
		`/wb-actions/set-hide-action?id=${id}&hide=${hide}`
	)
	return response.data
}

export async function setParticipating(
	seller_id?: number | string,
	nm_id?: number | string,
	promotion_detailed_id?: number | string
) {
	const params = new URLSearchParams()

	if (seller_id) {
		params.append('seller_id', `${seller_id}`)
	}
	if (nm_id) {
		params.append('nm_id', `${nm_id}`)
	}
	if (promotion_detailed_id) {
		params.append('promotion_detailed_id', `${promotion_detailed_id}`)
	}

	const queryString = params.toString().replace(/\+/g, '%20')
	const response = await api.post(
		`/wb-actions/enable-nomenclature-in-action${params && `?${queryString}`}`
	)
	return response.data
}

export async function setNotParticipating(
	seller_id?: number | string,
	nm_id?: number | string,
	promotion_detailed_id?: number | string
) {
	const params = new URLSearchParams()

	if (seller_id) {
		params.append('seller_id', `${seller_id}`)
	}
	if (nm_id) {
		params.append('nm_id', `${nm_id}`)
	}
	if (promotion_detailed_id) {
		params.append('promotion_detailed_id', `${promotion_detailed_id}`)
	}

	const queryString = params.toString().replace(/\+/g, '%20')
	const response = await api.post(
		`/wb-actions/disable-nomenclature-in-action${params && `?${queryString}`}`
	)
	return response.data
}

export async function setAllParticipating(
	promotion_id?: string | number | null,
	sellers?: any[],
	nm_ids?: any[]
) {
	const params = new URLSearchParams()
	if (sellers && sellers.length > 0) {
		params.append('seller_id', sellers?.map((item: any) => item?.id)?.join(','))
	}

	if (nm_ids && nm_ids.length > 0) {
		params.append('nm_ids', nm_ids?.map((item: any) => item)?.join(','))
	}

	// if (promotions) {
	// 	const promotionsString = Array.isArray(promotions)
	// 		? promotions.join(',')
	// 		: promotions
	// 	params.append('promotion_id', `${promotionsString}`)
	// }

	if (promotion_id) {
		params.append('promotion_id', `${promotion_id}`)
	}

	const queryString = params.toString().replace(/\+/g, '%20')
	const response = await api.post(
		`/wb-actions/enable-all-nomenclatures-in-action${
			params && `?${queryString}`
		}`
	)
	return response.data
}

export async function setAllNotParticipating(
	promotions?: string | number | null,
	sellers?: any[],
	nm_ids?: any[]
) {
	const params = new URLSearchParams()
	if (sellers && sellers.length > 0) {
		params.append('seller_id', sellers?.map((item: any) => item?.id)?.join(','))
	}

	if (promotions) {
		const promotionsString = Array.isArray(promotions)
			? promotions.join(',')
			: promotions
		params.append('promotion_id', `${promotionsString}`)
	}

	if (nm_ids && nm_ids.length > 0) {
		params.append('nm_ids', nm_ids?.map((item: any) => item)?.join(','))
	}

	const queryString = params.toString().replace(/\+/g, '%20')
	const response = await api.post(
		`/wb-actions/disable-all-nomenclatures-in-action${
			params && `?${queryString}`
		}`
	)
	return response.data
}

export async function addByControlPrice(
	promotions?: string | number | null,
	sellers?: any[],
	nm_ids?: any[]
) {
	const params = new URLSearchParams()
	if (sellers && sellers.length > 0) {
		params.append('seller_id', sellers?.map((item: any) => item?.id)?.join(','))
	}

	if (promotions) {
		const promotionsString = Array.isArray(promotions)
			? promotions.join(',')
			: promotions
		params.append('promotion_id', `${promotionsString}`)
	}

	if (nm_ids && nm_ids.length > 0) {
		params.append('nm_ids', nm_ids?.map((item: any) => item)?.join(','))
	}

	const queryString = params.toString().replace(/\+/g, '%20')
	const response = await api.post(
		`/wb-actions/enable-by-control-price${params && `?${queryString}`}`
	)
	return response.data
}

export async function downloadNomenclaturesData(
	promotion?: string | number | null,
	sellers?: any[],
	search?: string,
	products?: any[],
	available?: boolean | null,
	participating?: boolean | null,
	setInAction?: boolean | null
) {
	try {
		const params = new URLSearchParams()

		if (sellers && sellers?.length > 0) {
			params.append('sellers', sellers.map((item: any) => item?.id).join(','))
		}

		if (promotion) {
			params.append('promotions', `${promotion}`)
		}

		if (search) {
			params.append('search', search)
		}

		if (products && products?.length > 0) {
			params.append(
				'categories',
				products.map((item: any) => item?.id).join(',')
			)
		}

		if (available !== null) {
			params.append('tracking', `${available}`)
		}

		if (participating !== null) {
			params.append('in_action', `${participating}`)
		}

		if (setInAction !== null) {
			params.append('set_in_action', `${setInAction}`)
		}

		const queryString = params.toString().replace(/\+/g, '%20')
		const response = await api.get<any>(
			`wb-actions/download-excell?${queryString}`,
			{
				responseType: 'blob',
			}
		)
		return response.data
	} catch (error: any) {
		console.error(`Error downloading nomenclatures excel:`, error)
		message.error(`Критическая ошибка выгрузки. Попробуйте позже`)
		throw error
	}
}

export async function downloadAllOldPrices(
	promotion?: string | number | null
	// sellers?: any[],
	// search?: string,
	// products?: any[],
	// available?: boolean | null,
	// participating?: boolean | null,
	// setInAction?: boolean | null
) {
	try {
		const params = new URLSearchParams()

		// if (sellers && sellers?.length > 0) {
		// 	params.append('sellers', sellers.map((item: any) => item?.id).join(','))
		// }

		if (promotion) {
			params.append('promotion_id', `${promotion}`)
		}

		// if (search) {
		// 	params.append('search', search)
		// }

		// if (products && products?.length > 0) {
		// 	params.append(
		// 		'categories',
		// 		products.map((item: any) => item?.id).join(',')
		// 	)
		// }

		// if (available !== null) {
		// 	params.append('tracking', `${available}`)
		// }

		// if (participating !== null) {
		// 	params.append('in_action', `${participating}`)
		// }

		// if (setInAction !== null) {
		// 	params.append('set_in_action', `${setInAction}`)
		// }

		const queryString = params.toString().replace(/\+/g, '%20')
		const response = await api.get<any>(
			`wb-actions/download-prices-before-action?${queryString}`,
			{
				responseType: 'blob',
			}
		)
		return response.data
	} catch (error: any) {
		console.error(`Error downloading nomenclatures excel:`, error)
		message.error(`Критическая ошибка выгрузки. Попробуйте позже`)
		throw error
	}
}

export async function addFile(
	type: 'enable' | 'disable',
	seller_id: string,
	promotion_id: string,
	file: File
) {
	try {
		const params = new URLSearchParams()

		if (seller_id) {
			params.append('seller_id', seller_id)
		}

		if (promotion_id) {
			params.append('promotion_id', promotion_id)
		}

		const formData = new FormData()
		formData.append('file', file)

		const queryString = params.toString()

		const response = await api.post<any>(
			`wb-actions/upload-excell-${type}?${queryString}`,
			formData
		)

		return response.data
	} catch (error: any) {
		console.error(`Error uploading nomenclatures excel:`, error)
		message.error('Критическая ошибка отправки файла. Попробуйте позже.')
		throw error
	}
}
