import {
	Button,
	Checkbox,
	Col,
	DatePicker,
	Dropdown,
	Form,
	Input,
	MenuProps,
	message,
	Modal,
	Pagination,
	Row,
	Select,
	Spin,
	Switch,
	Table,
	Tooltip,
} from 'antd'
import ModalHeader from 'features/shared/ModalHeader'
import React, { useEffect, useRef, useState } from 'react'
import { useAddFile } from '../hooks/prices/useAddFile'
import { ReactComponent as SelectIconComponent } from '../../../assets/images/select-icon.svg'
import Icon, { LoadingOutlined } from '@ant-design/icons'

const { Option } = Select

export default function AddFileModal({
	setData,
	onClose,
	title = 'Добавить файл',
	// modalData,
	// selectedSeller,
	isOpen,
	type,
}: {
	setData?: any
	onClose: () => void
	title: string
	// modalData: any
	// selectedSeller: any
	isOpen: boolean
	type: 'import-prices' | 'import-min-prices' | 'import-planned-prices'
}) {
	const [form] = Form.useForm()

	// const [selectedSeller, setSelectedSeller] = useState<any>();
	const [selectedType, setSelectedType] = useState<any>()
	const [selectedFile, setSelectedFile] = useState<any>()

	// const {sellers} = useSellerList();

	const { handleAddFile, errorMessage, isLoading, isSuccess, isError } =
		useAddFile()

	const onFinish = async (values: any) => {
		if (!selectedFile) {
			return message.error('Пожалуйста выберите файл')
		}

		const data = await handleAddFile({ file: selectedFile, type: type })
		setData && setData(data)
		if (data) {
			onClose()
		}
	}

	const fileInputRef = useRef<any>(null)

	const handleFileSelect = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click()
		}
	}

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const selectedFile = event.target.files && event.target.files[0]

		setSelectedFile(selectedFile)
	}

	return (
		<Modal
			open={isOpen}
			closable={false}
			footer={null}
			width={'100%'}
			style={{
				maxWidth: 600,
				minWidth: 360,
				padding: '0 10px',
			}}
		>
			<div
				className={'modal-wrapper'}
				style={{ padding: '30px', width: '90%' }}
			>
				<ModalHeader
					title={title}
					onClose={() => {
						onClose()
						form.resetFields()
						setSelectedFile('')
						fileInputRef.current.value = ''
					}}
				/>
				<Row
					className={'container'}
					style={{
						display: 'flex',
						flexDirection: 'column',
						width: '100%',
					}}
				>
					<Form
						form={form}
						layout={'vertical'}
						onFinish={onFinish}
						style={{
							width: '100%',
						}}
					>
						{selectedFile?.name && <div>{selectedFile?.name}</div>}
						<input
							accept='.pdf,.doc,.docx,.xls,.xlsx,.fiff'
							type='file'
							ref={fileInputRef}
							style={{ display: 'none' }}
							onChange={handleInputChange}
						/>
						<Button
							type='dashed'
							style={{
								width: '100%',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								marginBottom: 10,
							}}
							onClick={handleFileSelect}
						>
							Добавить файл
						</Button>
						<Row gutter={16} style={{ justifyContent: 'flex-end' }}>
							<Col span={12}>
								<Button
									loading={isLoading}
									className={'button'}
									type={'primary'}
									style={{ width: '100%' }}
									htmlType={'submit'}
								>
									Добавить
								</Button>
							</Col>
						</Row>
					</Form>
				</Row>
			</div>
		</Modal>
	)
}
