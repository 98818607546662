import { useState } from 'react'
import { Input, Button, Modal, Col, message } from 'antd'
import {
	CloseCircleOutlined,
	EditOutlined,
	SaveOutlined,
} from '@ant-design/icons'
import ModalHeader from 'features/shared/ModalHeader'
import TitleWithTooltip from 'features/actions/components/TitleWithTooltip'

const MinMax = ({
	min = 0,
	max = 0,
	onChange,
	title = '',
}: {
	min: number
	max: number
	onChange: (value: { min: number; max: number }) => void
	title?: string
}) => {
	const [isEditing, setIsEditing] = useState(false)
	const [editedValue, setEditedValue] = useState({ min: min, max: max })

	const handleEdit = () => {
		setIsEditing(true)
	}

	const validateValues = () => {
		if (editedValue.min < 0 || editedValue.max < 0) {
			message.error('Значения не могут быть отрицательными')
			return false
		}
		if (editedValue.min >= editedValue.max) {
			message.error('Минимальное значение должно быть меньше максимального')
			return false
		}
		return true
	}

	const handleSave = () => {
		if (!validateValues()) {
			return
		}

		setIsEditing(false)
		if (onChange) {
			onChange({ min: editedValue.min, max: editedValue.max })
		}
	}

	const handleCancel = () => {
		setIsEditing(false)
		setEditedValue({ min: min, max: max })
	}

	const onClose = () => {
		setIsEditing(false)
	}

	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				gap: 8,
			}}
		>
			{min != null && max != null && (
				<>
					{isEditing && (
						<Modal open={isEditing} footer={null} onCancel={onClose}>
							<div className='modal-wrapper' style={{ padding: '30px' }}>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'flex-end',
										justifyContent: 'center',
										gap: 16,
										marginTop: 16,
									}}
								>
									<div style={{ width: '100%' }}>
										<label
											style={{
												fontWeight: 'bold',
												display: 'block',
												marginBottom: 4,
											}}
										>
											Мин
										</label>
										<Input
											type='number'
											value={editedValue.min}
											onChange={e =>
												setEditedValue({
													min: Number(e.target.value),
													max: editedValue.max,
												})
											}
										/>
									</div>
									<div style={{ width: '100%' }}>
										<label
											style={{
												fontWeight: 'bold',
												display: 'block',
												marginBottom: 4,
											}}
										>
											Макс
										</label>
										<Input
											type='number'
											value={editedValue.max}
											onChange={e =>
												setEditedValue({
													min: editedValue.min,
													max: Number(e.target.value),
												})
											}
										/>
									</div>
									<div style={{ display: 'flex', gap: 4 }}>
										<Button type='primary' onClick={handleSave}>
											<SaveOutlined /> Сохранить
										</Button>
										<Button type='text' onClick={handleCancel}>
											<CloseCircleOutlined /> Отменить
										</Button>
									</div>
								</div>
							</div>
						</Modal>
					)}
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							gap: 8,
						}}
					>
						<TitleWithTooltip
							title={`${min.toString()}-${max.toString()} ${title}`}
							rows={1}
						/>
						<EditOutlined onClick={handleEdit} style={{ cursor: 'pointer' }} />
					</div>
				</>
			)}
		</div>
	)
}

export default MinMax
