import React, {useEffect, useState} from 'react';
import {
  Button,
  Col,
  DatePicker,
  Dropdown,
  Input,
  Menu,
  Modal, Pagination,
  Radio,
  Row,
  Select, Spin,
  Table,
  TableColumnsType
} from "antd";
import useShipmentsData from "./hooks/useShipmentsData";
import selectIcon, {ReactComponent as SelectIconComponent} from "../../assets/images/select-icon.svg";
import Icon from "@ant-design/icons";
import {ReactComponent as editIcon} from "../../assets/images/edit-icon.svg";
import ArticleModalContent from "../analytics/modal/ArticleModalContent";
import ShipmentsModalContent from "./components/ShipmentsModalContent";
import {DownloadShipmentsButton} from "./components/DownloadShipmentsButton";

const ShipmentsTable = () => {

  const {
    shipmentsData,
    isLoading,
    currentPage,
    currentPageSize,
    searchText,
    setCurrentPage,
    setCurrentPageSize,
    setSearchText,
    selectedSizes,
    selectedProducts,
    selectedSeller,
    selectedWarehouse,
    selectedPeriod,
    getProductsFilterItems,
    getSellersFilterItems,
    getWarehouseFilterItems,
    setSelectedPeriod,
    withDetails,
    setWithDetails,
  } = useShipmentsData()

  const sliceText = (text: any) => {
    if (!text) return 'Нет данных'
    if (text?.length > 10) {
      return text.slice(0, 10) + '...'
    } else {
      return text
    }
  }

  const [articleModal, setArticleModal] = useState<any>({
    productId: '',
    analyticsUserId: '',
    articule: '',
    isUpdate: false,
    isOpen: false
  });

  const [shipmentModal, setShipmentModal] = useState<any>({
    productId: '',
    count: 0,
    isOpen: false
  });

  const columns2: TableColumnsType<any> = [
    {
      title: '',
      dataIndex: 'title',
      key: 'title',
      width: '18%'
    },
    {
      title: 'Остаток товара, шт.',
      dataIndex: 'left',
      key: 'left',
      width: '20%'
    },
    {
      title: 'Доля продаж, %',
      dataIndex: 'dolya',
      key: 'dolya',
      width: '20%'
    },
    {
      title: 'Скорость заказов со склада за выбранный диапазон, шт\\день',
      dataIndex: 'order_speed',
      key: 'order_speed',
      width: '20%'
    },
    {
      title: 'Необходимо отгрузить, шт',
      dataIndex: 'needs',
      key: 'needs',
      width: '20%'
    },
  ];

  const columns3: TableColumnsType<any> = [
    {
      title: '',
      dataIndex: 'main',
      key: 'main',
      width: '20%'
    },
    {
      title: 'остаток товара',
      dataIndex: 'left',
      key: 'left',
      width: '20%'
    },
    {
      title: 'доля продаж',
      dataIndex: 'dolya',
      key: 'dolya',
      width: '20%'
    },
    {
      title: 'скорость заказов со склада, шт в день за последние 5 дней',
      dataIndex: 'order_speed',
      key: 'order_speed',
      width: '20%'
    },
    {
      title: 'Необходиом отгрузить',
      dataIndex: 'needs',
      key: 'needs',
      width: '20%'
    },
  ];

  const [shipmentsDataToShow, setShipmentsDataToShow] = useState<any>([])

  useEffect(() => {
    setShipmentsDataToShow(shipmentsData?.data?.map((item: any) => ({...item, isOpen: false})))
  }, [isLoading, shipmentsData])

  const onOpenRow = (id: any) => {
    let map = shipmentsDataToShow.map((item: any) => item?.productId === id ? ({
      ...item,
      isOpen: !item?.isOpen
    }) : item);
    setShipmentsDataToShow(map)
  }



  return (
    <div className="shipments-page">
      <Row gutter={6} align={"middle"}>
        <Col style={{flexGrow: "1"}}>
          <Input
            className="table-header-input"
            placeholder={"Поиск"}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </Col>
        <Col style={{width: "88px", paddingTop: 0, paddingBottom: 0}}>
          <Dropdown
            trigger={["click"]}
            placement={"bottomRight"}
            menu={{items: getWarehouseFilterItems()}}
            overlayClassName={"dropdown-border"}
          >
            <div className={"analytics-header-dropdown "}>
              <div>Склады</div>
              <Icon
                component={SelectIconComponent}
                style={{marginTop: "2px", fontSize: "10px"}}
              />
            </div>
          </Dropdown>
        </Col>
        <Col style={{width: "98px"}}>
          <Dropdown
            trigger={["click"]}
            placement={"bottomRight"}
            menu={{items: getProductsFilterItems()}}
            overlayClassName={"dropdown-border"}
          >
            <div className={"analytics-header-dropdown "}>
              <div>Предмет</div>
              <Icon
                component={SelectIconComponent}
                style={{marginTop: "2px", fontSize: "10px"}}
              />
            </div>
          </Dropdown>
        </Col>
        <Col>
          <Select
            className="table-header-select table-header-select-no-offset"
            id="analytics-select"
            suffixIcon={<img src={selectIcon} alt={""}/>}
            defaultValue={selectedPeriod}
            onChange={(e) => setSelectedPeriod(e)}
            options={[
              {value: "3", label: "Период: 3 дня"},
              {value: "5", label: "Период: 5 дней"},
              {value: "7", label: "Период: 7 дней"},
            ]}
          />
        </Col>
        <Col style={{width: "190px"}}>
          <Select
            className="table-header-select table-header-select-no-offset"
            id="analytics-select"
            suffixIcon={<img src={selectIcon} alt={""}/>}
            defaultValue={currentPageSize}
            onChange={(e) => {
              setCurrentPage(1);
              setCurrentPageSize(e);
            }}
            options={[
              {value: "10", label: "Показать по 10 шт."},
              {value: "50", label: "Показать по 50 шт."},
              {value: "100", label: "Показать по 100 шт."},
            ]}
          />
        </Col>
        <Col style={{width: "230px"}}>
          <Select
            className="table-header-select table-header-select-no-offset"
            id="analytics-select"
            suffixIcon={<img src={selectIcon} alt={""}/>}
            // defaultValue={currentPageSize}
            onChange={(e) => {
              setWithDetails(e)
            }}
            defaultValue={'all'}
            options={[
              {value: "all", label: "Все"},
              {value: "true", label: "С детализацией по складам"},
              {value: "false", label: "Без детализацией по складам"},
            ]}
          />
        </Col>
        <Col>
          <DownloadShipmentsButton
            page={currentPage}
            pageSize={currentPageSize}
            searchText={searchText}
            sizes={selectedSizes}
            products={selectedProducts}
            selectedSeller={selectedSeller}
            selectedWarehouse={selectedWarehouse}
            selectedPeriod={selectedPeriod}
          />
        </Col>
      </Row>
      <Row className={"container"} style={{marginTop: "10px"}}>
        <Col span={24}>
          <Row
            justify={"space-between"}
            style={{padding: "10px 40px 10px 30px"}}
          >
            <Col className={"analytics-actions-wrapper"}>

            </Col>
            <Col>
              <Dropdown
                trigger={["click"]}
                placement={"bottomRight"}
                menu={{items: getSellersFilterItems()}}
                overlayClassName={"dropdown-border"}
              >
                <div className="analytics-header-dropdown-v2">
                  <div>Продавец</div>
                  <Icon
                    component={SelectIconComponent}
                    style={{marginTop: "2px", fontSize: "10px"}}
                  />
                </div>
              </Dropdown>
            </Col>
          </Row>

          <Spin spinning={isLoading}>
            <div className="shipments-table">
              {
                shipmentsDataToShow?.map((item: any) => {
                  return (
                    <>
                      <div className="shipments-table-header">
                        <p>Фото</p>
                        <p>Продавец</p>
                        <p>Предмет \ категория</p>
                        <p>Артикул</p>
                        <p>Размеры</p>
                        <p>Остаток ВБ, шт</p>
                        <p>Собственные остатки, шт</p>
                        <p>Скорость заказа на артикул</p>
                        <p>Товар в статусе приемки</p>
                        <p>В пути к клиенту</p>
                        <p>В пути от клиента</p>
                        <p>Продано всего</p>
                        <p>Комментарий</p>
                        <p>Планируемый запас, дней</p>
                      </div>
                      <div className="shipments-table-row">
                        <div className="shipments-table-row-left">
                          <button onClick={() => onOpenRow(item?.productId)}>
                            +
                          </button>
                          <img src={item?.photo} alt=""/>
                        </div>
                        <div className="shipments-table-row-main">
                          <div className="shipments-table-row-main-data">
                            <p>
                              {item?.seller?.companyName}
                            </p>
                            <p>
                              {item?.subjectName}
                            </p>
                            <p>
                              {item?.articule}
                            </p>
                            <p style={{wordBreak:'break-word'}}>
                              {item?.sizes?.map((itemSize: any) => itemSize?.product_size)?.join(', ')}
                            </p>
                            <p>
                              {item?.wbStock}
                            </p>
                            <p>
                              {item?.selfStock}
                            </p>
                            <p>
                              {item?.orderSpeed}
                            </p>
                            <p>
                              {item?.prodStatus}
                            </p>
                            <p>
                              {item?.sizes && item?.sizes?.reduce((acc:any, current:any) => acc + (current.delivering_to_client || 0), 0)}
                            </p>
                            <p>
                              {item?.sizes && item?.sizes?.reduce((acc:any, current:any) => acc + (current.delivering_from_client || 0), 0)}
                            </p>
                            <p>
                              {item?.sizes && item?.sizes?.reduce((acc:any, current:any) => acc + (current.sizes_sales_count || 0), 0)}
                            </p>

                            <p>
                              <div>
                                {sliceText(item?.lastNote?.noteText?.slice(0, 10)?.concat('...'))} {""}
                                <Icon
                                  component={editIcon}
                                  style={{cursor: "pointer"}}
                                  onClick={() => {
                                    setArticleModal({
                                      ...articleModal,
                                      analyticsUserId: item?.analyticsUserId,
                                      articule: item?.articule,
                                      productId: item?.productId,
                                      isUpdate: true,
                                      isOpen: true
                                    })
                                  }}
                                />
                                {`(` + item?.countNotes + `)`}
                              </div>
                            </p>
                            <p>
                              <div>
                                {`(` + item.need + `)`}
                                <Icon
                                  component={editIcon}
                                  style={{cursor: "pointer"}}
                                  onClick={() => {
                                    setShipmentModal({
                                      productId: item?.productId,
                                      count: item?.need,
                                      isOpen: true
                                    })
                                  }}
                                />
                              </div>
                            </p>
                          </div>
                          <div className="shipments-table-row-main-sizes">
                            {
                              item?.sizes?.map((itemSizes: any) => {
                                return (
                                  <div className="shipments-table-row-main-sizes-row">
                                    <p>
                                    </p>
                                    <p>
                                    </p>
                                    <p>
                                    </p>
                                    <p>
                                      {itemSizes?.product_size}
                                    </p>
                                    <p>
                                      {itemSizes?.wb_stock}
                                    </p>
                                    <p>
                                      {itemSizes?.self_stock}
                                    </p>
                                    <p>
                                      {itemSizes?.order_speed}
                                    </p>
                                    <p>
                                      {itemSizes?.prod_status}
                                    </p>
                                    <p>
                                      {itemSizes?.delivering_to_client}
                                    </p>
                                    <p>
                                      {itemSizes?.delivering_from_client}
                                    </p>
                                    <p>
                                      {itemSizes?.sizes_sales_count}
                                    </p>
                                    <p></p>
                                    <p></p>
                                  </div>
                                )
                              })
                            }
                          </div>
                        </div>
                      </div>
                      {
                        item?.isOpen &&
                          <Table
                              expandable={{
                                expandedRowRender: (record2) => {
                                  return (
                                    <div>
                                      <Table
                                        showHeader={false}
                                        columns={columns3}
                                        style={{
                                          backgroundColor: '#effa7a',
                                          borderRadius: 0
                                        }}
                                        dataSource={record2?.data}
                                        pagination={false}
                                      />
                                    </div>
                                  )
                                }
                              }}
                              columns={columns2}
                              style={{
                                padding: 10,
                                paddingLeft: 50,
                                backgroundColor: '#effa7a'
                              }}
                              dataSource={item?.wbs}
                              pagination={false}
                          />
                      }
                    </>
                  )
                })
              }
            </div>

            <Pagination
              onChange={(page, pageSize): any => setCurrentPage(page)}
              defaultCurrent={1}
              current={currentPage}
              pageSize={parseInt(currentPageSize)}
              total={Number(shipmentsData?.total)}
              style={{
                textAlign: "center",
                padding: "16px 0",
              }}
              showSizeChanger={false}
            />
          </Spin>


          {/*<Table*/}
          {/*  loading={isLoading}*/}
          {/*  columns={columns}*/}
          {/*  expandable={{*/}
          {/*    expandedRowRender: (record) => {*/}
          {/*      return (*/}
          {/*        <div>*/}
          {/*          <Table*/}
          {/*            columns={columnsSize}*/}
          {/*            style={{*/}
          {/*              padding: '0 0 10px 0',*/}
          {/*              backgroundColor: '#effa7a'*/}
          {/*            }}*/}
          {/*            dataSource={record?.sizes}*/}
          {/*            pagination={false}*/}
          {/*            showHeader={false}*/}
          {/*          />*/}
          {/*          <Table*/}
          {/*            expandable={{*/}
          {/*              expandedRowRender: (record2) => {*/}

          {/*                return (*/}
          {/*                  <div>*/}
          {/*                    <Table*/}
          {/*                      showHeader={false}*/}
          {/*                      columns={columns3}*/}
          {/*                      style={{*/}
          {/*                        backgroundColor: '#effa7a',*/}
          {/*                        borderRadius: 0*/}
          {/*                      }}*/}
          {/*                      dataSource={record2?.data}*/}
          {/*                      pagination={false}*/}
          {/*                    />*/}
          {/*                  </div>*/}
          {/*                )*/}
          {/*              }*/}
          {/*            }}*/}
          {/*            columns={columns2}*/}
          {/*            style={{*/}
          {/*              padding: 10,*/}
          {/*              paddingLeft: 50,*/}
          {/*              backgroundColor: '#effa7a'*/}
          {/*            }}*/}
          {/*            dataSource={record?.wbs}*/}
          {/*            pagination={false}*/}
          {/*          />*/}
          {/*        </div>*/}
          {/*      )*/}
          {/*    }*/}
          {/*  }}*/}
          {/*  dataSource={shipmentsData?.data?.map((item: any) => ({ ...item, key: item?.productId }))}*/}
          {/*  className="cashback-table"*/}
          {/*  scroll={{ x: true }}*/}
          {/*  pagination={{*/}
          {/*    onChange: (page, pageSize): any => setCurrentPage(page),*/}
          {/*    position: ["bottomCenter"],*/}
          {/*    pageSize: Number(currentPageSize),*/}
          {/*    total: Number(shipmentsData?.total),*/}
          {/*    showSizeChanger: false,*/}
          {/*    current: currentPage,*/}
          {/*  }}*/}
          {/*/>*/}
        </Col>
      </Row>

      <Modal
        open={articleModal.isOpen}
        closable={false}
        footer={null}
        width={770}
      >
        <ArticleModalContent
          productId={articleModal.productId}
          articleModal={articleModal}
          onClose={() => setArticleModal({...articleModal, isOpen: false})}
          finishUpdate={() => setArticleModal({...articleModal, isUpdate: false})}
        />
      </Modal>

      {shipmentModal.isOpen &&
          <Modal
              open={shipmentModal.isOpen}
              closable={false}
              footer={null}
              width={770}
          >
              <ShipmentsModalContent
                  productId={shipmentModal.productId}
                  count={shipmentModal.count}
                  onClose={() => setShipmentModal({...shipmentModal, isOpen: false})}
              />
          </Modal>
      }

    </div>
  );
};

export default ShipmentsTable;
