import React, { useEffect, useMemo, useState } from 'react'
import {
	Button,
	Col,
	DatePicker,
	Dropdown,
	Input,
	message,
	Pagination,
	Row,
	Select,
	Spin,
	Table,
} from 'antd'
import Icon, { DownloadOutlined, LoadingOutlined } from '@ant-design/icons'
import selectIcon, {
	ReactComponent as SelectIconComponent,
} from '../../assets/images/select-icon.svg'
import { PickSeller } from './components/PickSeller'
import usePricesData from './hooks/prices/usePricesData'
import CopyToClipboard from 'react-copy-to-clipboard'
import { PickPageSize } from './components/PickPageSize'
import { PickIsNew } from './components/PickIsNew'
import { useTableColumns } from './hooks/prices/useTableColumns'
import { PickVisibleColumns } from './components/PickVisibleColumns'
import TitleWithTooltip from 'features/actions/components/TitleWithTooltip'
import NoImage from 'assets/icon/no-image'
import BeforeAfter from './BeforeAfter'
import { DownloadExcel } from './components/DownloadExcel'
import { UploadExcel } from './components/UploadExcel'
import { PickIsAvailable } from './components/PickIsAvailable'

const CurrentPrices = () => {
	const {
		pricesData,
		selectedSeller,
		setSelectedSeller,
		sellers,
		setDataType,
		isLoading,
		onChangeDate,
		dateSortStart,
		dateSortEnd,
		getSellersFilterItems,
		selectedActionType,
		setSelectedActionType,
		searchText,
		setSearchText,
		selectedAvailableType,
		setSelectedAvailableType,
		currentPageSize,
		setCurrentPageSize,
		currentPage,
		setCurrentPage,
		getProductsFilterItems,
		setMinPrice,
		handleDownloadAllPrices,
		downloadLoading,
	} = usePricesData()

	const [data, setData] = useState(pricesData)
	console.log(data)
	useEffect(() => {
		setDataType('prices')
		if (pricesData) {
			setData(pricesData)
		}
	}, [pricesData])

	const columns = useMemo(
		() => [
			{
				title: 'Товар',
				dataIndex: 'photoUrl',
				key: 'photoUrl',
				width: '5%',
				align: 'center' as const,
				render: (photo: string, record: any) => {
					const { wbArticle, linkOnSite } = record || {}
					return (
						<div
							className='analytics-col-title__wrap'
							style={{ padding: '5px 6px 5px 6px' }}
						>
							<CopyToClipboard
								text={`${wbArticle || ''}`}
								onCopy={() => message.success('Текст скопирован')}
							>
								<div
									className='analytics-col-title'
									style={{ cursor: 'pointer' }}
								>
									{wbArticle || 'Нет данных'}
								</div>
							</CopyToClipboard>
							<a href={linkOnSite} target='_blank' rel='noopener noreferrer'>
								{photo ? (
									<img
										src={photo}
										style={{
											width: '90px',
											minHeight: 120,
											maxHeight: 120,
										}}
										alt='Товар'
										onError={e => {
											e.currentTarget.onerror = null
											e.currentTarget.src = ''
											e.currentTarget.style.display = 'none'
											const noImage = e.currentTarget
												.nextElementSibling as HTMLElement
											if (noImage) noImage.style.display = 'block'
										}}
									/>
								) : null}
								<NoImage
									style={{
										width: '90px',
										minHeight: 120,
										maxHeight: 120,
										display: photo ? 'none' : 'block',
									}}
								/>
							</a>
							{/* {category && <div className='analytics-col-title'>{category}</div>} */}
						</div>
					)
				},
			},
			{
				title: <TitleWithTooltip title='Отслеживание' />,
				dataIndex: 'isAvailable',
				key: 'isAvailable',
				width: '5%',
				align: 'center' as const,
				render: (isAvailable: any) => (
					<div
						style={{
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							background: isAvailable
								? 'rgba(0, 203, 68, 0.6)'
								: 'rgba(255, 203, 10, 0.6)',
						}}
					>
						{isAvailable ? 'Да' : 'Нет'}
					</div>
				),
			},
			{
				title: <TitleWithTooltip title='Продавец' />,
				dataIndex: 'companyName',
				key: 'companyName',
				width: 150,
				align: 'center' as const,
				render: (text: any) => <div style={{ padding: '0 8px' }}>{text}</div>,
			},
			{
				title: <TitleWithTooltip title='Категория' />,
				dataIndex: 'category',
				key: 'category',
				width: 150,
				align: 'center' as const,
				render: (text: any) => <div style={{ padding: '0 8px' }}>{text}</div>,
			},
			{
				title: <TitleWithTooltip title='Текущая цена (полная)' />,
				dataIndex: 'currentPrice',
				key: 'currentPrice',
				width: 50,
				align: 'center' as const,
				render: (text: any) => <div style={{ padding: '0 8px' }}>{text}</div>,
			},
			{
				title: <TitleWithTooltip title='Цена до СПП' />,
				dataIndex: 'currentPriceBeforeSpp',
				key: 'currentPriceBeforeSpp',
				width: 50,
				align: 'center' as const,
				render: (text: number) => (
					<div style={{ padding: '0 8px' }}>{text ? text.toFixed(2) : 0}</div>
				),
			},
			{
				title: <TitleWithTooltip title='Нормальная цена' />,
				dataIndex: 'normalPrice',
				key: 'normalPrice',
				width: 50,
				align: 'center' as const,
				render: (text: any) => <div style={{ padding: '0 8px' }}>{text}</div>,
			},
			{
				title: <TitleWithTooltip title='Минимальная цена' />,
				dataIndex: 'minPrice',
				key: 'minPrice',
				width: 50,
				align: 'center' as const,
				render: (text: any, record: any) => (
					<div style={{ padding: '0 8px' }}>
						<BeforeAfter
							after={text}
							onAfterChange={price =>
								setMinPrice({
									id: record.productId,
									minPrice: price,
									controlPrice: record.controlPrice,
								})
							}
						/>
					</div>
				),
			},
			{
				title: <TitleWithTooltip title='Контрольная цена' />,
				dataIndex: 'controlPrice',
				key: 'controlPrice',
				width: 50,
				align: 'center' as const,
				render: (text: any, record: any) => (
					<div style={{ padding: '0 8px' }}>
						<BeforeAfter
							after={text}
							onAfterChange={price =>
								setMinPrice({
									id: record.productId,
									controlPrice: price,
									minPrice: record.minPrice,
								})
							}
						/>
					</div>
				),
			},
			{
				title: <TitleWithTooltip title='Минимальная цена WB' />,
				dataIndex: 'minWbPrice',
				key: 'minWbPrice',
				width: 50,
				align: 'center' as const,
				render: (text: any) => <div style={{ padding: '0 8px' }}>{text}</div>,
			},
			{
				title: <TitleWithTooltip title='Максимальная цена WB' />,
				dataIndex: 'maxWbPrice',
				key: 'maxWbPrice',
				width: 50,
				align: 'center' as const,
				render: (text: any) => <div style={{ padding: '0 8px' }}>{text}</div>,
			},
			{
				title: <TitleWithTooltip title='Медианная цена' />,
				dataIndex: 'medianPrice',
				key: 'medianPrice',
				width: 50,
				align: 'center' as const,
				render: (text: any) => <div style={{ padding: '0 8px' }}>{text}</div>,
			},
			{
				title: <TitleWithTooltip title='Минимальная скидка' />,
				dataIndex: 'minDiscount',
				key: 'minDiscount',
				width: 50,
				align: 'center' as const,
				render: (text: any) => <div style={{ padding: '0 8px' }}>{text}</div>,
			},
			{
				title: <TitleWithTooltip title='Максимальная скидка' />,
				dataIndex: 'maxDiscount',
				key: 'maxDiscount',
				width: 50,
				align: 'center' as const,
				render: (text: any) => <div style={{ padding: '0 8px' }}>{text}</div>,
			},
			{
				title: <TitleWithTooltip title='Медианная скидка' />,
				dataIndex: 'medianDiscount',
				key: 'medianDiscount',
				width: 50,
				align: 'center' as const,
				render: (text: any) => <div style={{ padding: '0 8px' }}>{text}</div>,
			},
		],
		[data]
	)

	const { visibleColumns, toggleColumn } = useTableColumns(columns)

	const tableColumns = visibleColumns.filter((col: any) => col.visible)

	return (
		<div style={{ backgroundColor: '#f8f8f8', marginTop: 10 }}>
			<Row gutter={6} align={'middle'} justify={'end'} style={{ gap: 5 }}>
				<Col style={{ flexGrow: '1' }}>
					<Input
						className='table-header-input'
						placeholder={'Поиск'}
						value={searchText}
						onChange={e => setSearchText(e.target.value)}
						type={'number'}
					/>
				</Col>
				<Col style={{ width: '120px' }}>
					<PickSeller
						getSellersFilterItems={getSellersFilterItems}
						selectedSeller={selectedSeller}
					/>
				</Col>
				<Col style={{ width: '160px' }}>
					<PickIsAvailable
						selectedAvailableType={selectedAvailableType}
						setSelectedAvailableType={setSelectedAvailableType}
					/>
				</Col>
				<Col style={{ width: '98px' }}>
					<Dropdown
						trigger={['click']}
						placement={'bottomRight'}
						menu={{ items: getProductsFilterItems() }}
						overlayClassName={'dropdown-border'}
					>
						<div className={'analytics-header-dropdown '}>
							<div>Предмет</div>
							<Icon
								component={SelectIconComponent}
								style={{ marginTop: '2px', fontSize: '10px' }}
							/>
						</div>
					</Dropdown>
				</Col>
				<Col>
					<PickVisibleColumns
						columns={visibleColumns}
						onToggle={toggleColumn}
					/>
				</Col>
				<Col style={{ width: '190px' }}>
					<PickPageSize
						currentPageSize={currentPageSize}
						setCurrentPage={setCurrentPage}
						setCurrentPageSize={setCurrentPageSize}
					/>
				</Col>{' '}
				<Col>
					<UploadExcel
						title={'Загрузить мин./контр. цены'}
						type={'import-min-prices'}
					/>
				</Col>
				<Col>
					<Button
						href='https://it-korobka.ru/api/storage/prices/new_min_price_sample.xlsx'
						type={'primary'}
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<DownloadOutlined />
						Скачать
					</Button>
				</Col>{' '}
				<Col>
					<DownloadExcel
						handleDownload={handleDownloadAllPrices}
						isLoading={downloadLoading}
						title={'Скачать все цены'}
					/>
				</Col>
			</Row>

			<Row className={'container'} style={{ marginTop: '10px' }}>
				{isLoading ? (
					<div
						style={{
							margin: '0 auto',
							padding: '150px',
							textAlign: 'center',
						}}
					>
						<Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} />} />
					</div>
				) : data?.length === 0 ? (
					<div style={{ margin: '0 auto', padding: 150 }}>Нет данных</div>
				) : (
					<Col span={24}>
						<Table
							columns={tableColumns}
							locale={{
								emptyText: 'Нет данных',
								filterTitle: 'Фильтр',
								filterReset: 'Очистить',
								filterEmptyText: 'Нет фильтров',
							}}
							dataSource={data ? data.data : []}
							pagination={false}
							rowKey={(record: any) => record.id}
							scroll={{ x: 'max-content', y: '65vh' }}
						/>
						<Col span={24}>
							<Pagination
								onChange={page => setCurrentPage(page)}
								current={currentPage}
								defaultCurrent={1}
								pageSize={parseInt(currentPageSize)}
								total={data?.total}
								style={{
									textAlign: 'center',
									padding: '16px 0',
								}}
								showSizeChanger={false}
							/>
						</Col>
					</Col>
				)}
			</Row>
		</div>
	)
}

export default CurrentPrices
