import { useState } from 'react'
import { Input, Button, Modal } from 'antd'
import {
	CloseCircleOutlined,
	EditOutlined,
	SaveOutlined,
} from '@ant-design/icons'
import ModalHeader from 'features/shared/ModalHeader'

const BeforeAfter = ({
	before,
	after,
	onAfterChange,
}: {
	before?: number
	after?: number
	onAfterChange?: (newAfter: number) => void
}) => {
	const [isEditing, setIsEditing] = useState(false)
	const [editedAfter, setEditedAfter] = useState(after)

	const handleEdit = () => {
		setIsEditing(true)
	}

	const handleSave = () => {
		setIsEditing(false)
		if (onAfterChange && editedAfter !== undefined) {
			onAfterChange(editedAfter)
		}
	}

	const handleCancel = () => {
		setIsEditing(false)
		setEditedAfter(after)
	}

	const onClose = () => {
		setIsEditing(false)
	}

	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				gap: 8,
			}}
		>
			{before && <span>{before.toFixed(2)}</span>}
			{before && after && <>➡️</>}
			{after != null && (
				<>
					{onAfterChange && isEditing && (
						<Modal open={isEditing} footer={null} onCancel={onClose}>
							<div className={'modal-wrapper'} style={{ padding: '30px' }}>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'flex-end',
										justifyContent: 'center',
										gap: 16,
										marginTop: 16,
									}}
								>
									<Input
										type='number'
										value={editedAfter}
										onChange={e => setEditedAfter(Number(e.target.value))}
									/>
									<div style={{ display: 'flex', gap: 4 }}>
										<Button type='primary' onClick={handleSave}>
											<SaveOutlined /> Сохранить
										</Button>
										<Button type='text' onClick={handleCancel}>
											<CloseCircleOutlined /> Отменить
										</Button>
									</div>
								</div>
							</div>
						</Modal>
					)}
					<div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
						<span>{after.toFixed(2)}</span>
						{onAfterChange && (
							<EditOutlined
								onClick={handleEdit}
								style={{ cursor: 'pointer' }}
							/>
						)}
					</div>
				</>
			)}
		</div>
	)
}

export default BeforeAfter
