import {
	Button,
	Col,
	Form,
	Input,
	Modal,
	Row,
	DatePicker,
	message,
	Switch,
} from 'antd'
import ModalHeader from 'features/shared/ModalHeader'
import React, { useState } from 'react'

export default function UploadPlannedPrice({
	setData,
	onClose,
	title = 'Добавить данные',
	isOpen,
}: {
	setData?: any
	onClose: () => void
	title: string
	isOpen: boolean
}) {
	const [form] = Form.useForm()
	const [priceBeforeSPPToggle, setPriceBeforeSPPToggle] = useState(false)

	const onFinish = async (values: any) => {
		const {
			article,
			plannedPrice,
			plannedDiscount,
			plannedPriceBeforeSPP,
			date,
		} = values

		if (!article || !date) {
			return message.error('Заполните все обязательные поля')
		}

		if (priceBeforeSPPToggle && !plannedPriceBeforeSPP) {
			return message.error('Введите "Цену до СПП"')
		}

		if (!priceBeforeSPPToggle && (!plannedPrice || plannedDiscount == null)) {
			return message.error('Введите "Планируемую цену" и "Скидку"')
		}

		const absoluteDiscount = !priceBeforeSPPToggle
			? (plannedPrice * (plannedDiscount / 100)).toFixed(2)
			: (plannedPriceBeforeSPP * 0.35).toFixed(2)

		const data = {
			article: article,
			planned_price: priceBeforeSPPToggle
				? plannedPriceBeforeSPP / 0.65
				: plannedPrice,
			planned_discount: priceBeforeSPPToggle ? 35 : plannedDiscount,
			start_date: date.format('DD.MM.YYYY'),
		}

		setData && setData(data)
		onClose()
		form.resetFields()
		setPriceBeforeSPPToggle(false)
	}

	return (
		<Modal
			open={isOpen}
			closable={false}
			footer={null}
			width={'100%'}
			style={{
				maxWidth: 600,
				minWidth: 360,
				padding: '0 10px',
			}}
		>
			<div className='modal-wrapper' style={{ padding: '30px', width: '90%' }}>
				<ModalHeader
					title={title}
					onClose={() => {
						onClose()
						form.resetFields()
						setPriceBeforeSPPToggle(false)
					}}
				/>
				<Row
					className='container'
					style={{
						display: 'flex',
						flexDirection: 'column',
						width: '100%',
					}}
				>
					<Form
						form={form}
						layout='vertical'
						onFinish={onFinish}
						style={{
							width: '100%',
						}}
					>
						<Form.Item
							label='Артикул'
							name='article'
							rules={[{ required: true, message: 'Введите артикул' }]}
						>
							<Input placeholder='Введите артикул' />
						</Form.Item>

						<Row justify='space-between' align='middle'>
							<Col>
								<label style={{ fontWeight: 'bold' }}>Цена до СПП</label>
							</Col>
							<Col>
								<Switch
									checked={priceBeforeSPPToggle}
									onChange={checked => {
										setPriceBeforeSPPToggle(checked)
										form.resetFields([
											'plannedPrice',
											'plannedDiscount',
											'plannedPriceBeforeSPP',
										])
									}}
								/>
							</Col>
						</Row>

						<Form.Item
							label='Цена до СПП'
							name='plannedPriceBeforeSPP'
							rules={[
								{
									required: priceBeforeSPPToggle,
									message: 'Введите цену до СПП',
								},
							]}
						>
							<Input
								placeholder='Введите цену до СПП'
								type='number'
								disabled={!priceBeforeSPPToggle}
								onChange={e => {
									if (priceBeforeSPPToggle) {
										const value = Number(e.target.value)
										form.setFieldsValue({
											plannedPrice: (value / 0.65).toFixed(2),
											plannedDiscount: 35,
										})
									}
								}}
							/>
						</Form.Item>

						<Form.Item
							label='Планируемая цена'
							name='plannedPrice'
							rules={[
								{
									required: !priceBeforeSPPToggle,
									message: 'Введите планируемую цену',
								},
							]}
						>
							<Input
								placeholder='Введите планируемую цену'
								type='number'
								disabled={priceBeforeSPPToggle}
								onChange={e => {
									if (!priceBeforeSPPToggle) {
										const value = Number(e.target.value)
										const discount = form.getFieldValue('plannedDiscount') || 0
										form.setFieldsValue({
											plannedPriceBeforeSPP: (
												value -
												(value * discount) / 100
											).toFixed(2),
										})
									}
								}}
							/>
						</Form.Item>

						<Form.Item
							label='Плановая скидка (%)'
							name='plannedDiscount'
							rules={[
								{
									required: !priceBeforeSPPToggle,
									message: 'Введите плановую скидку',
								},
							]}
						>
							<Input
								placeholder='Введите плановую скидку в процентах'
								type='number'
								disabled={priceBeforeSPPToggle}
								onChange={e => {
									if (!priceBeforeSPPToggle) {
										const discount = Number(e.target.value)
										const price = form.getFieldValue('plannedPrice') || 0
										form.setFieldsValue({
											plannedPriceBeforeSPP: (
												price -
												(price * discount) / 100
											).toFixed(2),
										})
									}
								}}
							/>
						</Form.Item>

						<Form.Item
							label='Дата'
							name='date'
							rules={[{ required: true, message: 'Выберите дату' }]}
						>
							<DatePicker
								style={{ width: '100%' }}
								placeholder='Выберите дату'
							/>
						</Form.Item>

						<Row gutter={16} style={{ justifyContent: 'flex-end' }}>
							<Col span={12}>
								<Button
									className='button'
									type='primary'
									style={{ width: '100%' }}
									htmlType='submit'
								>
									Добавить
								</Button>
							</Col>
						</Row>
					</Form>
				</Row>
			</div>
		</Modal>
	)
}
