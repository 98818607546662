import { message } from 'antd'
import { useMutation, useQueryClient } from 'react-query'
import { useCallback, useState } from 'react'
import { backend } from 'api'

export const useAddFile = () => {
	const queryClient = useQueryClient()
	const [errorMessage, setErrorMessage] = useState<string>('')

	const { mutateAsync: handleAdd, ...options } = useMutation(
		({

			file,
		}: {

			file: File
		}) => backend.advertisingMirror.importCometa( file),
		{
			onSuccess: async (data: any) => {
				if (data?.error !== false) {
					// await queryClient.invalidateQueries([`COST_PRICE_DATA`])
					message.success('Файл успешно отправлен')
				} else {
					message.warning(
						`Файл отправлен, но произошла ошибка со стороны сервера: ${
							data?.message || 'Неизвестная ошибка'
						}`
					)
				}
			},
			onError: (error: any) => {
				const errorDetail =
					error?.response?.data?.errorText ||
					'Критическая ошибка отправки файла.'
				setErrorMessage(errorDetail)
				message.error(errorDetail)
			},
		}
	)

	const handleAddFile = useCallback(
		async (
			file: File
		) => {
			try {
				await handleAdd({file })
			} catch (error: any) {
				const errorData =
					error?.response?.data?.detail || 'Не удалось отправить файл.'
				setErrorMessage(errorData)
			}
		},
		[handleAdd]
	)

	return {
		handleAddFile,
		errorMessage,
		...options,
	}
}
