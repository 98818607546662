import React, { useEffect, useMemo, useState } from 'react'
import {
	Button,
	Checkbox,
	Col,
	Dropdown,
	Input,
	message,
	Pagination,
	Row,
	Select,
	Spin,
	Table,
	Tooltip,
} from 'antd'
import { useLocation, useNavigate } from 'react-router'
import Icon, {
	EditOutlined,
	InfoCircleOutlined,
	LoadingOutlined,
} from '@ant-design/icons'
import useActionsData from './hooks/actions/useActionsData'
import { PickSeller } from './components/PickSeller'
import { PickActionType } from './components/PickActionType'
import CopyToClipboard from 'react-copy-to-clipboard'
import { ReactComponent as SelectIconComponent } from 'assets/images/select-icon.svg'
import selectIcon from 'assets/images/select-icon.svg'
import { PickIsAvailable } from './components/PickIsAvailable'
import ActionsTableCol from './components/ActionsTableCol'
import { PickIsParticipating } from './components/PickIsParticipating'
import { PickPageSize } from './components/PickPageSize'
import { UpdateDate } from './components/UpdateDate'
import HistoryHover from './components/HistoryHover'
import { ConfirmationModal } from './modals/ConfirmationModal'
import { PickIsSetInAction } from './components/PickIsSetInAction'
import { PickVisibleColumns } from './components/PickVisibleColumns'
import TitleWithTooltip from './components/TitleWithTooltip'
import PickSelectAll from './components/PickSelectAll'
import { DownloadExcel } from './components/DownloadExcel'
import { useTableColumns } from './hooks/actions/useTableColumns'
import { PickWBStocks } from './components/PickWBStocks'
import { UploadExcel } from './components/UploadExcel'
import ActionWithActions from './components/ActionWithActions'
import NoImage from 'assets/icon/no-image'

const { Option } = Select

const ActionItemsTable = () => {
	const [isEditModalVisible, setIsEditModalVisible] = useState(false)

	const [data, setData] = useState<null | any>(null)
	const location = useLocation()
	const navigate = useNavigate()

	const lastSegment = location.pathname.split('/').pop()
	const decodedSegment = decodeURIComponent(lastSegment || '')

	const {
		nomenclatures,
		setPromotions,
		currentPage,
		setCurrentPage,
		currentPageSize,
		setCurrentPageSize,
		selectedSeller,
		setSelectedSeller,
		sellers,
		nomenclaturesLoading,
		nomenclaturesError,
		getSellersFilterItems,
		getProductsFilterItems,
		searchText,
		setSearchText,
		selectedAvailableType,
		setSelectedAvailableType,
		selectedParticipatingType,
		setSelectedParticipatingType,
		setAvailable,
		setLoadingCheckboxId,
		loadingCheckboxId,
		setAllInAction,
		isSetInAction,
		setIsSetInAction,
		setParticipating,
		setNotParticipating,
		setAllParticipating,
		setAllNotParticipating,
		downloadNomenclatureExcel,
		downloadAllOldPricesExcel,
		wbStocks,
		setWbStocks,
		actionsData,
		setAddByControlPrice,
	} = useActionsData()

	const handleCancel = () => {
		setIsEditModalVisible(false)
	}

	useEffect(() => {
		if (lastSegment) {
			setPromotions([decodedSegment])
			// setPromotions('44%2C%2064')
			setData(nomenclatures)
		}
	}, [currentPage, lastSegment, nomenclatures])

	const handleClick = (id: number | string) => {
		navigate(`/actions/nomenclature/${id || 0}`)
	}

	const [selectedNomenclature, setSelectedNomenclature] = useState<string[]>([])

	const handleCheckboxChange = (wbArticle: string, checked: boolean) => {
		console.log(selectedNomenclature, wbArticle)
		setSelectedNomenclature(prevState => {
			if (checked) {
				return [...prevState, wbArticle]
			} else {
				return prevState.filter(article => article !== wbArticle)
			}
		})
	}

	const selectAllNomenclatures = () => {
		setSelectedNomenclature(prev =>
			data ? data?.data.data.map((item: any) => item.wbArticle) : []
		)
	}

	const deselectAllNomenclatures = () => {
		setSelectedNomenclature([])
	}

	const [confirmUnsetInActionVisible, setConfirmUnsetInActionVisible] =
		useState(false)
	const [confirmSetInActionVisible, setConfirmSetInActionVisible] =
		useState(false)

	const [confirmActionData, setConfirmActionData] = useState<{
		seller_id: string | number
		nm_id: string | number
		promotion_detailed_id: string | number
	}>({
		seller_id: '',
		nm_id: '',
		promotion_detailed_id: '',
	})

	const handleSetUnavailable = (action: any) => {
		setConfirmUnsetInActionVisible(true)
		console.log(action)
		setConfirmActionData({
			seller_id: action.sellerId,
			nm_id: action.wbArticle,
			promotion_detailed_id: action.wbCalendarPromotionDetailedId,
		})
	}
	const handleSetAvailable = (action: any) => {
		setConfirmSetInActionVisible(true)
		console.log(action)
		setConfirmActionData({
			seller_id: action.sellerId,
			nm_id: action.wbArticle,
			promotion_detailed_id: action.wbCalendarPromotionDetailedId,
		})
	}

	const confirmSetInAction = () => {
		console.log(confirmActionData)
		setParticipating({
			seller_id: confirmActionData.seller_id,
			nm_id: confirmActionData.nm_id,
			promotion_detailed_id: confirmActionData.promotion_detailed_id,
		})
		setConfirmActionData({
			seller_id: '',
			nm_id: '',
			promotion_detailed_id: '',
		})
	}

	const confirmUnsetInAction = () => {
		setNotParticipating({
			seller_id: confirmActionData.seller_id,
			nm_id: confirmActionData.nm_id,
			promotion_detailed_id: confirmActionData.promotion_detailed_id,
		})
		setConfirmActionData({
			seller_id: '',
			nm_id: '',
			promotion_detailed_id: '',
		})
	}

	const [isLoading, setIsLoading] = useState(false)
	const [oldPricesLoading, setOldPricesLoading] = useState(false)

	const handleDownload = async () => {
		message.warning('Скачивание началось. Пожалуйста не закрывайте страницу.')
		setIsLoading(true)
		try {
			await downloadNomenclatureExcel({ promotion: decodedSegment })
		} finally {
			setIsLoading(false)
		}
	}
	const handleDownloadOldPrices = async () => {
		message.warning('Скачивание началось. Пожалуйста не закрывайте страницу.')
		setOldPricesLoading(true)
		try {
			await downloadAllOldPricesExcel({ promotion: decodedSegment })
		} finally {
			setOldPricesLoading(false)
		}
	}
	const handleUpload = async () => {
		// message.warning('Скачивание началось. Пожалуйста не закрывайте страницу.')
		// setIsLoading(true)
		// try {
		// 	await downloadNomenclatureExcel({ promotion: decodedSegment })
		// } finally {
		// 	setIsLoading(false)
		// }
	}

	const columns = useMemo(
		() => [
			{
				title: '',
				width: '2%',
				dataIndex: 'wbArticle',
				key: 'wbArticle',
				align: 'center' as const,
				render: (wbArticle: string) => {
					return (
						<Checkbox
							checked={selectedNomenclature.includes(wbArticle)}
							onChange={e => handleCheckboxChange(wbArticle, e.target.checked)}
						/>
					)
				},
			},
			{
				title: <TitleWithTooltip title={'Товар'} />,
				dataIndex: 'photoUrl',
				key: 'photoUrl',
				width: '5%',
				align: 'center' as const,
				render: (photo: string, record: any) => {
					const { wbArticle, linkOnSite } = record || {}
					return (
						<div
							className='analytics-col-title__wrap'
							style={{ padding: '5px 6px 5px 6px' }}
						>
							<CopyToClipboard
								text={`${wbArticle || ''}`}
								onCopy={() => message.success('Текст скопирован')}
							>
								<div
									className='analytics-col-title'
									style={{ cursor: 'pointer' }}
								>
									{wbArticle || 'Нет данных'}
								</div>
							</CopyToClipboard>
							<a href={linkOnSite} target='_blank' rel='noopener noreferrer'>
								{photo ? (
									<img
										src={photo}
										style={{
											width: '90px',
											minHeight: 120,
											maxHeight: 120,
										}}
										alt='Товар'
										onError={e => {
											e.currentTarget.onerror = null
											e.currentTarget.src = ''
											e.currentTarget.style.display = 'none'
											const noImage = e.currentTarget
												.nextElementSibling as HTMLElement
											if (noImage) noImage.style.display = 'block'
										}}
									/>
								) : null}
								<NoImage
									style={{
										width: '90px',
										minHeight: 120,
										maxHeight: 120,
										display: photo ? 'none' : 'block',
									}}
								/>
							</a>
							{/* {category && <div className='analytics-col-title'>{category}</div>} */}
						</div>
					)
				},
			},
			{
				title: <TitleWithTooltip title='Отслеживание' />,
				dataIndex: 'isAvailable',
				key: 'isAvailable',
				width: '3%',
				align: 'center' as const,
				overflow: 'hidden',
				display: '-webkit-box',
				textOverflow: 'ellipsis',
				WebkitBoxOrient: 'vertical',
				WebkitLineClamp: 3,
				render: (isAvailable: any) => (
					<div
						style={{
							minWidth: 80,
							maxWidth: 80,
							width: '100%',
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							background: isAvailable
								? 'rgba(0, 203, 68, 0.6)'
								: 'rgba(255, 203, 10, 0.6)',
						}}
					>
						{isAvailable ? 'Да' : 'Нет'}
					</div>
				),
			},
			{
				title: <TitleWithTooltip title='Участие' />,
				dataIndex: 'inAction',
				key: 'inAction',
				width: '3%',
				align: 'center' as const,
				render: (participation: any) => (
					<div
						style={{
							minWidth: 80,
							maxWidth: 80,
							width: '100%',
							padding: '0 8px',
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							background: participation
								? 'rgba(0, 203, 68, 0.6)'
								: 'rgba(255, 203, 10, 0.6)',
						}}
					>
						{participation ? 'Да' : 'Нет'}
					</div>
				),
			},
			{
				title: 'Предмет',
				dataIndex: 'category',
				key: 'category',
				width: '5%',
				align: 'center' as const,
				render: (text: any, action: any) => (
					<div
						onClick={() => handleClick(action.id)}
						className='item-title'
						style={{
							padding: '0 8px',
							fontSize: '0.88rem',
							fontWeight: 400,
							cursor: 'pointer',
						}}
					>
						{text}
					</div>
				),
			},
			{
				title: 'Продавец',
				dataIndex: 'seller',
				key: 'seller',
				width: 100,
				align: 'center' as const,
				render: (text: any) => <div style={{ padding: '0 8px' }}>{text}</div>,
			},

			{
				title: <TitleWithTooltip title='Текущая розничная цена' />,
				dataIndex: 'currentSalePrice',
				key: 'currentSalePrice',
				width: 50,
				align: 'center' as const,
				render: (price: any, action: any) => (
					<div style={{ padding: '0 8px' }}>{price}</div>
				),
			},
			{
				title: <TitleWithTooltip title='Текущая скидка %' />,
				dataIndex: 'currentDiscount',
				key: 'currentDiscount',
				width: 50,
				align: 'center' as const,
				render: (discount: any, action: any) => (
					<div style={{ padding: '0 8px' }}>{`${discount}%`}</div>
				),
			},
			{
				title: <TitleWithTooltip title='Текущая до СПП сейчас' />,
				dataIndex: 'currentPriceBeforeSpp',
				key: 'currentPriceBeforeSpp',
				width: '5%',
				align: 'center' as const,
				render: (price: any, action: any) => {
					return {
						props: {
							style: {
								background: 'rgba(0, 203, 68, 0.6)',
							},
						},
						children: (
							<div style={{ padding: '0 8px', width: '100%' }}>{price}</div>
						),
					}
				},
			},
			{
				title: (
					<TitleWithTooltip title='Загружаемая скидка для участия в акции' />
				),
				dataIndex: 'planDiscount',
				key: 'planDiscount',
				width: 50,
				align: 'center' as const,
				render: (discount: any, action: any) => (
					<div style={{ padding: '0 8px' }}>{`${discount}%`}</div>
				),
			},
			{
				title: <TitleWithTooltip title='Цена до СПП для участия в акции' />,
				dataIndex: 'expectedFinalPrice',
				key: 'expectedFinalPrice',
				width: '5%',
				align: 'center' as const,
				render: (price: any, action: any) => {
					return {
						props: {
							style: {
								background: 'rgba(0, 203, 68, 0.6)',
							},
						},
						children: (
							<div style={{ padding: '0 8px', width: '100%' }}>{price}</div>
						),
					}
				},
			},
			{
				title: <TitleWithTooltip title='Разница' />,
				dataIndex: 'priceDifference',
				key: 'priceDifference',
				width: '5%',
				align: 'center' as const,
				render: (difference: any, action: any) => {
					return {
						props: {
							style: {
								background: 'rgba(255, 203, 10, 0.6)',
							},
						},
						children: (
							<div style={{ padding: '0 8px', width: '100%' }}>
								{difference.toFixed(2)}₽
							</div>
						),
					}
				},
			},
			{
				title: <TitleWithTooltip title='Цена до акции' />,
				dataIndex: 'priceBeforeAction',
				key: 'priceBeforeAction',
				width: 50,
				align: 'center' as const,
				render: (value: any, action: any) => (
					<div style={{ padding: '0 8px' }}>{value.toFixed(2)}</div>
				),
			},
			{
				title: 'Остаток WB',
				dataIndex: 'wbTotalStorageCount',
				key: 'wbTotalStorageCount',
				width: 50,
				align: 'center' as const,
				render: (text: any, action: any) => (
					<div style={{ padding: '0 8px' }}>
						<ActionsTableCol
							text={text}
							width={'100%'}
							isShowHover={true}
							hoverData={action.wbStocksBySizes}
						/>
					</div>
				),
			},
			{
				title: <TitleWithTooltip title='На складе продавца' />,
				dataIndex: 'ownTotalStorageCount',
				key: 'ownTotalStorageCount',
				width: 50,
				align: 'center' as const,
				render: (text: any, action: any) => (
					<div style={{ padding: '0 8px' }}>
						<ActionsTableCol
							text={text}
							width={'100%'}
							isShowHover={true}
							hoverData={action.ownStocksBySizes}
						/>
					</div>
				),
			},
			{
				title: <TitleWithTooltip title='Средняя скорость заказа за 3 дня' />,
				dataIndex: 'avgOrdersPerDay',
				key: 'avgOrdersPerDay',
				width: 50,
				align: 'center' as const,
				render: (value: any, action: any) => (
					<div style={{ padding: '0 8px' }}>{value}</div>
				),
			},
			{
				title: 'Изменения',
				dataIndex: 'history',
				key: 'history',
				width: 50,
				align: 'center' as const,
				render: (history: any, action: any) => {
					if (!history || history.length === 0) {
						return <div>Нет данных</div>
					}

					const [latest, previous] = history

					return (
						<div style={{ padding: '0 8px' }}>
							<HistoryHover
								title=' '
								width='100%'
								icon={<InfoCircleOutlined size={40} />}
								isShowHover={true}
								hoverData={history}
								type='nomenclatures'
							/>
							{history.length > 1 && (
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										gap: 7,
										width: '100%',
									}}
								>
									<UpdateDate updatedAt={latest?.updatedAt} />
									{previous?.planDiscount !== latest?.planDiscount && (
										<span>
											<span style={{ fontWeight: 600 }}>Скидка:</span>{' '}
											{previous?.planDiscount}% → {latest?.planDiscount}%
										</span>
									)}
									{previous?.inAction !== latest?.inAction && (
										<div>
											<span>{previous?.inAction ? 'У' : 'Не у'}частвует</span>→
											<span>
												{latest?.inAction ? '' : 'Не'} участвует в акции,
											</span>
										</div>
									)}
								</div>
							)}
						</div>
					)
				},
			},
			// {
			// 	title: <TitleWithTooltip title='Ставим в акцию' />,
			// 	dataIndex: 'setInAction',
			// 	key: 'setInAction',
			// 	width: 50,
			// 	align: 'center' as const,
			// 	render: (setIn: any, action: any) => (
			// 		<Checkbox
			// 			checked={setIn}
			// 			disabled={loadingCheckboxId === action.id}
			// 			onChange={() => {
			// 				setLoadingCheckboxId(action.id)
			// 				setAvailable({
			// 					id: action.id,
			// 					setInAction: !setIn,
			// 				})
			// 			}}
			// 		>
			// 			{loadingCheckboxId === action.id && <Spin size='small' />}
			// 		</Checkbox>
			// 	),
			// },
			{
				title: <TitleWithTooltip title='Участие в акции' />,
				dataIndex: 'participatingInAction',
				key: 'participatingInAction',
				width: '8%',
				align: 'center' as const,
				render: (setIn: any, action: any) => (
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							gap: '5px',
							maxWidth: 150,
							minWidth: 150,
						}}
					>
						<Button type='primary' onClick={() => handleSetAvailable(action)}>
							Участвовать
						</Button>
						<Button type='primary' onClick={() => handleSetUnavailable(action)}>
							Не участвовать
						</Button>
					</div>
				),
			},
		],
		[nomenclatures, selectedNomenclature.length, loadingCheckboxId]
	)

	const { visibleColumns, toggleColumn } = useTableColumns(columns)

	const tableColumns = visibleColumns.filter((col: any) => col.visible)

	const action = useMemo(() => {
		return (
			actionsData?.find(
				(action: { id: string | number; name: string }) =>
					String(action.id) === String(decodedSegment)
			) || null
		)
	}, [actionsData, decodedSegment])

	console.log(action, actionsData, decodedSegment)
	const itemsCount = useMemo(() => {
		return {
			total: data?.data.total,
			inAction: data?.data.in_action,
			notInAction: data?.data.not_in_action,
		}
	}, [data?.total])

	return (
		<>
			<div style={{ backgroundColor: '#f8f8f8' }}>
				<Row
					style={{
						marginBottom: 5,
						display: 'flex',
						flexDirection: 'column',
						gap: 5,
						// alignItems: 'center',
						// justifyContent: 'space-between',
					}}
				>
					<h1 style={{ margin: 0 }}>{action?.name}</h1>
					<div
						style={{
							display: 'flex',
							gap: 10,
						}}
					>
						<span
							style={{
								margin: 0,
								borderRight: '1px solid #000',
								paddingRight: 10,
							}}
						>
							Количество отображаемых участников:{' '}
							<strong>{itemsCount.total}</strong>
						</span>
						<span
							style={{
								margin: 0,
								borderRight: '1px solid #000',
								paddingRight: 10,
							}}
						>
							Количество участников в акции:{' '}
							<strong>{itemsCount.inAction}</strong>
						</span>
						<span style={{ margin: 0 }}>
							Количество участников не в акции:{' '}
							<strong>{itemsCount.notInAction}</strong>
						</span>
					</div>
				</Row>
				<Row gutter={6} align={'middle'} justify={'start'} style={{ gap: 5 }}>
					<Col
						style={{
							minWidth: 80,
							maxWidth: 80,
						}}
					>
						<PickSelectAll
							selectAll={selectAllNomenclatures}
							deselectAll={deselectAllNomenclatures}
							selectedCount={selectedNomenclature.length}
							checked={selectedNomenclature.length === data?.data.data.length}
						/>
					</Col>
					<Col style={{ flexGrow: '1', minWidth: 100, maxWidth: 250 }}>
						<Input
							className='table-header-input'
							placeholder={'Поиск'}
							value={searchText}
							onChange={e => setSearchText(e.target.value)}
							type={'number'}
						/>
					</Col>

					<Col style={{ width: '120px' }}>
						<PickSeller
							getSellersFilterItems={getSellersFilterItems}
							selectedSeller={selectedSeller}
						/>
					</Col>
					<Col style={{ width: '175px' }}>
						<PickIsAvailable
							selectedAvailableType={selectedAvailableType}
							setSelectedAvailableType={setSelectedAvailableType}
						/>
					</Col>
					<Col style={{ width: '140px' }}>
						<PickIsParticipating
							selectedParticipatingType={selectedParticipatingType}
							setSelectedParticipatingType={setSelectedParticipatingType}
						/>
					</Col>
					<Col style={{ width: '140px' }}>
						<PickIsSetInAction
							isSetInAction={isSetInAction}
							setIsSetInAction={setIsSetInAction}
						/>
					</Col>
					<Col style={{ width: '150px' }}>
						<PickWBStocks
							selectedStocksType={wbStocks}
							setSelectedStocksType={setWbStocks}
						/>
					</Col>
					<Col style={{ width: '98px' }}>
						<Dropdown
							trigger={['click']}
							placement={'bottomRight'}
							menu={{ items: getProductsFilterItems() }}
							overlayClassName={'dropdown-border'}
						>
							<div className={'analytics-header-dropdown '}>
								<div>Предмет</div>
								<Icon
									component={SelectIconComponent}
									style={{ marginTop: '2px', fontSize: '10px' }}
								/>
							</div>
						</Dropdown>
					</Col>
					<Col>
						<PickVisibleColumns
							columns={visibleColumns}
							onToggle={toggleColumn}
						/>
					</Col>
					<Col>
						<ActionWithActions
							selectedSeller={selectedSeller}
							promotionId={decodedSegment}
							selectedNomenclature={selectedNomenclature}
							setAllParticipating={setAllParticipating}
							setAllNotParticipating={setAllNotParticipating}
							setAddByControlPrice={setAddByControlPrice}
							allGoodsNotParticipating={
								itemsCount?.total === itemsCount?.notInAction
							}
						/>
					</Col>
					<Col>
						<DownloadExcel
							handleDownload={handleDownloadOldPrices}
							isLoading={oldPricesLoading}
							title={'Скачать цены до акции'}
							className={''}
						/>
					</Col>
					<Col>
						<DownloadExcel
							handleDownload={handleDownload}
							isLoading={isLoading}
							title={'Скачать цены акции'}
						/>
					</Col>
				</Row>

				<Row className='container' style={{ marginTop: '10px' }}>
					{nomenclaturesLoading ? (
						<div
							style={{
								margin: '0 auto',
								padding: '150px',
								textAlign: 'center',
							}}
						>
							<Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} />} />
						</div>
					) : nomenclaturesError ? (
						<div style={{ textAlign: 'center', padding: '20px', color: 'red' }}>
							Ошибка загрузки данных. Пожалуйста, попробуйте еще раз.
						</div>
					) : (
						<Col span={24}>
							<Table
								columns={tableColumns}
								locale={{
									emptyText: 'Нет данных',
									filterTitle: 'Фильтр',
									filterReset: 'Очистить',
									filterEmptyText: 'Нет фильтров',
								}}
								dataSource={data ? data.data.data : []}
								pagination={false}
								rowKey={(record: any) => record.id}
								scroll={{
									x: 'max-content',
									y: '60vh',
								}}
								size='small'
								style={{ width: '100%' }}
							/>
							<Col span={24}>
								<Row style={{ alignItems: 'center', justifyContent: 'center' }}>
									<Pagination
										onChange={page => setCurrentPage(page)}
										current={currentPage}
										defaultCurrent={1}
										pageSize={parseInt(currentPageSize)}
										total={data?.total}
										style={{
											textAlign: 'center',
											padding: '16px 0',
										}}
										showSizeChanger={false}
									/>
									<Col style={{ width: '190px' }}>
										<PickPageSize
											currentPageSize={currentPageSize}
											setCurrentPage={setCurrentPage}
											setCurrentPageSize={setCurrentPageSize}
										/>
									</Col>
								</Row>
							</Col>
						</Col>
					)}
				</Row>
			</div>
			<ConfirmationModal
				isOpen={confirmSetInActionVisible}
				setIsOpen={setConfirmSetInActionVisible}
				confirm={confirmSetInAction}
				title={'Вы уверены, что хотите поставить этот товар в акцию?'}
			/>
			<ConfirmationModal
				isOpen={confirmUnsetInActionVisible}
				setIsOpen={setConfirmUnsetInActionVisible}
				confirm={confirmUnsetInAction}
				title={'Вы уверены, что хотите убрать этот товар из акции?'}
			/>
		</>
	)
}

export default ActionItemsTable
