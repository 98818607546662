import { Button, Checkbox, Dropdown, Menu } from 'antd'
import React, { FC } from 'react'
import Icon from '@ant-design/icons'
import { ReactComponent as SelectIconComponent } from 'assets/images/select-icon.svg'

interface PickSelectAllProps {
	selectAll: () => void
	deselectAll: () => void
	selectedCount: number
	checked: boolean
}

const PickSelectAll: FC<PickSelectAllProps> = ({
	selectAll,
	deselectAll,
	selectedCount,
	checked,
}) => {
	const menu = (
		<Menu>
			<Menu.Item key='selectAll' onClick={selectAll}>
				Выбрать все
			</Menu.Item>
			<Menu.Item key='deselectAll' onClick={deselectAll}>
				Снять выбор
			</Menu.Item>
		</Menu>
	)

	return (
		<Dropdown
			overlay={menu}
			trigger={['click']}
			overlayClassName='dropdown-border'
		>
			<div
				className='analytics-header-dropdown-v2'
				style={{
					width: '100%',
					height: '100%',
					padding: '10px 10px',
					display: 'flex',
					alignItems: 'center',
					cursor: 'pointer',
					borderRadius: '8px 0 0 8px',
				}}
			>
				<div style={{ paddingRight: 5 }} onClick={e => e.stopPropagation()}>
					<Checkbox
						onChange={() => {
							checked ? deselectAll() : selectAll()
						}}
						checked={checked}
					/>
				</div>
				<span style={{ paddingRight: 5 }}>{selectedCount}</span>
				<Icon
					component={SelectIconComponent}
					style={{ marginTop: '2px', fontSize: '10px' }}
				/>
			</div>
		</Dropdown>
	)
}

export default PickSelectAll
