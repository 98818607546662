import { formatDate } from 'utils/formatDate'
import { api } from '../index'
import { message } from 'antd'

interface ISetPrices {
	id: number
	productId: number
	linkOnSite: string
	photoUrl: string
	newPrice: number
	newFullPrice: number
	newDiscount: number
	currentPrice: number
	currentFullPrice: number
	currentDiscount: number
	createdAt: string
	updatedAt: string
	deletedAt: string
}

export async function getPricesData(
	dataType?: 'prices' | 'history' | 'planned-prices',
	sellers?: any[],
	search?: string,
	available?: boolean | null,
	products?: any[],
	currentPageSize?: string,
	page?: string | number
) {
	try {
		const params = new URLSearchParams()

		if (sellers && sellers.length > 0) {
			params.append('sellers', sellers?.map((item: any) => item?.id)?.join(','))
		}

		if (available !== null) {
			params.append('tracking', `${available}`)
		}
		if (products && products.length > 0) {
			params.append(
				'categories',
				products?.map((item: any) => item?.id)?.join(',')
			)
		}
		if (page) {
			params.append('page', `${page}`)
		}

		if (currentPageSize) {
			params.append('limit', currentPageSize)
		}

		if (search) {
			params.append('search', search)
		}

		const response = await api.get<any>(
			`prices/${dataType}?${params.toString()}`
		)
		return response.data.data
	} catch (error: any) {
		console.error(`Error fetching data:`, error)
		message.error(`Failed to fetch data. Please try again.`)
	}
}

export async function addFileToPrices(
	file: any,
	type: 'import-prices' | 'import-min-prices' | 'import-planned-prices'
) {
	const formData = new FormData()
	formData.append('file', file)

	const params = new URLSearchParams()

	const response = await api.post<any>(`/prices/${type}`, formData, {
		params,
	})
	return response.data
}

export async function setPrices(prices: ISetPrices[]) {
	console.log('Исходные данные:', prices)

	const response = await api.post<any>(`/prices/set-prices`, prices, {
		headers: {
			'Content-Type': 'application/json',
		},
	})

	return response.data
}
export async function setMinPrice(
	productId: number,
	minPrice: number,
	controlPrice: number
) {
	try {
		const params = new URLSearchParams()

		if (productId !== null) {
			params.append('product_id', productId.toString())
		}

		if (minPrice != null) {
			params.append('min_price', minPrice.toString())
		}

		if (controlPrice != null) {
			params.append('control_price', controlPrice.toString())
		}

		const response = await api.patch<any>(
			`/prices/set-min-price?${params.toString()}`,
			{
				headers: {
					'Content-Type': 'application/json',
				},
			}
		)

		return response.data
	} catch (error: any) {
		console.error(`Error fetching data:`, error)
		message.error(`Не удалось изменить минимальную цену. Попробуйте еще раз.`)
	}
}

export async function downloadAllPrices() {
	// promotion?: string | number | null
	// sellers?: any[],
	// search?: string,
	// products?: any[],
	// available?: boolean | null,
	// participating?: boolean | null,
	// setInAction?: boolean | null
	try {
		const params = new URLSearchParams()

		// if (sellers && sellers?.length > 0) {
		// 	params.append('sellers', sellers.map((item: any) => item?.id).join(','))
		// }

		// if (promotion) {
		// 	params.append('promotions', `${promotion}`)
		// }

		// if (search) {
		// 	params.append('search', search)
		// }

		// if (products && products?.length > 0) {
		// 	params.append(
		// 		'categories',
		// 		products.map((item: any) => item?.id).join(',')
		// 	)
		// }

		// if (available !== null) {
		// 	params.append('tracking', `${available}`)
		// }

		// if (participating !== null) {
		// 	params.append('in_action', `${participating}`)
		// }

		// if (setInAction !== null) {
		// 	params.append('set_in_action', `${setInAction}`)
		// }

		const queryString = params.toString().replace(/\+/g, '%20')
		const response = await api.get<any>(
			`prices/download-all-prices${queryString ? `?${queryString}` : ''}`,
			{
				responseType: 'blob',
			}
		)
		return response.data
	} catch (error: any) {
		console.error(`Error downloading nomenclatures excel:`, error)
		message.error(`Критическая ошибка выгрузки. Попробуйте позже`)
		throw error
	}
}

export async function setPlannedPricesData(
	article: string,
	planned_price: number,
	planned_discount: number,
	start_date: string
) {
	try {
		const params = new URLSearchParams()

		if (article) {
			params.append('article', article)
		}

		if (planned_price) {
			params.append('planned_price', planned_price.toString())
		}
		if (planned_discount) {
			params.append('planned_discount', planned_discount.toString())
		}
		if (start_date) {
			params.append('start_date', start_date)
		}

		const response = await api.post<any>(
			`prices/planned-prices?${params.toString()}`
		)
		return response.data.data
	} catch (error: any) {
		console.error(`Error fetching data:`, error)
		message.error(`Failed to fetch data. Please try again.`)
	}
}

export async function deletePlannedPricesData(id: number) {
	try {
		const params = new URLSearchParams()

		if (id) {
			params.append('id', id.toString())
		}

		const response = await api.delete<any>(
			`prices/planned-prices?${params.toString()}`
		)
		return response.data.data
	} catch (error: any) {
		console.error(`Error fetching data:`, error)
		message.error(`Failed to fetch data. Please try again.`)
	}
}
