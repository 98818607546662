import { Select } from 'antd'
import React, { FC, useEffect, useState } from 'react'

const { Option } = Select

interface IIsAvailablePicker {
	selectedPriceType: boolean | null
	setSelectedPriceType: (type: boolean | null) => void
}

const priceTypes = [
	{ value: null, title: 'Цены (все)' },
	{ value: false, title: 'Ниже минимума' },
	{ value: true, title: 'Выше минимума' },
]

export const PickLessThenMin: FC<IIsAvailablePicker> = ({
	selectedPriceType,
	setSelectedPriceType,
}) => {
	const handleChange = (value: boolean | null) => {
		setSelectedPriceType(value)
	}

	return (
		<Select
			className='table-header-select'
			style={{ width: '100%' }}
			value={selectedPriceType}
			placeholder='Выберите фильтр по цене'
			onChange={handleChange}
			filterOption={false}
		>
			{priceTypes.map((option, idx) => (
				<Option key={idx} value={option.value}>
					{option.title}
				</Option>
			))}
		</Select>
	)
}
