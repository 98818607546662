import { Select } from 'antd'
import React, { FC } from 'react'
import selectIcon from 'assets/images/select-icon.svg'

interface IPageSize {
	currentPageSize: string | number
	setCurrentPage: any
	setCurrentPageSize: any
}

export const PickPageSize: FC<IPageSize> = ({
	currentPageSize,
	setCurrentPage,
	setCurrentPageSize,
}) => {
	return (
		<Select
			className='table-header-select table-header-select-no-offset'
			id='analytics-select'
			suffixIcon={<img src={selectIcon} alt={''} />}
			defaultValue={currentPageSize}
			onChange={e => {
				setCurrentPage(1)
				setCurrentPageSize(e)
			}}
			options={[
				{ value: '10', label: 'Показать по 10 шт.' },
				{ value: '25', label: 'Показать по 25 шт.' },
				{ value: '50', label: 'Показать по 50 шт.' },
				{ value: '100', label: 'Показать по 100 шт.' },
				{ value: '500', label: 'Показать по 500 шт.' },
				{ value: '1000', label: 'Показать по 1000 шт.' },
			]}
		/>
	)
}
