import React, { FC } from 'react'
import { ReactComponent as uploadButtonIcon } from '../../../assets/images/upload-button-icon.svg'
import { Button, message } from 'antd'
import Icon from '@ant-design/icons'

interface IDownload {
	handleDownload: () => void
	selectedSeller: any
	isLoading: boolean
	title?: string
	className?: string
}

export const DownloadExcel: FC<IDownload> = ({
	handleDownload,
	selectedSeller,
	isLoading,
	title = 'Скачать',
	className = 'table-header-button',
}) => {
	const onClick = () => {
		if (selectedSeller.length < 1) {
			message.error('Выберите хотя бы одного продавца')
		} else {
			handleDownload()
		}
	}

	return (
		<Button
			onClick={onClick}
			className={className}
			type='primary'
			loading={isLoading} // Показываем лоадер
			disabled={isLoading} // Блокируем кнопку
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			{title}
			{!isLoading && (
				<Icon
					component={uploadButtonIcon}
					style={{ fontSize: '18px', display: 'block' }}
				/>
			)}
		</Button>
	)
}
