import { useState, useEffect } from 'react'

export const useTableColumns = (columns: any) => {
	const columnState = () => {
		const savedColumns = localStorage.getItem('visiblePricesColumns')
		if (savedColumns) {
			try {
				const parsed = JSON.parse(savedColumns)

				return columns.map((col: any) => {
					const saved = parsed.find((savedCol: any) => savedCol.key === col.key)
					return { ...col, visible: saved ? saved.visible : true }
				})
			} catch {
				return columns.map((col: any) => ({ ...col, visible: true }))
			}
		}

		return columns.map((col: any) => ({ ...col, visible: true }))
	}

	const [visibleColumns, setVisibleColumns] = useState(columnState)

	const toggleColumn = (key: any, visible: boolean) => {
		setVisibleColumns((prev: any) =>
			prev.map((col: any) => (col.key === key ? { ...col, visible } : col))
		)
	}

	useEffect(() => {
		setVisibleColumns(columnState())
	}, [columns])

	useEffect(() => {
		const serializableColumns = visibleColumns.map(({ key, visible }: any) => ({
			key,
			visible,
		}))
		localStorage.setItem(
			'visiblePricesColumns',
			JSON.stringify(serializableColumns)
		)
	}, [visibleColumns])

	return { visibleColumns, toggleColumn }
}
