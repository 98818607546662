import React, { useEffect, useState } from 'react'
import {
	Col,
	Dropdown,
	Input,
	message,
	Pagination,
	Row,
	Spin,
	Table,
} from 'antd'
import Icon, { LoadingOutlined } from '@ant-design/icons'
import selectIcon, {
	ReactComponent as SelectIconComponent,
} from '../../assets/images/select-icon.svg'

import { PickSeller } from './components/PickSeller'

import usePricesData from './hooks/prices/usePricesData'
import { PickIsNew } from './components/PickIsNew'
import { PickPageSize } from './components/PickPageSize'
import CopyToClipboard from 'react-copy-to-clipboard'
import dayjs from 'dayjs'
import NoImage from 'assets/icon/no-image'
import TitleWithTooltip from 'features/actions/components/TitleWithTooltip'

const PricesHistoryTable = () => {
	const {
		pricesData,
		selectedSeller,
		setSelectedSeller,
		sellers,
		setDataType,
		isLoading,
		onChangeDate,
		dateSortStart,
		dateSortEnd,
		getSellersFilterItems,
		selectedActionType,
		setSelectedActionType,
		searchText,
		setSearchText,
		selectedSeenType,
		setSelectedSeenType,
		currentPageSize,
		setCurrentPageSize,
		currentPage,
		setCurrentPage,
		getProductsFilterItems,
	} = usePricesData()

	const [data, setData] = useState(pricesData)
	console.log(data)
	useEffect(() => {
		setDataType('history')
		if (pricesData) {
			setData(pricesData)
		}
	}, [pricesData])

	const columns = [
		{
			title:  '',
			dataIndex: 'photoUrl',
			key: 'photoUrl',
			width: '5%',
			align: 'center' as const,
			render: (photo: string, record: any) => {
				const { wbArticle, linkOnSite } = record || {}
				return (
					<div
						className='analytics-col-title__wrap'
						style={{ padding: '5px 6px 5px 6px' }}
					>
						<CopyToClipboard
							text={`${wbArticle || ''}`}
							onCopy={() => message.success('Текст скопирован')}
						>
							<div
								className='analytics-col-title'
								style={{ cursor: 'pointer' }}
							>
								{wbArticle || 'Нет данных'}
							</div>
						</CopyToClipboard>
						<a href={linkOnSite} target='_blank' rel='noopener noreferrer'>
							{photo ? (
								<img
									src={photo}
									style={{
										width: '90px',
										minHeight: 120,
										maxHeight: 120,
									}}
									alt='Товар'
									onError={e => {
										e.currentTarget.onerror = null
										e.currentTarget.src = ''
										e.currentTarget.style.display = 'none'
										const noImage = e.currentTarget
											.nextElementSibling as HTMLElement
										if (noImage) noImage.style.display = 'block'
									}}
								/>
							) : null}
							<NoImage
								style={{
									width: '90px',
									minHeight: 120,
									maxHeight: 120,
									display: photo ? 'none' : 'block',
								}}
							/>
						</a>
						{/* {category && <div className='analytics-col-title'>{category}</div>} */}
					</div>
				)
			},
		},
		{
			title: <TitleWithTooltip title='Отслеж.'/> ,
			dataIndex: 'isAvailable',
			key: 'isAvailable',
			width: '5%',
			align: 'center' as const,
			render: (isAvailable: any) => (
				<div
					style={{
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						background: isAvailable
							? 'rgba(0, 203, 68, 0.6)'
							: 'rgba(255, 203, 10, 0.6)',
					}}
				>
					{isAvailable ? 'Да' : 'Нет'}
				</div>
			),
		},
		{
			title: <TitleWithTooltip title='Продавец'/> ,
			dataIndex: 'companyName',
			key: 'companyName',
			width: 150,
			align: 'center' as const,
			render: (text: any) => <div style={{ padding: '0 8px' }}>{text}</div>,
		},
		{
			title: <TitleWithTooltip title='Категория'/> ,
			dataIndex: 'category',
			key: 'category',
			width: 150,
			align: 'center' as const,
			render: (text: any) => <div style={{ padding: '0 8px' }}>{text}</div>,
		},
		{
			title: <TitleWithTooltip title='Текущая цена'/> ,
			dataIndex: 'currentPrice',
			key: 'currentPrice',
			width: 50,
			align: 'center' as const,
			render: (text: any) => <div style={{ padding: '0 8px' }}>{text}</div>,
		},
		{
			title: <TitleWithTooltip title='Нормальная цена'/>,
			dataIndex: 'normalPrice',
			key: 'normalPrice',
			width: 50,
			align: 'center' as const,
			render: (text: any) => <div style={{ padding: '0 8px' }}>{text}</div>,
		},
		{
			title: <TitleWithTooltip title='Минимальная цена'/>,
			dataIndex: 'minPrice',
			key: 'minPrice',
			width: 50,
			align: 'center' as const,
			render: (text: any) => <div style={{ padding: '0 8px' }}>{text}</div>,
		},
		{
			title: <TitleWithTooltip title='Дата установки цены'/>,
			dataIndex: 'priceInstalledDate',
			key: 'priceInstalledDate',
			width: 50,
			align: 'center' as const,
			render: (text: any) => (
				<div style={{ padding: '0 8px' }}>
					{dayjs(text).format('DD.MM.YYYY HH:mm')}
				</div>
			),
		},
		{
			title: <TitleWithTooltip title='Оператор'/>,
			dataIndex: 'operator',
			key: 'operator',
			width: 50,
			align: 'center' as const,
			render: (text: any) => <div style={{ padding: '0 8px' }}>{text}</div>,
		},
	]

	return (
		<div style={{ backgroundColor: '#f8f8f8' }}>
			<Row gutter={6} align={'middle'} justify={'end'} style={{ gap: 5 }}>
				<Col style={{ flexGrow: '1' }}>
					<Input
						className='table-header-input'
						placeholder={'Поиск'}
						value={searchText}
						onChange={e => setSearchText(e.target.value)}
						type={'number'}
					/>
				</Col>
				<Col style={{ width: '120px' }}>
					<PickSeller
						getSellersFilterItems={getSellersFilterItems}
						selectedSeller={selectedSeller}
					/>
				</Col>
				<Col style={{ width: '160px' }}>
					<PickIsNew
						selectedSeenType={selectedSeenType}
						setSelectedSeenType={setSelectedSeenType}
					/>
				</Col>
				<Col style={{ width: '98px' }}>
					<Dropdown
						trigger={['click']}
						placement={'bottomRight'}
						menu={{ items: getProductsFilterItems() }}
						overlayClassName={'dropdown-border'}
					>
						<div className={'analytics-header-dropdown '}>
							<div>Предмет</div>
							<Icon
								component={SelectIconComponent}
								style={{ marginTop: '2px', fontSize: '10px' }}
							/>
						</div>
					</Dropdown>
				</Col>
				<Col style={{ width: '190px' }}>
					<PickPageSize
						currentPageSize={currentPageSize}
						setCurrentPage={setCurrentPage}
						setCurrentPageSize={setCurrentPageSize}
					/>
				</Col>
			</Row>

			<Row className={'container'} style={{ marginTop: '10px' }}>
				{isLoading ? (
					<div
						style={{
							margin: '0 auto',
							padding: '150px',
							textAlign: 'center',
						}}
					>
						<Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} />} />
					</div>
				) : data?.length === 0 ? (
					<div style={{ margin: '0 auto', padding: 150 }}>Нет данных</div>
				) : (
					<Col span={24}>
						<Table
							columns={columns}
							locale={{
								emptyText: 'Нет данных',
								filterTitle: 'Фильтр',
								filterReset: 'Очистить',
								filterEmptyText: 'Нет фильтров',
							}}
							dataSource={data ? data.data : []}
							pagination={false}
							rowKey={(record: any) => record.id}
							scroll={{ x: 'max-content', y: '65vh' }}
						/>
						<Col span={24}>
							<Pagination
								onChange={page => setCurrentPage(page)}
								current={currentPage}
								defaultCurrent={1}
								pageSize={parseInt(currentPageSize)}
								total={data?.total}
								style={{
									textAlign: 'center',
									padding: '16px 0',
								}}
								showSizeChanger={false}
							/>
						</Col>
					</Col>
				)}
			</Row>
		</div>
	)
}

export default PricesHistoryTable
