import { useQuery, useMutation, useQueryClient } from 'react-query'
import { backend } from 'api'
import React, { useEffect, useState } from 'react'
import { Checkbox, Input, MenuProps, message } from 'antd'
import useSellerList from 'features/sellers/hooks/useSellerList'
import { format } from 'path'
import { formatDate } from 'utils/formatDate'
import dayjs, { Dayjs } from 'dayjs'
import useProductList from 'features/product/hooks/useProductList'
import Icon from '@ant-design/icons'
import { ReactComponent as searchIcon } from 'assets/images/search-icon.svg'
import { useDebounce } from 'use-debounce'

export default function useActionsData() {
	const queryClient = useQueryClient()
	const [currentPage, setCurrentPage] = useState<number>(1)
	const [currentPageSize, setCurrentPageSize] = useState<string>('25')

	const [dataType, setDataType] = useState<
		'calendar' | 'promotion_changes' | 'action_details'
	>('calendar')
	const [actionsData, setActionsData] = useState<any>(null)
	const [nomenclaturesData, setNomenclaturesData] = useState<any>(null)
	const [promotions, setPromotions] = useState<string[] | null>(null)
	const [searchText, setSearchText] = useState<string>('')
	const [selectedChangeType, setSelectedChangeType] = useState<string[] | null>(
		null
	)
	const [selectedSeenType, setSelectedSeenType] = useState<boolean | null>(null)

	const [debouncedSearchText] = useDebounce(searchText, 1000)

	const {
		sellers,
		search: searchSellers,
		setSearch: setSearchSellers,
	} = useSellerList()

	const [selectedSeller, setSelectedSeller] = useState<Array<string>>([])

	const [dateSortStart, setDateSortStart] = useState<Dayjs | null>(null)
	const [dateSortEnd, setDateSortEnd] = useState<Dayjs | null>(null)

	const onChangeDate = (dates: [Dayjs | null, Dayjs | null] | null) => {
		if (dates) {
			setDateSortStart(dates[0])
			setDateSortEnd(dates[1])
		} else {
			setDateSortStart(null)
			setDateSortEnd(null)
		}
	}

	useEffect(() => {
		const today = dayjs()
		const startDate = today.subtract(14, 'day')
		const endDate = today.add(14, 'day')

		onChangeDate([startDate, endDate])
	}, [])

	const formatDateForApi = (date: Dayjs | null) =>
		date ? date.format('DD.MM.YYYY') : null

	const [selectedProducts, setSelectedProducts] = useState<Array<string>>([])
	const [selectedAvailableType, setSelectedAvailableType] = useState<
		null | boolean
	>(null)
	const [selectedParticipatingType, setSelectedParticipatingType] = useState<
		null | boolean
	>(null)
	const [selectedActionType, setSelectedActionType] = useState<null | string[]>(
		null
	)
	const [isSetInAction, setIsSetInAction] = useState<null | boolean>(null)
	const [wbStocks, setWbStocks] = useState<null | boolean>(null)
	const [showHidden, setShowHidden] = useState<null | boolean>(false)

	const {
		products,
		search: searchProduct,
		setSearch: setSearchProduct,
		setNotEmptyMode,
	} = useProductList()

	const {
		data: sData,
		error,
		isLoading,
		refetch,
		...options
	} = useQuery(
		[
			'ACTIONS_DATA',
			selectedSeller,
			dataType,
			dateSortEnd,
			dateSortStart,
			selectedActionType,
			showHidden,
		],
		() =>
			backend.actions.getActionsData(
				selectedSeller,
				formatDateForApi(dateSortStart),
				formatDateForApi(dateSortEnd),
				selectedActionType,
				showHidden
			),
		{
			keepPreviousData: false,
			refetchOnWindowFocus: false,
			enabled: !!selectedSeller && !!dateSortStart && !!dateSortEnd,
			// &&				dataType === 'calendar',

			onError: err => {
				console.error(`Failed to fetch ${dataType} data: ${err}`)
			},
			onSuccess: data => {
				const newSettingsData = data?.result ? data.result : data
				setActionsData(newSettingsData)
			},
		}
	)

	const {
		data: nomenclatures,
		error: nomenclaturesError,
		isLoading: nomenclaturesLoading,
		// refetch,
		// ...options
	} = useQuery(
		[
			'ACTIONS_NOMENCLATURES_DATA',
			currentPage,
			promotions,
			currentPageSize,
			selectedSeller,
			debouncedSearchText,
			selectedProducts,
			selectedAvailableType,
			selectedParticipatingType,
			isSetInAction,
			wbStocks,
		],
		() =>
			backend.actions.getNomenclaturesData(
				currentPage,
				promotions,
				currentPageSize,
				selectedSeller,
				debouncedSearchText,
				selectedProducts,
				selectedAvailableType,
				selectedParticipatingType,
				isSetInAction,
				wbStocks
			),
		{
			keepPreviousData: false,
			refetchOnWindowFocus: false,
			enabled: !!promotions,

			onError: err => {
				console.error(`Failed to fetch ${dataType} data: ${err}`)
			},
			onSuccess: data => {
				if (data?.isSuccess) {
					setNomenclaturesData(data.data)
				} else {
					message.error('не удалось получить номенклатуры')
				}
			},
		}
	)

	const getProductsFilterItems = () => {
		if (!products) {
			return []
		}

		let allItems: any[] = []
		let selectedItems: any[] = []

		products?.forEach((item: any) => {
			const isDuplicate = selectedProducts.some(
				(productItem: any) =>
					productItem?.id?.toString() === item?.id?.toString()
			)

			if (!isDuplicate) {
				allItems.push({
					label: (
						<div
							style={{ display: 'flex', gap: '10px' }}
							onClick={e => {
								e.stopPropagation()
							}}
						>
							<Checkbox
								style={{ width: '100%' }}
								value={item.title}
								checked={selectedProducts.some(
									(productItems: any) =>
										productItems?.id?.toString() === item?.id?.toString()
								)}
								onChange={e => {
									let newSelectedProducts = [...selectedProducts]
									if (e.target.checked) {
										newSelectedProducts.push(item)
									} else {
										newSelectedProducts = newSelectedProducts.filter(
											(el: any) => el?.id?.toString() !== item?.id?.toString()
										)
									}
									setSelectedProducts(newSelectedProducts)
								}}
							>
								{item.title}
							</Checkbox>
						</div>
					),
					key: `product-${item.id}`,
				})
			}
		})

		selectedProducts?.forEach((item: any) => {
			const isDuplicate = selectedProducts.some(
				(productItem: any) =>
					productItem?.id?.toString() === item?.id?.toString()
			)

			if (isDuplicate) {
				selectedItems.push({
					label: (
						<div
							style={{ display: 'flex', gap: '10px' }}
							onClick={e => {
								e.stopPropagation()
							}}
						>
							<Checkbox
								style={{ width: '100%' }}
								value={item.title}
								checked={selectedProducts.some(
									(productItems: any) =>
										productItems?.id?.toString() === item?.id?.toString()
								)}
								onChange={e => {
									let newSelectedProducts = [...selectedProducts]
									if (e.target.checked) {
										newSelectedProducts.push(item)
									} else {
										newSelectedProducts = newSelectedProducts.filter(
											(el: any) => el?.id?.toString() !== item?.id?.toString()
										)
									}
									setSelectedProducts(newSelectedProducts)
								}}
							>
								{item.title}
							</Checkbox>
						</div>
					),
					key: `product-${item.id}`,
				})
			}
		})

		const items: MenuProps['items'] = [
			{
				label: (
					<Input
						value={searchProduct}
						className={'analytic-dropdown-input'}
						onClick={e => e?.stopPropagation()}
						onChange={e => setSearchProduct(e?.target?.value)}
						prefix={<Icon component={searchIcon} />}
						placeholder={'Поиск...'}
					/>
				),
				key: 'product-search',
			},
			...selectedItems,
			...allItems,
		]

		return items
	}

	const getSellersFilterItems = () => {
		if (!sellers) {
			return []
		}

		let allItems: any[] = []
		let selectedItems: any[] = []

		const handleCheckboxChange = (item: any, checked: boolean) => {
			let newSelectedSellers = [...selectedSeller]
			if (checked) {
				newSelectedSellers.push(item)
			} else {
				newSelectedSellers = newSelectedSellers.filter(
					(el: any) => el?.id.toString() !== item?.id.toString()
				)
			}
			setSelectedSeller(newSelectedSellers)
		}

		sellers?.forEach((item: any) => {
			const isDuplicate = selectedSeller.some(
				(sellerItem: any) => sellerItem?.id.toString() === item?.id.toString()
			)

			if (!isDuplicate) {
				allItems.push({
					label: (
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								gap: '10px',
							}}
							onClick={e => e.stopPropagation()} // предотвращает закрытие
						>
							<Checkbox
								style={{ pointerEvents: 'auto' }} // позволяет работать с checkbox
								value={item?.company_name}
								checked={selectedSeller.some(
									(sellerItem: any) =>
										sellerItem?.id.toString() === item?.id.toString()
								)}
								onChange={e => handleCheckboxChange(item, e.target.checked)}
							>
								{item?.company_name}
							</Checkbox>
						</div>
					),
					key: `seller-${item.id.toString()}`,
				})
			}
		})

		selectedSeller?.forEach((item: any) => {
			selectedItems.push({
				label: (
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							gap: '10px',
						}}
						onClick={e => e.stopPropagation()}
					>
						<Checkbox
							style={{ pointerEvents: 'auto' }}
							value={item?.company_name}
							checked={true}
							onChange={e => handleCheckboxChange(item, e.target.checked)}
						>
							{item?.company_name}
						</Checkbox>
					</div>
				),
				key: `seller-${item.id}`,
			})
		})

		const items: MenuProps['items'] = [
			{
				label: (
					<Input
						value={searchSellers}
						className={'analytic-dropdown-input'}
						onClick={e => e?.stopPropagation()}
						onChange={e => setSearchSellers(e?.target?.value)}
						prefix={<Icon component={searchIcon} />}
						placeholder={'Поиск...'}
					/>
				),
				key: 'sizes-search',
			},
			...selectedItems,
			...allItems,
		]

		return items
	}

	const [loadingCheckboxId, setLoadingCheckboxId] = useState<
		string | number | null
	>(null)

	const setInAction = useMutation(
		async ({
			id,
			setInAction,
		}: {
			id: string | number
			setInAction: boolean
		}) => await backend.actions.setInAction(id, setInAction),
		{
			onSuccess: () => {
				queryClient.invalidateQueries('ACTIONS_NOMENCLATURES_DATA')
			},
			onSettled: () => {
				setLoadingCheckboxId(null)
			},
		}
	)

	const {
		data: promotionTypes,
		error: promotionTypesError,
		isLoading: promotionTypesLoading,
	} = useQuery(['ACTIONS_TYPES'], () => backend.actions.getActionTypes(), {
		keepPreviousData: true,
		refetchOnWindowFocus: false,
		// enabled: !!promotions,

		onError: err => {
			console.error(`Failed to fetch ${dataType} data: ${err}`)
			message.error(`Не удалось получить типы акций`)
		},
	})

	const { data: nomenclatureChangeTypesData } = useQuery(
		['NOMENCLATURE_CHANGE_TYPES'],
		backend.actions.getNomenclatureChangeTypes,
		{
			enabled: dataType === 'action_details',
			onError: () =>
				message.error('Не удалось загрузить типы изменений номенклатур'),
		}
	)

	const { data: promotionChangeTypesData } = useQuery(
		['PROMOTION_CHANGE_TYPES'],
		backend.actions.getPromotionChangeTypes,
		{
			enabled: dataType === 'promotion_changes',
			onError: () => message.error('Не удалось загрузить типы изменений акций'),
		}
	)

	const { data: nomenclatureChanges } = useQuery(
		[
			'NOMENCLATURE_CHANGES',
			promotions,
			currentPage,
			currentPageSize,
			selectedSeller,
			debouncedSearchText,
			selectedProducts,
			dateSortStart,
			dateSortEnd,
			selectedChangeType,
			selectedAvailableType,
			selectedParticipatingType,
			selectedSeenType,
		],
		() =>
			backend.actions.getNomenclatureChanges(
				currentPage,
				promotions,
				currentPageSize,
				selectedSeller,
				debouncedSearchText,
				selectedProducts,
				formatDateForApi(dateSortStart),
				formatDateForApi(dateSortEnd),
				selectedChangeType,
				selectedAvailableType,
				selectedParticipatingType,
				selectedSeenType
			),
		{
			enabled:
				!!dateSortStart && !!dateSortEnd && dataType === 'action_details',
			onError: () =>
				message.error('Не удалось загрузить изменения номенклатур'),
			// onSuccess: data => setNomenclaturesData(data),
		}
	)

	const { data: promotionChanges } = useQuery(
		[
			'PROMOTION_CHANGES',
			promotions,
			currentPage,
			currentPageSize,
			selectedSeller,
			dateSortStart,
			dateSortEnd,
			selectedChangeType,

			selectedSeenType,
			selectedActionType,
		],
		() =>
			backend.actions.getPromotionChanges(
				currentPage,
				promotions,
				currentPageSize,
				selectedSeller,
				formatDateForApi(dateSortStart),
				formatDateForApi(dateSortEnd),
				selectedChangeType,
				selectedSeenType,
				selectedActionType
			),
		{
			enabled:
				!!dateSortStart && !!dateSortEnd && dataType === 'promotion_changes',
			onError: () =>
				message.error('Не удалось загрузить изменения номенклатур'),
			// onSuccess: data => setNomenclaturesData(data),
		}
	)

	const setChangeSeen = useMutation(
		async ({ id, seen }: { id: string | number; seen: boolean }) =>
			backend.actions.setChangeSeen(id, seen),
		{
			onSuccess: () => {
				queryClient.invalidateQueries('NOMENCLATURE_CHANGES')
				message.success('Изменение сохранено.')
			},
			onError: () => {
				message.error('Не удалось отметить изменение как просмотренное')
			},
			onSettled: () => {
				setLoadingCheckboxId(null)
			},
		}
	)

	const setPromotionChangeSeen = useMutation(
		async ({ id, seen }: { id: string | number; seen: boolean }) =>
			backend.actions.setPromotionChangeSeen(id, seen),
		{
			onSuccess: () => {
				queryClient.invalidateQueries('PROMOTION_CHANGES')
				message.success('Изменение сохранено.')
			},
			onError: () => {
				message.error('Не удалось отметить изменение как просмотренное')
			},
			onSettled: () => {
				setLoadingCheckboxId(null)
			},
		}
	)

	const setAllInAction = useMutation(
		async ({
			setInAction,
			promotion,
		}: {
			setInAction: boolean
			promotion: string | number
		}) =>
			backend.actions.setAllInAction(promotion, selectedSeller, setInAction),
		{
			onSuccess: () => {
				queryClient.invalidateQueries('ACTIONS_NOMENCLATURES_DATA')
				message.success('Изменение сохранено.')
			},
			onError: () => {
				message.error('Не удалось поставить все товары в акцию')
			},
			onSettled: () => {
				setLoadingCheckboxId(null)
			},
		}
	)

	const setAllChangeSeen = useMutation(
		async ({ seen }: { seen: boolean }) =>
			backend.actions.setAllChangeSeen(selectedSeller, seen),
		{
			onSuccess: () => {
				queryClient.invalidateQueries('NOMENCLATURE_CHANGES')
				message.success('Изменение сохранено.')
			},
			onError: () => {
				message.error('Не удалось отметить все номенклатуры как просмотренные')
			},
			onSettled: () => {
				setLoadingCheckboxId(null)
			},
		}
	)

	const setAllPromotionChangeSeen = useMutation(
		async ({ seen }: { seen: boolean }) =>
			backend.actions.setAllPromotionChangeSeen(selectedSeller, seen),
		{
			onSuccess: () => {
				queryClient.invalidateQueries('PROMOTION_CHANGES')
				message.success('Изменение сохранено.')
			},
			onError: () => {
				message.error('Не удалось все акции как просмотренные')
			},
			onSettled: () => {
				setLoadingCheckboxId(null)
			},
		}
	)

	const setHideAction = useMutation(
		async ({ id, hide }: { id: string | number; hide: boolean }) =>
			backend.actions.setHideAction(id, hide),
		{
			onSuccess: () => {
				queryClient.invalidateQueries('ACTIONS_DATA')
				message.success('Изменение сохранено.')
			},
			onError: () => {
				message.error('Не удалось скрыть акцию')
			},
			onSettled: () => {
				setLoadingCheckboxId(null)
			},
		}
	)

	const setNotParticipating = useMutation(
		async ({
			seller_id,
			nm_id,
			promotion_detailed_id,
		}: {
			seller_id: string | number
			nm_id: string | number
			promotion_detailed_id: string | number
		}) =>
			backend.actions.setNotParticipating(
				seller_id,
				nm_id,
				promotion_detailed_id
			),
		{
			onSuccess: data => {
				if (data.isSuccess === false) {
					message.error('Проиошла неизвестная ошибка')
				} else {
					queryClient.invalidateQueries('ACTIONS_NOMENCLATURES_DATA')
					message.success('Изменение сохранено.')
				}
			},
			onError: () => {
				message.error('Произошла неизвестная ошибка')
			},
			onSettled: () => {
				setLoadingCheckboxId(null)
			},
		}
	)

	const setParticipating = useMutation(
		async ({
			seller_id,
			nm_id,
			promotion_detailed_id,
		}: {
			seller_id: string | number
			nm_id: string | number
			promotion_detailed_id: string | number
		}) =>
			backend.actions.setParticipating(seller_id, nm_id, promotion_detailed_id),
		{
			onSuccess: data => {
				if (data.isSuccess === false) {
					message.error('Проиошла неизвестная ошибка')
				} else {
					queryClient.invalidateQueries('ACTIONS_NOMENCLATURES_DATA')
					message.success('Изменение сохранено.')
				}
			},
			onError: () => {
				message.error('Произошла неизвестная ошибка')
			},
			onSettled: () => {
				setLoadingCheckboxId(null)
			},
		}
	)

	const setAllParticipating = useMutation(
		async ({
			promotion,
			nm_ids,
		}: {
			promotion: string | number
			nm_ids: any[]
		}) =>
			backend.actions.setAllParticipating(promotion, selectedSeller, nm_ids),
		{
			onSuccess: data => {
				if (data.isSuccess === true && data.data.error === false) {
					queryClient.invalidateQueries('ACTIONS_NOMENCLATURES_DATA')
					message.success('Изменение сохранено.')
				} else {
					message.error(
						`Не удалось применить изменения по причинам: ${data.data.errorText} ${data.message}`
					)
				}
			},
			onError: (data: any) => {
				if (data.data.errorText) {
					message.error(
						`Не удалось поставить все товары в акцию по причине ${data.data.errorText}`
					)
				} else if (data.message) {
					message.error(
						`Не удалось поставить все товары в акцию по причине ${data.message}`
					)
				} else {
					message.error('Не удалось поставить все товары в акцию ')
				}
			},
			onSettled: () => {
				setLoadingCheckboxId(null)
			},
		}
	)

	const setAllNotParticipating = useMutation(
		async ({
			promotion,
			nm_ids,
		}: {
			promotion: string | number
			nm_ids: any[]
		}) =>
			backend.actions.setAllNotParticipating(promotion, selectedSeller, nm_ids),
		{
			onSuccess: data => {
				if (data.isSuccess === true && data.data.error === false) {
					queryClient.invalidateQueries('ACTIONS_NOMENCLATURES_DATA')
					message.success('Изменение сохранено.')
				} else {
					message.error(
						`Не удалось применить изменения по причинам: ${data.data.errorText} ${data.message}`
					)
				}
			},
			onError: (data: any) => {
				if (data.data.errorText) {
					message.error(
						`Не удалось убрать все товары из акции по причине ${data.data.errorText}`
					)
				} else if (data.message) {
					message.error(
						`Не удалось убрать все товары из акции по причине ${data.message}`
					)
				} else {
					message.error('Не удалось убрать все товары из акции ')
				}
			},
			onSettled: () => {
				setLoadingCheckboxId(null)
			},
		}
	)

	const setAddByControlPrice = useMutation(
		async ({
			promotion,
			nm_ids,
		}: {
			promotion: string | number
			nm_ids: any[]
		}) => backend.actions.addByControlPrice(promotion, selectedSeller, nm_ids),
		{
			onSuccess: data => {
				console.log(data)
				if (data.isSuccess === true && data.data.error === false) {
					queryClient.invalidateQueries('ACTIONS_NOMENCLATURES_DATA')
					message.success('Товары добавлены в акцию по контрольной цене.')
				} else if (data.message > 1) {
					message.error(
						`Не удалось добавить товары по причинам: ${data.message}`
					)
				} else {
					message.error(
						`Не удалось добавить товары по неизвестной причине, обратитесь в тех.поддержку.`
					)
				}
			},
			onError: (data: any) => {
				if (data.message && data.message.length > 0) {
					message.error(
						`Не удалось убрать все товары из акции по причине ${data.message}`
					)
				} else {
					message.error('Не удалось убрать все товары из акции ')
				}
			},
			onSettled: () => {
				setLoadingCheckboxId(null)
			},
		}
	)

	const generateFilename = (actionId: string | number): string => {
		const currentDate = dayjs().format('YYYY-MM-DD')

		const action = actionsData.find(
			(action: { id: string | number; name: string }) =>
				String(action.id) === String(actionId)
		)

		if (!action) {
			console.error(`Action with id ${actionId} not found in actionsData`)
			return `action_report_${currentDate}.xlsx`
		}

		return `${action.name}_${currentDate}.xlsx`
	}

	const downloadNomenclatureExcel = useMutation(
		async ({ promotion }: { promotion: string | number }) =>
			backend.actions.downloadNomenclaturesData(
				promotion,
				selectedSeller,
				debouncedSearchText,
				selectedProducts,
				selectedAvailableType,
				selectedParticipatingType,
				isSetInAction
			),
		{
			onError: err => {
				console.error(`Failed to download data: ${err}`)
				message.error('Не удалось скачать Excel файл')
			},
			onSuccess: (data: Blob, variables: { promotion: string | number }) => {
				const url = window.URL.createObjectURL(new Blob([data]))
				const link = document.createElement('a')
				link.href = url
				link.setAttribute('download', generateFilename(variables.promotion))
				link.setAttribute('target', '_blank')
				document.body.appendChild(link)
				link.click()
				link.remove()
				message.success('Файл успешно загружен!')
			},
		}
	)

	const downloadAllOldPricesExcel = useMutation(
		async ({ promotion }: { promotion: string | number }) =>
			backend.actions.downloadAllOldPrices(
				promotion
				// selectedSeller,
				// debouncedSearchText,
				// selectedProducts,
				// selectedAvailableType,
				// selectedParticipatingType,
				// isSetInAction
			),
		{
			onError: err => {
				console.error(`Failed to download data: ${err}`)
				message.error('Не удалось скачать Excel файл')
			},
			onSuccess: (data: Blob, variables: { promotion: string | number }) => {
				const url = window.URL.createObjectURL(new Blob([data]))
				const link = document.createElement('a')
				link.href = url
				link.setAttribute('download', generateFilename(variables.promotion))
				link.setAttribute('target', '_blank')
				document.body.appendChild(link)
				link.click()
				link.remove()
				message.success('Файл успешно загружен!')
			},
		}
	)

	return {
		actionsData,
		nomenclatures: nomenclaturesData,
		selectedSeller,
		setSelectedSeller,
		dataType,
		setDataType,
		sellers,
		setSearchSellers,
		searchSellers,
		isLoading,
		error,

		setPromotions,
		promotions,
		currentPage,
		setCurrentPage,
		currentPageSize,
		setCurrentPageSize,

		nomenclaturesLoading,
		nomenclaturesError,

		dateSortStart,
		dateSortEnd,
		onChangeDate,

		getProductsFilterItems,
		getSellersFilterItems,

		searchText,
		setSearchText,

		selectedAvailableType,
		setSelectedAvailableType,
		selectedParticipatingType,
		setSelectedParticipatingType,
		selectedActionType,
		setSelectedActionType,
		setAvailable: setInAction.mutate,
		setLoadingCheckboxId,
		loadingCheckboxId,

		promotionTypes,

		nomenclaturesData,
		setChangeSeen: setChangeSeen.mutate,
		setPromotionChangeSeen: setPromotionChangeSeen.mutate,
		nomenclatureChanges,
		nomenclatureChangeTypesData,

		selectedChangeType,
		setSelectedChangeType,
		selectedSeenType,
		setSelectedSeenType,
		isSetInAction,
		setIsSetInAction,
		wbStocks,
		setWbStocks,

		promotionChanges,
		promotionChangeTypesData,

		setAllInAction: setAllInAction.mutate,
		setAllChangeSeen: setAllChangeSeen.mutate,
		setAllPromotionChangeSeen: setAllPromotionChangeSeen.mutate,
		setHideAction: setHideAction.mutate,
		showHidden,
		setShowHidden,
		setParticipating: setParticipating.mutate,
		setNotParticipating: setNotParticipating.mutate,
		setAllParticipating: setAllParticipating.mutate,
		setAllNotParticipating: setAllNotParticipating.mutate,
		setAddByControlPrice: setAddByControlPrice.mutate,
		downloadNomenclatureExcel: downloadNomenclatureExcel.mutateAsync,
		downloadAllOldPricesExcel: downloadAllOldPricesExcel.mutateAsync,

		...options,
	}
}
