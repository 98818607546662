import React, { useState } from 'react'
import { Col, Row } from 'antd'
import { useNavigate, Link, useLocation, Outlet } from 'react-router-dom'

const ActionsPage = () => {
	const location = useLocation()
	const navigate = useNavigate()
	return (
		<Row>
			<Col style={{ padding: '10px 20px' }} span={24}>
				<div className={'storage-tabs'}>
					<div
						className={`${
							location.pathname.includes('/actions-calendar')
								? 'tab-active'
								: ''
						}`}
						onClick={() => navigate('/actions/actions-calendar')}
					>
						Календарь акций
					</div>
					<div
						className={`${
							location.pathname.includes('/changes') ? 'tab-active' : ''
						}`}
						onClick={() => navigate('/actions/changes')}
					>
						Изменения акций
					</div>
					<div
						className={`${
							location.pathname.includes('/actions-details') ? 'tab-active' : ''
						}`}
						onClick={() => navigate('/actions/actions-details')}
					>
						Детали об акциях
					</div>
					<div
						className={`${
							location.pathname.includes('/actions-boost') ? 'tab-active' : ''
						}`}
						onClick={() => navigate('/actions/actions-boost')}
					>
						Бустинг товаров
					</div>
				</div>
				<Row>
					<Col span={24}>
						<Outlet />
					</Col>
				</Row>
			</Col>
		</Row>
	)
}

export default ActionsPage
