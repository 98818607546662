import React from 'react'
import { Table } from 'antd'
import TableHeader from './TableHeader'

interface DataType {
	key: string
	[date: string]: any
}

interface DynamicTableProps {
	data: DataType[]
}

const TableItems: React.FC<DynamicTableProps> = ({ data }) => {
	const columns = [
		{
			title: (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						maxHeight: 50,
						minHeight: 50,
						background: '#666666',
						fontSize: 14,
						marginBottom: 0.83,
					}}
				>
					<a
						href='https://docs.google.com/spreadsheets/d/1VoHCrCPfzMPrbj-rnNkKKWxBl_GkmHVzCBg0_V6bK-U/edit?gid=1590828847#gid=1590828847'
						style={{ color: '#fff', textDecoration: 'underline' }}
					>
						Отчет продаж
					</a>
					<a
						href='https://docs.google.com/spreadsheets/d/1VoHCrCPfzMPrbj-rnNkKKWxBl_GkmHVzCBg0_V6bK-U/edit?gid=1590828847#gid=1590828847'
						style={{ color: '#fff', textDecoration: 'underline' }}
					>
						Анализ комета
					</a>
				</div>
			),
			dataIndex: 'name',
			key: 'name',
			width: 190,
			render(text: any, record: any) {
				return {
					props: {
						style: { background: record.color, height: 25 },
					},
					children: (
						<div style={{ color: record.textColor, padding: '0 8px' }}>
							{text}
						</div>
					),
				}
			},
		},
	]

	return (
		<div className='custom-table'>
			<Table
				locale={{
					emptyText: 'Нет данных',
					filterTitle: 'Фильтр',
					filterReset: 'Очистить',
					filterEmptyText: 'Нет фильтров',
				}}
				dataSource={data || []}
				columns={columns}
				size='small'
				pagination={false}
				style={{ width: '100%' }}
			/>
		</div>
	)
}

export default TableItems
