import { Col, Row } from 'antd'
import { ActionsBoostTable } from 'features/actions/ActionsBoostTable'
import React from 'react'

export default function ActionsBoost() {
	return (
		<Row style={{ padding: '10px 0' }}>
			<Col className={'container'} span={24}>
				<ActionsBoostTable />
			</Col>
		</Row>
	)
}
