import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {Menu} from "antd";
import {
    ApiOutlined,
    UserOutlined,
    CopyOutlined,
    WalletOutlined,
    FileDoneOutlined,
    FileOutlined,
    EuroOutlined,
    CrownOutlined,
    SettingOutlined
} from "@ant-design/icons";
import Icon from "@ant-design/icons";
import icon from "assets/images/sider-menu-icon.svg";
import iconWhite from "assets/images/sider-menu-icon-white.svg";
import iconActive from "assets/images/sider-menu-icon-active.svg";
import {ReactComponent as financialResultsIcon} from "assets/images/financial-results-icon.svg";
import {ReactComponent as setting} from "assets/images/setting.svg";
import {ReactComponent as analyticsIcon} from "assets/images/analytics-icon.svg";
import {ReactComponent as storageIcon} from "assets/images/storage-icon.svg";
import {ReactComponent as costPriceIcon} from "assets/images/costPriceIcon.svg";
import KizesIcon from "../../assets/icon/KizesIcon";
import {BellOutlined, LogoutOutlined} from "@ant-design/icons";
import {useAuthStore} from "../auth/hooks/useAuthStore";
import CostPrice from "../../assets/icon/CostPrice";

const SideNavContent = (props: any) => {

    const {routeInfo, collapsed, setCollapsed, isShowBurger, setIsShowBurger, isMob, isFinance} = props;
    const {state} = useAuthStore();
    const isInvestor = state?.user?.role?.name === 'Инвестор';

    const [navigationConfig, setNavigationConfig] = useState([
        {
            key: "apps",
            path: `/`,
            icon: "",
            title: "Основное",
            breadcrumb: false,
            submenu: [
                {
                    key: "apps-analytics",
                    path: `/analytics`,
                    title: "Аналитика",
                    icon: <Icon component={analyticsIcon}/>,
                    breadcrumb: false,
                    submenu: [],
                },
                {
                    key: "apps-shipments",
                    path: `/shipments`,
                    title: "Отгрузки/дозаказ товаров",
                    icon: <Icon component={analyticsIcon}/>,
                    breadcrumb: false,
                    submenu: [],
                },
                {
                    key: "apps-cashbacks",
                    path: `/cashbacks`,
                    title: "Кешбэки",
                    icon: React.createElement(CrownOutlined),
                    breadcrumb: false,
                    submenu: [],
                },
                {
                    key: "apps-storage",
                    path: `/storage`,
                    title: "Склад",
                    icon: <Icon component={storageIcon}/>,
                    breadcrumb: false,
                    submenu: [],
                },
                {
                    key: "apps-order-from-chine",
                    path: `/orders-from-china`,
                    title: "Заказы с Китая",
                    icon: React.createElement(FileDoneOutlined),
                    breadcrumb: false,
                    submenu: [],
                },
                {
                    key: "apps-mirror",
                    path: `/advertising-mirror`,
                    title: "Реклама",
                    icon: React.createElement(ApiOutlined),
                    breadcrumb: false,
                    submenu: [],
                },
                {
                    key: "apps-financial-results",
                    path: `/financial-results`,
                    title: "Финансовые результаты",
                    icon: <Icon component={financialResultsIcon}/>,
                    breadcrumb: false,
                    submenu: [],
                },
                {
                    key: "apps-questions-and-answers",
                    path: `/innovation-proposals`,
                    title: "Рационализаторские предложения",
                    icon: <Icon component={financialResultsIcon}/>,
                    breadcrumb: false,
                    submenu: [],
                },
                {
                    key: "apps-innovation-proposals",
                    path: `/questions-and-answers`,
                    title: "Вопросы - ответы",
                    icon: <Icon component={financialResultsIcon}/>,
                    breadcrumb: false,
                    submenu: [],
                },
                {
                    key: "apps-products",
                    path: `/products/products`,
                    title: "Продукты и Аналитики",
                    icon: React.createElement(FileOutlined),
                    breadcrumb: false,
                    submenu: [],
                },
                {
                    key: "apps-operating-expenses",
                    path: `/operating-expenses`,
                    title: "Операционные затраты",
                    icon: React.createElement(WalletOutlined),
                    breadcrumb: false,
                    submenu: [],
                },
                {
                    key: "apps-cost-price",
                    path: `/cost-price`,
                    title: "Себестоимость",
                    icon: React.createElement(EuroOutlined),
                    breadcrumb: false,
                    submenu: [],
                },
                {
                    key: "apps-document-mirror",
                    path: `/document-mirror/statements`,
                    title: "Зеркало документов",
                    icon: React.createElement(CopyOutlined),
                    breadcrumb: false,
                    submenu: [],
                },
                {
                    key: "apps-laboratory-tvt",
                    path: `/laboratory-tvt`,
                    title: "Лаборатория ТВТ",
                    icon: React.createElement(CopyOutlined),
                    breadcrumb: false,
                    submenu: [],
                },
                {
                    key: "apps-ads-selection",
                    path: `/adsSelection`,
                    title: "Выборка рекламы",
                    icon: React.createElement(CopyOutlined),
                    breadcrumb: false,
                    submenu: [],
                },
                {
                    key: "apps-micro-seasons",
                    path: `/microSeasons`,
                    title: "Микросезоны",
                    icon: React.createElement(CopyOutlined),
                    breadcrumb: false,
                    submenu: [],
                },
                {
					key: 'apps-settings-mirror',
					path: `/settings-mirror/sellers-data`,
					title: 'Зеркало настроек',
					icon: React.createElement(SettingOutlined),
					breadcrumb: false,
					submenu: [],
				},
                {
					key: 'apps-actions',
					path: `/actions/actions-calendar`,
					title: 'Календарь Акций',
					icon: React.createElement(CopyOutlined),
					breadcrumb: false,
					submenu: [],
				},
				{
					key: 'apps-prices',
					path: `/prices/current`,
					title: 'Цены',
					icon: React.createElement(CopyOutlined),
					breadcrumb: false,
					submenu: [],
				},
            ],
        },
        {
            key: "profile",
            path: `/`,
            icon: "",
            title: "Профиль",
            breadcrumb: false,
            submenu: [
                {
                    key: "profile-account",
                    path: `/account`,
                    title: "Личный кабинет",
                    icon: <UserOutlined/>,
                    breadcrumb: false,
                    submenu: [],
                },
                {
                    key: "profile-notification",
                    path: `/`,
                    title: "Уведомления",
                    icon: <BellOutlined/>,
                    breadcrumb: false,
                    submenu: [],
                },
                {
                    key: "profile-settings",
                    path: `/`,
                    title: "Настройки",
                    icon: <Icon component={setting}/>,
                    breadcrumb: false,
                    submenu: [],
                },
            ],
        },
    ])


    const shouldDisplayRoute = (route: any) => {
        if (state?.user?.role?.name === "Инвестор") {
            const displayedRoutes = ["apps-financial-results", "apps-innovation-proposals", "apps-questions-and-answers"];
            return displayedRoutes.includes(route.key);
        }
        if (state?.user?.role?.name === "МнМ-координатор") {
            const displayedRoutes = ["apps-financial-results", "apps-innovation-proposals", "apps-questions-and-answers"];
            return displayedRoutes.includes(route.key);
        }
        if (state?.user?.role?.name === "Бухгалтер") {
            const displayedRoutes = ["apps-document-mirror"];
            return displayedRoutes.includes(route.key);
        }
        if (state?.user?.role?.name === "Аналитик") {
            const displayedRoutes = ["apps-analytics", "apps-mirror", "apps-products"];
            return displayedRoutes.includes(route.key);
        }
        if (state?.user?.role?.name === "Координатор") {
            const displayedRoutes = [
                "apps-analytics",
                "apps-mirror",
                "apps-cashbacks",
                "apps-shipments",
                "apps-order-from-chine",
                "apps-operating-expenses",
                "apps-document-mirror",
                "apps-cost-price",
                "apps-products",
                "apps-innovation-proposals",
                "apps-questions-and-answers",
                "apps-storage",
                "apps-actions",
                "apps-prices",
            ];
            return displayedRoutes.includes(route.key);
        }
        if (state?.user?.role?.name === "Начальник склада") {
            const displayedRoutes = [
                "apps-cost-price",
                "apps-order-from-chine",
                "apps-storage",
            ];
            return displayedRoutes.includes(route.key);
        }
        if (state?.user?.role?.name === "Кешбек-аналитик") {
            const displayedRoutes = ["apps-cashbacks"];
            return displayedRoutes.includes(route.key);
        }

        return true;
    };

    const updatedNavigationConfig = navigationConfig.map((item) => {
        if (item.key === "apps") {
            const updatedSubmenu = item.submenu.filter(shouldDisplayRoute);
            return {...item, submenu: updatedSubmenu};
        }
        return item;
    });

    useEffect(() => {
        setNavigationConfig(updatedNavigationConfig)
    }, [state?.user?.role?.name])

    return (
        <div style={{minHeight: "100%", borderRight: 0, backgroundColor: isFinance ? '#5C4E73' : '#fff'}}>
            <Menu
                theme={"light"}
                mode="inline"
                style={{minHeight: "100%", borderRight: 0, backgroundColor: isFinance ? '#5C4E73' : '#fff'}}
                defaultSelectedKeys={[routeInfo?.key]}
                //defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
                className={collapsed ? "hide-group-title" : "menu-not-collapsed"}
            >
                {navigationConfig.map((menu: any, index: any) =>
                    menu.submenu.length > 0 ? (
                        <Menu.ItemGroup
                            key={menu.key}
                            title={
                                <span
                                    style={{
                                        color: isInvestor || isFinance ? '#fff' : 'inherit' // Change 'red' to your desired color
                                    }}
                                >
                                        {menu.title}
                                     </span>
                            }
                            style={{
                                position: "relative",
                                color: isInvestor || isFinance && '#fff'
                            }}
                        >
                            {index === 0 &&
                            (!collapsed ? (
                                <img
                                    className="sidenav-button"
                                    src={isInvestor || isFinance ? iconWhite : icon}
                                    style={{
                                        position: "absolute",
                                        top: "3%",
                                        left: "80%",
                                    }}
                                    onClick={() => setCollapsed(true)}
                                />
                            ) : (
                                <li
                                    className="ant-menu-item sidenav-button-item"
                                    onClick={() => setCollapsed(false)}
                                    title=""
                                    role="menuitem"
                                    data-menu-id="rc-menu-uuid-10523-2-icon-active"
                                >
                                    <span className="ant-menu-title-content">
                                        {" "}
                                        <img src={iconActive}/>
                                    </span>
                                </li>
                            ))}

                            {menu.submenu?.map((subMenuFirst: any) => (
                                <>
                                    {
                                        (!isMob && subMenuFirst.key === 'profile-settings') || (!isMob && subMenuFirst.key === 'profile-notification')
                                            ? null
                                            :
                                            <Menu.Item
                                                key={subMenuFirst.key}
                                                className='menu-item-bckg'
                                            >
                                                {subMenuFirst.icon}
                                                <span style={{color: isFinance && '#fff'}}>{subMenuFirst.title}</span>
                                                <Link
                                                    // onClick={() => closeMobileNav(2)}
                                                    to={subMenuFirst.path}
                                                />
                                            </Menu.Item>
                                    }
                                </>
                            ))}
                        </Menu.ItemGroup>
                    ) : (
                        <Menu.Item key={menu.key}>
                            {menu.icon ? React.createElement(menu.icon) : null}
                            <span>{menu?.title}</span>
                            {menu.path ? <Link to={menu.path}/> : null}
                        </Menu.Item>
                    )
                )}
            </Menu>
        </div>
    );
};

const MenuContent = (props: any) => {
    return <SideNavContent {...props} />;
};

export default MenuContent;
