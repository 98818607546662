import TitleWithTooltip from 'features/actions/components/TitleWithTooltip'
import React from 'react'

const TableHeader = ({
	title,
	fontSize = 16,
	style,
}: {
	title: any
	fontSize?: string | number
	style?: any
}) => {
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				maxHeight: 50,
				minHeight: 50,
				background: '#666666',
				padding: '0 7px',
				...style,
			}}
		>
			<TitleWithTooltip
				title={title}
				rows={2}
				color='#fff'
				fontSize={fontSize}
			/>
		</div>
	)
}

export default TableHeader
